import React from "react";
import { useEffect } from "react";
import ModalLayout from "./ModalLayout";

const SeedPhase = ({ modalOpen, setModalOpen, setMessageModal }) => {
  const [phraseCopied, setPhraseCopied] = React.useState(false);
  const [randomCharactersArray, setRandomCharactersArray] = React.useState([]);

  // Generate random characters
  const generateRandomCharacters = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < 9; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const emptyArray = Array(8).fill("");

  useEffect(() => {
    setRandomCharactersArray(emptyArray.map(() => generateRandomCharacters()));
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(randomCharactersArray.join(" "));
    setPhraseCopied(true);
    setTimeout(() => {
      setPhraseCopied(false);
    }, 2000);
  };

  const closeSeedPhase = () => {
    setModalOpen(false);
    setMessageModal(true);
  };

  return (
    <ModalLayout modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block transform font-primary overflow-hidden bg-[#363636] p-10 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[39rem] sm:p-9 sm:align-middle border border-white text-white xs:p-4">
        <h2 className="text-lg">Seed phrase</h2>
        <p className="font-secondary text-sm pt-6">
          Please store the following secret recovery phrase in a safe place.
          This is the only way to recover your account!
        </p>
        <div className="text-lg flex justify-between pt-9 flex-wrap sm:gap-[30px] gap-3">
          {randomCharactersArray?.map((characters, index) => (
            <h2
              key={index}
              className="py-3 border border-white sm:w-[13rem] w-[10rem] text-center sm:text-base text-sm xs:text-[0.83rem] xs:w-[8rem]"
            >
              {index + 1}. {characters}
            </h2>
          ))}
        </div>
        <p className="text-right text-sm font-secondary pt-6">
          Click if you saved your seed phrase
        </p>
        <div className="flex justify-between items-center pt-6">
          <button
            className="px-7 py-3 bg-transparent text-white border border-white text-lg cursor-pointer hover:bg-[#F6A70D] hover:border-[#F6A70D] duration-500"
            onClick={handleCopyToClipboard}
          >
            {phraseCopied ? "Copied" : "Copy All"}
          </button>
          <button
            className="px-9 py-3 bg-[#F6A70D] text-white text-lg cursor-pointer"
            onClick={closeSeedPhase}
          >
            Ok
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default SeedPhase;
