import none from "../../../assets/images/Attributes/zoomers/none.png";
import whiteEarbuds from "../../../assets/images/Attributes/zoomers/earAccessories/whiteEarbuds.png";
import blackEarbuds from "../../../assets/images/Attributes/zoomers/earAccessories/blackEarbuds.png";
import diamonEarrings from "../../../assets/images/Attributes/zoomers/earAccessories/diamonEarrings.png";
import blackStubEarrings from "../../../assets/images/Attributes/zoomers/earAccessories/blackStubEarrings.png";
import hotDogEarrings from "../../../assets/images/Attributes/zoomers/earAccessories/hotDogEarrings.png";
import threePieceEarrings from "../../../assets/images/Attributes/zoomers/earAccessories/threePieceEarrings.png";
import mintFlameCarEarrings from "../../../assets/images/Attributes/zoomers/earAccessories/mintFlameCarEarrings.png";
import traffiedLightEarrings from "../../../assets/images/Attributes/zoomers/earAccessories/traffiedLightEarrings.png";
import greenTraselEarrings from "../../../assets/images/Attributes/zoomers/earAccessories/greenTraselEarrings.png";
import { useRef } from "react";
import AttributeSlider from "../../common/AttributeSlider";

const EarAccessories = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const earRings = [
    {
      name: "None",
      count: 164,
      percent: "73.87",
      image: none,
    },
    {
      name: "White Wireless Earbuds",
      count: 16,
      percent: "7.21",
      image: whiteEarbuds,
    },
    {
      name: "Black Wireless Earbuds",
      count: 12,
      percent: "5.41",
      image: blackEarbuds,
    },
    {
      name: "Diamond Stud Earrings",
      count: 12,
      percent: "5.41",
      image: diamonEarrings,
    },
    {
      name: "Black Stud Earrings",
      count: 7,
      percent: "3.15",
      image: blackStubEarrings,
    },
    {
      name: "Hot Dog and Fries Earrings",
      count: 5,
      percent: "2.25",
      image: hotDogEarrings,
    },
    {
      name: "Three Piece Earrings",
      count: 3,
      percent: "1.35",
      image: threePieceEarrings,
    },
    {
      name: "Mint Flame Car Earrings",
      count: 1,
      percent: "0.45",
      image: mintFlameCarEarrings,
    },
    {
      name: "Traffic Light Earrings",
      count: 1,
      percent: "0.45",
      image: traffiedLightEarrings,
    },
    {
      name: "Green Tassel Earrings",
      count: 1,
      percent: "0.45",
      image: greenTraselEarrings,
    },
  ];

  if (selected === "From common to rare") {
    earRings.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    earRings.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Ear Accessories"}
        data={earRings}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default EarAccessories;
