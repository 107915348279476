import { ranks } from "../../data/RanksData";
import RankItems from "./RankItems";
import Tournaments from "./Tournaments";

import SeeMore from "../../components/common/SeeMore";

const Rankings = () => {
  return (
    <section className="h-[100%] relative w-full">
      <div className="w-[80%] m-auto py-20 font-primary text-white sm:text-[2.7rem] text-4xl font-bold">
        <h2>
          <span className="stoke-text">Ran</span>Kings
        </h2>
      </div>
      {ranks.map((rank, index) => (
        <RankItems key={index} rank={rank} />
      ))}
      <Tournaments />
      <SeeMore />
    </section>
  );
};

export default Rankings;
