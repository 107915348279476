import none from "../../../assets/images/Attributes/zoomers/none.png";
import goatee from "../../../assets/images/Attributes/zoomers/facialHair/goatee.png";
import blackMustache from "../../../assets/images/Attributes/zoomers/facialHair/blackMustache.png";
import { useRef } from "react";
import AttributeSlider from "../../common/AttributeSlider";

const FacialHair = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const facialHairs = [
    {
      name: "None",
      count: 216,
      percent: "97.30",
      image: none,
    },
    {
      name: "Goatee",
      count: 4,
      percent: "1.80",
      image: goatee,
    },
    {
      name: "Black Mustache",
      count: 2,
      percent: "0.90",
      image: blackMustache,
    },
  ];

  if (selected === "From common to rare") {
    facialHairs.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    facialHairs.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Facial Hair"}
        data={facialHairs}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default FacialHair;
