import brownMale from "../../../assets/images/Attributes/zoomers/eyeColor/brownMale.png";
import brownFemale from "../../../assets/images/Attributes/zoomers/eyeColor/brownFemale.png";
import blueMale from "../../../assets/images/Attributes/zoomers/eyeColor/blueMale.png";
import blueFemale from "../../../assets/images/Attributes/zoomers/eyeColor/blueFemale.png";
import greenMale from "../../../assets/images/Attributes/zoomers/eyeColor/greenMale.png";
import greenFemale from "../../../assets/images/Attributes/zoomers/eyeColor/greenFemale.png";
import redMale from "../../../assets/images/Attributes/zoomers/eyeColor/redMale.png";
import redFemale from "../../../assets/images/Attributes/zoomers/eyeColor/redFemale.png";
import heterochromiaFemale from "../../../assets/images/Attributes/zoomers/eyeColor/heterochromiaFemale.png";
import heterochromiaMale from "../../../assets/images/Attributes/zoomers/eyeColor/heterochromiaMale.png";
import dragonMale from "../../../assets/images/Attributes/zoomers/eyeColor/dragonMale.png";
import dragonFemale from "../../../assets/images/Attributes/zoomers/eyeColor/dragonFemale.png";
import doubleIrisFemale from "../../../assets/images/Attributes/zoomers/eyeColor/doubleIrisFemale.png";
import doubleIrisMale from "../../../assets/images/Attributes/zoomers/eyeColor/doubleIrisMale.png";
import { useRef } from "react";
import MultiItemSlider from "../../common/MultiItemSlider";

const EyeColor = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const eyeColors = [
    {
      name: "Brown",
      count: 79,
      percent: "35.59",
      male: brownMale,
      female: brownFemale,
      twoItems: true,
    },
    {
      name: "Blue",
      count: 71,
      percent: "31.98",
      male: blueMale,
      female: blueFemale,
      twoItems: true,
    },
    {
      name: "Green",
      count: 41,
      percent: "18.47",
      male: greenMale,
      female: greenFemale,
      twoItems: true,
    },
    {
      name: "Red",
      count: 18,
      percent: "8.11",
      male: redMale,
      female: redFemale,
      twoItems: true,
    },
    {
      name: "Heterochromia",
      count: 9,
      percent: "4.05",
      male: heterochromiaMale,
      female: heterochromiaFemale,
      twoItems: true,
    },
    {
      name: "Dragon",
      count: 3,
      percent: "1.35",
      male: dragonMale,
      female: dragonFemale,
      twoItems: true,
    },
    {
      name: "Double Iris",
      count: 1,
      percent: "0.45",
      male: doubleIrisMale,
      female: doubleIrisFemale,
      twoItems: true,
    },
  ];

  if (selected === "From common to rare") {
    eyeColors.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    eyeColors.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <MultiItemSlider
        data={eyeColors}
        from={"Eye Color"}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default EyeColor;
