import engine from "../../assets/images/Home/power/engine.png";
import upgrade from "../../assets/images/Home/power/upgrade.png";
import zoomer from "../../assets/images/Home/power/zoomer.png";

const backgrounds = [
  {
    name: "Engine Power-ups",
    image: engine,
    desc: "Give your engine special game abilities with engine power-ups",
  },
  {
    name: "Zoomer Power-ups",
    image: zoomer,
    desc: "Each Zoomer comes a specific talent that improves your game performance",
  },
  {
    name: "Upgrades",
    image: upgrade,
    desc: "Earn EXP to enhance your engine’s top speed and acceleration",
  },
];

const Power = () => {
  return (
    <div className="w-[95%] md:w-[85%] mx-auto py-20 ">
      <div className="flex justify-center  gap-y-10 md:gap-x-20 items-center mt-12 flex-wrap">
        {backgrounds.map((data, index) => (
          <div
            key={index}
            className="w-[21rem] h-[100%]  relative p-6 text-white"
          >
            <img src={data.image} alt="" className="w-full" />
            <div className="flex flex-col items-center md:items-start gap-5 py-5 px-6">
              <h2 className="text-center md:text-left font-primary text-xl">
                {data.name}
              </h2>
              <p className="font-secondary text-xl text-center md:text-left">
                {data.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Power;
