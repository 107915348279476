import normalMale from "../../../assets/images/Attributes/zoomers/eyeType/normalMale.png";
import normalFemale from "../../../assets/images/Attributes/zoomers/eyeType/normalFemale.png";
import laughingMale from "../../../assets/images/Attributes/zoomers/eyeType/laughingMale.png";
import laughingFemale from "../../../assets/images/Attributes/zoomers/eyeType/laughingFemale.png";
import surprisedMale from "../../../assets/images/Attributes/zoomers/eyeType/surprisedMale.png";
import surprisedFemale from "../../../assets/images/Attributes/zoomers/eyeType/surprisedFemale.png";
import sleepyMale from "../../../assets/images/Attributes/zoomers/eyeType/sleepyMale.png";
import sleepyFemale from "../../../assets/images/Attributes/zoomers/eyeType/sleepyFemale.png";
import closedMale from "../../../assets/images/Attributes/zoomers/eyeType/closedMale.png";
import closedFemale from "../../../assets/images/Attributes/zoomers/eyeType/closedFemale.png";
import angryMale from "../../../assets/images/Attributes/zoomers/eyeType/angryMale.png";
import angryFemale from "../../../assets/images/Attributes/zoomers/eyeType/angryFemale.png";
import winkFemale from "../../../assets/images/Attributes/zoomers/eyeType/winkFemale.png";
import winkMale from "../../../assets/images/Attributes/zoomers/eyeType/winkMale.png";
import cryingFemale from "../../../assets/images/Attributes/zoomers/eyeType/cryingFemale.png";
import cryingMale from "../../../assets/images/Attributes/zoomers/eyeType/cryingMale.png";
import fireMale from "../../../assets/images/Attributes/zoomers/eyeType/fireMale.png";
import fireFemale from "../../../assets/images/Attributes/zoomers/eyeType/fireFemale.png";
import abyssFemale from "../../../assets/images/Attributes/zoomers/eyeType/abyssFemale.png";
import abyssMale from "../../../assets/images/Attributes/zoomers/eyeType/abyssMale.png";
import { useRef } from "react";
import MultiItemSlider from "../../common/MultiItemSlider";

const EyeType = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const eyeTypes = [
    {
      name: "normal",
      count: 96,
      percent: "43.24",
      male: normalMale,
      female: normalFemale,
      twoItems: true,
    },
    {
      name: "Laughing",
      count: 29,
      percent: "13.06",
      male: laughingMale,
      female: laughingFemale,
      twoItems: true,
    },
    {
      name: "Surprised",
      count: 25,
      percent: "11.26",
      male: surprisedMale,
      female: surprisedFemale,
      twoItems: true,
    },
    {
      name: "Sleepy",
      count: 20,
      percent: "9.01",
      male: sleepyMale,
      female: sleepyFemale,
      twoItems: true,
    },
    {
      name: "Closed",
      count: 19,
      percent: "8.56",
      male: closedMale,
      female: closedFemale,
      twoItems: true,
    },
    {
      name: "Angry",
      count: 11,
      percent: "4.95",
      male: angryMale,
      female: angryFemale,
      twoItems: true,
    },
    {
      name: "Wink",
      count: 9,
      percent: "4.05",
      male: winkMale,
      female: winkFemale,
      twoItems: true,
    },
    {
      name: "Crying",
      count: 7,
      percent: "3.15",
      male: cryingMale,
      female: cryingFemale,
      twoItems: true,
    },
    {
      name: "Fire",
      count: 3,
      percent: "1.35",
      male: fireMale,
      female: fireFemale,
      twoItems: true,
    },
    {
      name: "Abyss",
      count: 3,
      percent: "1.35",
      male: abyssMale,
      female: abyssFemale,
      twoItems: true,
    },
  ];

  if (selected === "From common to rare") {
    eyeTypes.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    eyeTypes.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <MultiItemSlider
        data={eyeTypes}
        from={"Eye Type"}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default EyeType;
