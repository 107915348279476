const ErrorMessage = ({ children, error }) => {
  return (
    <p
      className={`text-[0.85rem] text-[#FF7B7B] absolute bottom-[-1.45rem] transform  pointer-events-none duration-500 transition-all ${
        error
          ? "opacity-100 translate-x-[-0rem]"
          : "opacity-0 translate-x-[-3rem]"
      }`}
    >
      {children}
    </p>
  );
};

export default ErrorMessage;
