import apeImg from "../../assets/images/Roadmap/zoomingApe/TheZoomingApe.png";
import topLeft from "../../assets/images/Roadmap/zoomingApe/topLeft.png";
import rightBottom from "../../assets/images/Roadmap/zoomingApe/rightBottom.png";
import BackToRoadmap from "../../components/common/BackToRoadmap";

const ZoomingApe = () => {
  const priorities = [
    "First merch collection release.",
    "Collaborations with 5 different projects on special edition cars.",
    "Collaborate with projects to help them launch their own mini-game within the Pixel Racers universe.",
  ];

  return (
    <>
      <section className="md:px-12 px-3">
        <div className="max-w-[95rem] m-auto  pt-[5rem]">
          <div className="text-white flex sm:justify-between sm:flex-row flex-col sm:items-start items-center pb-8">
            <div className="font-primary pb-7 text-center sm:text-left">
              <h2 className="text-3xl">The Zooming Ape</h2>
              <p className="font-secondary text-2xl pt-2">
                Branding and Partnerships
              </p>
            </div>
            <BackToRoadmap />
          </div>
          <div className="grid lg100:grid-cols-2 grid-cols-1">
            <div className="text-white flex flex-col justify-center font-secondary border-t border-b border-white py-6 lg100:px-0 px-6 bor border-l lg100:border-l-0 border-r lg100:border-r-0 lg100:order-1 order-2">
              <h2 className="navLg:w-[85%] w-[95%] navLg:text-lg text-base">
                Pixel Racers is a gaming metaverse, but it is also a brand. In
                addition to an official rebrand, we plan to release official
                merchandise for our holders to flaunt in the real world as we
                strive to be recognized as the number one racing game ecosystem
                on Solana. Furthermore, we plan to work on securing meaningful
                partnerships to bolster our brand image.
              </h2>
              <div className="w-[6.5rem] h-1.5 bg-white my-[1.5rem]"></div>
              <div>
                <h2 className="font-primary navLg:pb-5 pb-2.5">
                  Branding and Partnerships Priorities
                </h2>
                {priorities.map((priority) => (
                  <div className="flex gap-5 mb-2">
                    <div className="w-5 h-5 bg-[#FEAC0E] flex-shrink-0"></div>
                    <h2 className="navLg:text-lg text-base">{priority}</h2>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative border border-white lg100:order-2 order-1">
              <div className="bg-lighBg object-cover w-full h-full absolute z-[-1]"></div>
              <div className="flex justify-start">
                <img src={topLeft} alt="" />
              </div>
              <div className="flex justify-center">
                <img src={apeImg} alt="" />
              </div>
              <div className="flex justify-end">
                <img src={rightBottom} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZoomingApe;
