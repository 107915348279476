import React from "react";
import ErrorMessage from "../common/ErrorMessage";
import InputRequired from "../common/InputRequired";
import ModalLayout from "./ModalLayout";

const RecoverPassword = ({ modalOpen, setModalOpen, nextOpen }) => {
  const demoUserName = "PixelRacer";
  const [inputtedUserName, setInputtedUserName] = React.useState("");
  const [foundUserName, setFoundUserName] = React.useState(true);

  const handleCheckUserName = (e) => {
    if (inputtedUserName === "") return;

    if (inputtedUserName === demoUserName) {
      setFoundUserName(true);
      nextOpen(true);
      setModalOpen(false);
    } else {
      setFoundUserName(false);
    }
  };

  return (
    <ModalLayout modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block transform overflow-hidden bg-[#363636] border border-white p-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-10 sm:align-middle">
        <h2 className="font-primary text-white text-lg">Recover password</h2>
        <div className="relative mt-10">
          <InputRequired
            className={""}
            label="Enter Username"
            type={"text"}
            name="userName"
            placeholder="e.g. johndoe524"
            color={"white"}
            event={(e) => setInputtedUserName(e.target.value)}
          />
          <ErrorMessage error={!foundUserName}>
            Username not found.
          </ErrorMessage>
        </div>
        <div className="font-primary flex pt-8 justify-end">
          <button
            className={`
                px-9 py-3 text-white text-lg border duration-500 ${
                  inputtedUserName !== ""
                    ? "bg-[#FEAC0E] border-[#FEAC0E] cursor-pointer"
                    : "bg-[#363636] border-white cursor-not-allowed"
                }
                `}
            onClick={handleCheckUserName}
          >
            Find
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default RecoverPassword;
