import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import SliderArrow from "./SliderArrow";
import GetWidth from "../../hooks/GetWidth";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const AttributeSlider = ({ from, data, prevRef, nextRef }) => {
  const { width } = GetWidth();

  return (
    <>
      <div className="overflow-hidden">
        <div className="lg:w-[73%] md:w-[80%] w-[95%] m-auto md:pt-20 pt-0 pb-20 font-primary text-white md:text-4xl sm:text-2xl text-base items-center font-bold flex justify-between gap-2">
          <h2>{from}</h2>
          <SliderArrow prevRef={prevRef} nextRef={nextRef} />
        </div>
        <div className="md:max-w-[80%] m-auto max-w-[90%]">
          <Swiper
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={
              width > 1500
                ? 4
                : width > 1150
                ? 3
                : width > 752
                ? "auto"
                : "auto"
            }
            spaceBetween={20}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            className="backgroundSwiper"
            modules={[Navigation, Pagination]}
          >
            {data.map((data, index) => (
              <SwiperSlide key={index}>
                <div className="max-w-[20rem] h-[100%] bg-itemBg bg-no-repeat bg-center bg-full relative p-6 text-white">
                  <img src={data.image} alt="" className="w-full" />
                  <div className="flex flex-col items-center gap-5 pt-5">
                    <h2 className="text-center font-primary text-lg min-h-[4rem] flex items-center">
                      {data.name}
                    </h2>
                    <p className="font-secondary bg-[#BB3BC3] p-2">Count</p>
                    <p className="font-secondary">
                      {data.count ? data.count : "?"}
                    </p>
                    <p className="font-secondary bg-[#BB3BC3] p-2">%</p>
                    <p className="font-secondary">{data.percent}%</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default AttributeSlider;
