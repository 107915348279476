import twitter from "../../assets/images/Attributes/twitter.png";
import discord from "../../assets/images/Attributes/discord.png";

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer className="font-secondary py-12  text-white  mt-20">
            <div className="w-[90%] m-auto flex flex-col sm:flex-row gap-6 items-center justify-between ">
                <h2>&copy; Pixel Racers. All rights reserved {year}</h2>
                <a href="/privacy-policy">Privacy Policy</a>
                <div className="flex items-center gap-6">
                    <a
                        href="https://twitter.com/pixelracersnft"
                        target={"_blank"}
                        rel="noopener noreferrer"
                    >
                        <img src={twitter} alt="twitter" />
                    </a>
                    <a
                        href="https://discord.com/invite/sJyp4gMGJD"
                        target={"_blank"}
                        rel="noopener noreferrer"
                    >
                        <img src={discord} alt="discord" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
