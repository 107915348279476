import { useState } from "react";
import { Link } from "react-router-dom";

import bannerImg from "../../assets/images/Home/man-car-3.png";
import GetWidth from "../../hooks/GetWidth";
import Button from "../common/Button";

import "./Banner.css";
import Shape from "./Shape";

const Banner = ({ inView }) => {
  const { width } = GetWidth();
  // In vh
  const fullHidden = width >= 1250 ? 1200 : width >= 1024 ? 1300 : 1200;

  const [containerOpacity, setContainerOpacity] = useState();

  window.addEventListener("scroll", () => {
    const st = window.scrollY;
    if (st <= fullHidden) {
      setContainerOpacity(1 - st / fullHidden);
    }
  });

  return (
    <>
      <Shape customOpacity={containerOpacity} inView={inView} />
      <section
        className="md:h-screen lg:py-4 py-0 flex-col-reverse  flex md:flex-row items-center justify-start md:fixed bannerContainer"
        style={{
          opacity: inView ? 0 : containerOpacity,
        }}
      >
        {/* Title section  */}
        <div className="md:w-[30%] md:ml-[10%] text-center md:text-left">
          <h2 className=" font-primary text-white lg:text-[4rem] text-[3rem] navLg:text-[6rem] md:leading-[1.05] font-bold md:w-[40%] bannerTitle">
            Zoom Win Earn
          </h2>
          <p className="mt-[3rem] font-secondary text-xl text-white md:w-[60%] bannerDes">
            Pixel Racers is an NFT racing game universe on Solana
          </p>
          <div className="mt-16 flex gap-4 justify-center flex-wrap md:flex-nowrap sm:justify-start bannerBtnContainer">
            <Link to="/play">
              <Button name={"DOWnLOAD"} color="black" />
            </Link>
            <a
              href="https://magiceden.io/creators/pixel_racers"
              target={"_blank"}
              rel="noreferrer"
            >
              <Button name={"Buy"} color="pink" />
            </a>
          </div>
        </div>
        {/* image section */}
        <div className="md:w-[70%] flex justify-end items-center">
          <img
            src={bannerImg}
            alt="banner-img"
            className="w-[80%] h-full mt-36 flex-shrink-0 bannerImg"
          />
        </div>
      </section>
    </>
  );
};

export default Banner;
