import React, { useRef } from "react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import alphaDog1 from "../../../assets/images/Attributes/specialCars/alphaDog1.png";
import alphaDog2 from "../../../assets/images/Attributes/specialCars/alphaDog2.png";
import celon from "../../../assets/images/Attributes/specialCars/celon.png";
import jords from "../../../assets/images/Attributes/specialCars/jords.png";
import magellan from "../../../assets/images/Attributes/specialCars/magellan.png";
import issaHodl from "../../../assets/images/Attributes/specialCars/issaHodl.png";
import solLegend from "../../../assets/images/Attributes/specialCars/solLegend.png";
import solBigBrain from "../../../assets/images/Attributes/specialCars/solBigBrain.png";
import bunjil from "../../../assets/images/Attributes/specialCars/bunjil.png";
import miles from "../../../assets/images/Attributes/specialCars/miles.png";
import titan from "../../../assets/images/Attributes/specialCars/titan.png";
import melty from "../../../assets/images/Attributes/specialCars/melty.png";
import anglid from "../../../assets/images/Attributes/specialCars/anglid.png";

import GetWidth from "../../../hooks/GetWidth";
import SliderArrow from "../../common/SliderArrow";

const specialCars = [
  {
    name: "Thugbirdz",
    image: alphaDog1,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Muscle",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "Thugbirdz",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Turquoise",
      },
    ],
  },
  {
    name: "Alpha Dog",
    image: alphaDog2,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Ice Cream Truck",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@sol_snatcher",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Green",
      },
    ],
  },
  {
    name: "Celon",
    image: celon,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Supercar",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@0xCelon",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Turquoise",
      },
    ],
  },
  {
    name: "Jords",
    image: jords,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Supercar",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@earlyishadopter",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Rainbow",
      },
    ],
  },
  {
    name: "Magellan",
    image: magellan,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Muscle",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@CryptoMagellan",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Rainbow",
      },
    ],
  },
  {
    name: "Issa Hodl",
    image: issaHodl,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Antique",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@issahodl",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "red",
      },
    ],
  },
  {
    name: "Sol Legend",
    image: solLegend,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Ice Cream Truck",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@SolanaLegend",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Rainbow",
      },
    ],
  },
  {
    name: "Sol Big Brain",
    image: solBigBrain,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Antique",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@SOLBigBrain",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Purple",
      },
    ],
  },
  {
    name: "Bunjil",
    image: bunjil,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Muscle",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@notbunjil",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Yellow",
      },
    ],
  },
  {
    name: "Miles",
    image: miles,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Antique ",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@Miles_NFT",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Green",
      },
    ],
  },
  {
    name: "Titan",
    image: titan,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",

        type: "Special",
      },
      {
        name: "Body Type",
        type: "Supercar",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@TitanXBT",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Green",
      },
    ],
  },
  {
    name: "Melty",
    image: melty,
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Muscle",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@meltytanti",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Pink",
      },
    ],
  },
  {
    name: "Anglio",
    image: anglid,
    background: "Honorary Black",
    widowColor: "Special",
    bodyType: "Muscle",
    wheelType: "Amplification",
    bodyColor: "@CryptoAnglio",
    wheelColor: "Black",
    bodyStripe: "Purple",
    trail: "Yellow",
    details: [
      {
        name: "Background",
        type: "Honorary Black",
      },
      {
        name: "Window Color",
        type: "Special",
      },
      {
        name: "Body Type",
        type: "Muscle",
      },
      {
        name: "Wheel Type",
        type: "Amplification",
      },
      {
        name: "Body Color",
        type: "@CryptoAnglio",
      },
      {
        name: "Wheel Color",
        type: "Black",
      },
      {
        name: "Body Stripe",
        type: "None",
      },
      {
        name: "Trail",
        type: "Yellow",
      },
    ],
  },
];

const SpecialCars = () => {
  const { width } = GetWidth();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <div className="overflow-hidden w-full">
        <div className="lg:w-[73%] md:w-[80%] w-[95%] m-auto pt-20 pb-20 font-primary text-white md:text-4xl sm:text-2xl text-base items-center font-bold flex justify-between gap-2">
          <h2>Special Cars</h2>
          <SliderArrow prevRef={prevRef} nextRef={nextRef} />
        </div>
        <div className="md:max-w-[80%] m-auto max-w-[90%]">
          <Swiper
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={width > 1550 ? 1.5 : 1}
            spaceBetween={20}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            className="backgroundSwiper"
            modules={[Navigation, Pagination]}
          >
            {specialCars.map((car, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`w-[100%] h-[100%] bg-teamGrayBg bg-no-repeat bg-center bg-full relative p-6 text-white flex items-center justify-start flex-col gap-y-10 lg100:flex-row lg100:gap-x-10 cursor-pointer`}
                >
                  <div>
                    <img src={car.image} alt="" className="w-full" />
                    <h2 className="font-primary text-center pt-2 uppercase">
                      {car.name}
                    </h2>
                  </div>
                  <div className="grid grid-cols-2  gap-y-8 gap-x-20 py-4 h-[80%]  justify-center auto-cols-auto">
                    {car?.details.map((data, index) => (
                      <div key={index}>
                        <h2 className="bg-[#BB3BC3] font-primary inline py-1.5 lg:text-base text-sm">
                          {data.name}
                        </h2>
                        <p className="font-secondary pt-2 lg:text-base text-xs">
                          {data.type}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SpecialCars;
