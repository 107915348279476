import shape1 from "../../assets/images/Home/block-1.png";
import shape2 from "../../assets/images/Home/block-2.png";
import shape3 from "../../assets/images/Home/block-3.png";
import plus1 from "../../assets/images/Home/plus-1.png";
import plus2 from "../../assets/images/Home/plus-2.png";
import plus3 from "../../assets/images/Home/plus-3.png";

const Shape = ({ customOpacity, inView }) => {
  return (
    <>
      <img
        src={shape1}
        alt=""
        className="md:fixed absolute md:top-32 top-10 left-0 "
        style={{
          opacity: inView ? 0 : customOpacity,
        }}
      />
      <img
        src={shape2}
        alt=""
        className="md:fixed absolute bottom-0 left-1/2 translate-x-[-50%] navLg:block hidden"
        style={{
          opacity: inView ? 0 : customOpacity,
        }}
      />
      <img
        src={shape3}
        alt=""
        className="md:fixed absolute bottom-5 left-20 md:block hidden"
        style={{
          opacity: inView ? 0 : customOpacity,
        }}
      />
      <img
        src={shape3}
        alt=""
        className="md:fixed absolute top-40 right-20 -rotate-90"
        style={{
          opacity: inView ? 0 : customOpacity,
        }}
      />
      <img
        src={plus1}
        alt=""
        className="md:fixed absolute bottom-80 left-[600px]"
        style={{
          opacity: inView ? 0 : customOpacity,
        }}
      />
      <img
        src={plus2}
        alt=""
        className="md:fixed absolute top-36 left-[800px] navLg:block hidden"
        style={{
          opacity: inView ? 0 : customOpacity,
        }}
      />
      <img
        src={plus3}
        alt=""
        className="md:fixed absolute top-32 right-[200px]"
        style={{
          opacity: inView ? 0 : customOpacity,
        }}
      />
      {/* <div className="w-40 h-44 bg-[#4f4f4f44] fixed bottom-80 right-[58%] md:bottom-60 md:right-[38%] customShapes"></div>
      <div className="w-40 h-44 bg-[#4f4f4f44] fixed top-32 right-[20.37%] customShapes"></div>
      <div className="w-40 h-44 bg-[#4f4f4f44] fixed top-40 right-[28.8%] customShapes"></div>
      <div className="w-32 h-64 bg-[#4f4f4f44] fixed bottom-64 md:bottom-32 right-[18.8%] customShapes"></div>
      <div className="w-40 h-44 bg-[#4f4f4f44] fixed bottom-64 md:bottom-40 right-[2%] md:right-[10%] customShapes"></div> */}
    </>
  );
};

export default Shape;
