import gaps from "../../assets/images/Rankings/gaps.png";

const SeeMore = ({ onClick }) => {
  return (
    <>
      <div className="flex md:justify-between justify-center items-center text-white font-secondary text-2xl pt-[5rem]">
        <img
          src={gaps}
          alt="gaps"
          className="lg:w-[43%] md:w-[35%] md:block hidden "
        />
        <button className="" onClick={onClick}>
          See More
        </button>
        <img
          src={gaps}
          alt="gaps"
          className="lg:w-[43%] md:w-[35%] md:block hidden"
        />
      </div>
    </>
  );
};

export default SeeMore;
