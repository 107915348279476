import gray from "../../../assets/images/Attributes/colors/gray.png";
import black from "../../../assets/images/Attributes/colors/black.png";
import orange from "../../../assets/images/Attributes/colors/orange.png";
import yellow from "../../../assets/images/Attributes/colors/yellow.png";
import purple from "../../../assets/images/Attributes/colors/purple.png";
import mint from "../../../assets/images/Attributes/colors/mint.png";
import blue from "../../../assets/images/Attributes/colors/blue.png";
import green from "../../../assets/images/Attributes/colors/green.png";
import white from "../../../assets/images/Attributes/colors/white.png";
import red from "../../../assets/images/Attributes/colors/red.png";
import hotPink from "../../../assets/images/Attributes/colors/hotPink.png";
import solana from "../../../assets/images/Attributes/colors/solana.png";
import rainbow2 from "../../../assets/images/Attributes/colors/rainbow2.png";
import { useRef } from "react";
import AttributeSlider from "../../common/AttributeSlider";

const wheelColors = [
  {
    name: "Gray",
    image: gray,
    percent: "17.05",
    count: "1,480",
  },
  {
    name: "Black",
    image: black,
    percent: "16.48",
    count: "1,431",
  },
  {
    name: "Orange",
    image: orange,
    percent: "12.21",
    count: "1,060",
  },
  {
    name: "Yellow",
    image: yellow,
    percent: "10.99",
    count: "954",
  },
  {
    name: "Purple",
    image: purple,
    percent: "10.55",
    count: "916",
  },
  {
    name: "Mint",
    image: mint,
    percent: "9.78",
    count: "849",
  },
  {
    name: "Blue",
    image: blue,
    percent: "5.22",
    count: "453",
  },
  {
    name: "Green",
    image: green,
    percent: "4.99",
    count: "433",
  },
  {
    name: "White",
    image: white,
    percent: "4.25",
    count: "369",
  },
  {
    name: "Red",
    image: red,
    percent: "4.09",
    count: "355",
  },
  {
    name: "Hot Pink",
    image: hotPink,
    percent: "2.13",
    count: "185",
  },
  {
    name: "Solana",
    image: solana,
    percent: "1.15",
    count: "100",
  },
  {
    name: "Rainbow",
    image: rainbow2,
    percent: "1.11",
    count: "96",
  },
];

const WheelColor = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (selected === "From common to rare") {
    wheelColors.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    wheelColors.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        data={wheelColors}
        from="Wheel Color"
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default WheelColor;
