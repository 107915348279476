import { useState } from "react";
import { XIcon } from "@heroicons/react/outline";

const LinkedAddrss = () => {
  const walletAddresses = [
    "687425f87v587dfzcgfgrhddfxgfdgyjjjg",
    "687425f87v587dfzcgfgrhddfxgfdgyjjjg",
    "687425f87v587dfzcgfgrhddfxgfdgyjjjg",
  ];

  return (
    <>
      <h2 className="text-white sm:text-xl font-primary text-sm">
        Linked Address
      </h2>
      <div className="mt-[3rem] w-full h-[15rem] overflow-y-scroll linkedAddressesContainer">
        <div className="w-[93%] flex flex-col gap-7">
          {walletAddresses.map((walletAddress, index) => (
            <WalletAddressLayout key={index} walletAddress={walletAddress} />
          ))}
        </div>
      </div>
      <div className="w-[91.3%]">
        <button className="bg-white py-3 font-primary text-black w-full">
          Link New Address
        </button>
      </div>
    </>
  );
};

export default LinkedAddrss;

const WalletAddressLayout = ({ walletAddress }) => {
  const [isWalletAddressCopied, setIsWalletAddressCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`${walletAddress}`);
    setIsWalletAddressCopied(true);
    setTimeout(() => {
      setIsWalletAddressCopied(false);
    }, 2000);
  };

  return (
    <div className="flex items-center border-[2px] border-white text-lg mt-1 text-white">
      <h2 className="sm:w-[85%] w-[75%] xs:w-[65%] p-3 border-r-[2px] border-white overflow-hidden truncate">
        {walletAddress}
      </h2>
      <p
        className={`flex items-center justify-center gap-1 p-3 cursor-pointer sm:w-[15%] w-[25%] xs:w-[35%] duration-500 ${
          isWalletAddressCopied ? "text-primary sm:w-[20%]" : "sm:w-[15%]"
        }`}
        onClick={handleCopyToClipboard}
      >
        {isWalletAddressCopied ? "Copied!" : "Copy"}
      </p>
      <p
        className={`flex items-center justify-center gap-1 p-3 border-l-[2px] border-white cursor-pointer sm:w-[15%] w-[25%] xs:w-[35%] duration-500 h-full hover:bg-white hover:text-black tooltip relative`}
        onClick={handleCopyToClipboard}
      >
        <XIcon className="h-7 w-7" />
        <span className="tooltiptext text-sm pointer-events-none">Unlink</span>
      </p>
    </div>
  );
};
