import { useState } from "react";

import car from "../../assets/images/login&signup/car.png";
import leftBlock from "../../assets/images/login&signup/signupBlockLeft.png";
import bottomOne from "../../assets/images/login&signup/loginBlockBottom.png";
import bottomTwo from "../../assets/images/login&signup/loginBlockBottom2.png";

import ErrorMessage from "../../components/common/ErrorMessage";
import InputRequired from "../../components/common/InputRequired";
import RecoverPassword from "../../components/modals/RecoverPassword";
import InputePhrase from "../../components/modals/InputePhrase";
import NewPasswordForm from "../../components/modals/NewPasswordForm";
import Message from "../../components/modals/Message";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const demoUserName = "PixelRacer";
  const demoPassword = "123456";
  const [wrongPassword, setWrongPassword] = useState();
  const [wrongUserName, setWrongUserName] = useState();

  // Modal states
  const [recoverModalOpen, setRecoverModalOpen] = useState(false);
  const [inputPhraseOpen, setInputPhraseOpen] = useState(false);
  const [newPasswordOpen, setNewPasswordOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  // Modal states
  const [inputtedData, setInputtedData] = useState({
    userName: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputtedData({ ...inputtedData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputtedData.password !== "" && inputtedData.userName !== "") {
      inputtedData.userName === demoUserName
        ? setWrongUserName(false)
        : setWrongUserName(true);

      inputtedData.password === demoPassword
        ? setWrongPassword(false)
        : setWrongPassword(true);
    }
    if (!wrongPassword && !wrongUserName) {
      localStorage.setItem(
        "racers-user",
        JSON.stringify({
          userName: inputtedData.userName,
        })
      );
      navigate(`/wallet/${inputtedData.userName}`);
      window.location.reload();
    }
  };

  console.log(inputtedData, wrongPassword, wrongUserName);

  return (
    <section className="relative">
      <div className="lgC:w-[90%] md:w-[96%] w-[85%] m-auto grid md:grid-cols-2 grid-cols-1 gap-4 md:min-h-[90vh] h-full relative">
        <div className="md:absolute relative w-[50%] md:w-auto right-[50%] md:top-20">
          <img src={leftBlock} alt="" className="w-full" />
        </div>
        <div className="h-full flex justify-center items-center">
          <div>
            <img src={car} alt="" className="w-full flip loginCar" />
          </div>
        </div>
        <div className="font-secondary h-full flex flex-col justify-center gap-12 relative">
          <h2 className="titleContainer">
            <span className="stoke-text pr-4">Log</span>In
          </h2>
          <form className="flex flex-col gap-9" onSubmit={handleSubmit}>
            <div className="relative">
              <InputRequired
                className={""}
                label="Username"
                type={"text"}
                name="userName"
                placeholder="e.g. johndoe524"
                color={"white"}
                event={handleChange}
              />
              <ErrorMessage error={wrongUserName}>
                Username not found.
              </ErrorMessage>
            </div>
            <div className="relative">
              <InputRequired
                className={""}
                label="Password"
                type={"password"}
                name="password"
                color={"white"}
                event={handleChange}
              />
              <ErrorMessage error={wrongPassword}>
                Password incorrect, please try again.
              </ErrorMessage>
            </div>
            <div className="font-primary flex justify-end items-center lg:gap-20 md:gap-9 gap-5">
              <button
                type="button"
                className="cursor-pointer text-primary underline font-secondary"
                onClick={() => setRecoverModalOpen(true)}
              >
                Recover password
              </button>
              <button
                className={`px-9 py-3  border text-white text-lg cursor-pointer duration-500 ${
                  inputtedData.userName !== "" && inputtedData.password !== ""
                    ? "bg-[#FEAC0E] border-[#FEAC0E] cursor-pointer"
                    : "bg-[#363636] border-white cursor-not-allowed"
                }`}
                type="submit"
              >
                Enter
              </button>
            </div>
          </form>
          <div className="absolute left-[20%] bottom-[-4rem] pointer-events-none lg:block hidden">
            <img src={bottomTwo} alt="" className="w-fit" />
          </div>
        </div>
        <div className="absolute left-[10%] bottom-[0rem] pointer-events-none lg:block hidden">
          <img src={bottomOne} alt="" className="w-fit" />
        </div>
      </div>
      <RecoverPassword
        modalOpen={recoverModalOpen}
        setModalOpen={setRecoverModalOpen}
        nextOpen={setInputPhraseOpen}
      />
      <InputePhrase
        modalOpen={inputPhraseOpen}
        setModalOpen={setInputPhraseOpen}
        nextOpen={setNewPasswordOpen}
      />
      <NewPasswordForm
        modalOpen={newPasswordOpen}
        setModalOpen={setNewPasswordOpen}
        nextOpen={setMessageModalOpen}
      />
      <Message
        message={"Your password has been reset."}
        message2={"Click below to log in."}
        btn="Login"
        color={"gray"}
        modalOpen={messageModalOpen}
        setModalOpen={setMessageModalOpen}
      />
    </section>
  );
};

export default Login;
