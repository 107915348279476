import pixelImg from "../../assets/images/Roadmap/pixelTelecom/PixelTelecom.png";
import leftBottom from "../../assets/images/Roadmap/pixelTelecom/leftBottom.png";
import topRight from "../../assets/images/Roadmap/pixelTelecom/topRight.png";

import BackToRoadmap from "../../components/common/BackToRoadmap";

const PixelTelecom = () => {
  const priorities = [
    "Mobile wallet (non-custodial wallet) with password and biometric authentication for transactions",
    "Mobile game development and release",
  ];

  return (
    <section className="md:px-12 px-3">
      <div className="max-w-[95rem] m-auto  pt-[5rem]">
        <div className="text-white flex sm:justify-between sm:flex-row flex-col sm:items-start items-center pb-8">
          <div className="font-primary pb-7 text-center sm:text-left">
            <h2 className="text-3xl">Pixel Telecom</h2>
            <p className="font-secondary text-2xl pt-2">Mobile Game Release</p>
          </div>
          <BackToRoadmap />
        </div>
        <div className="grid lg100:grid-cols-2 grid-cols-1">
          <div className="text-white flex flex-col justify-center font-secondary border-t border-b border-white py-6 lg100:px-0 px-6 bor border-l lg100:border-l-0 border-r lg100:border-r-0 lg100:order-1 order-2">
            <h2 className="navLg:w-[85%] w-[95%] navLg:text-lg text-base">
              We plan to release a mobile version of our game with all of our
              game modes, social log-ins, and an internal marketplace. Bridging
              the gap between Web2 and Web3.
            </h2>
            <div className="w-[6.5rem] h-1.5 bg-white my-[1.5rem]"></div>
            <div>
              <h2 className="font-primary navLg:pb-5 pb-2.5">
                Mobile Game Priorities
              </h2>
              {priorities.map((priority) => (
                <div className="flex gap-5 mb-2">
                  <div className="w-5 h-5 bg-[#FEAC0E] flex-shrink-0"></div>
                  <h2 className="navLg:text-lg text-base">{priority}</h2>
                </div>
              ))}
            </div>
          </div>
          <div className="relative border border-white lg100:order-2 order-1 flex w-full justify-between">
            <div className="bg-lighBg object-cover w-full h-full absolute z-[-1]"></div>
            <div className="flex self-end h-[50%]">
              <img src={leftBottom} alt="" />
            </div>
            <div className="flex justify-center py-4">
              <img src={pixelImg} alt="" />
            </div>
            <div className="flex justify-end ">
              <img src={topRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PixelTelecom;
