import bgEffect from "../../assets/images/Attributes/BgEffect.png";

const BgEffect = () => {
  return (
    <>
      <div className="bg-black  w-full absolute h-full object-cover z-[-10]"></div>
      <img
        src={bgEffect}
        alt=""
        className="absolute object-cover h-full z-[-2] w-full"
      />
    </>
  );
};

export default BgEffect;
