import { useState } from "react";

const RankItems = ({ rank }) => {
  console.log(rank);
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="">
        {/* Question */}
        <div className="bg-lighBg py-5 border-y border-white">
          <div
            className="flex items-center gap-6 justify-between cursor-pointer text-white w-[80%] m-auto"
            onClick={() => setOpen(!open)}
          >
            <div className="font-secondary font-medium lg:text-4xl sm:text-4xl text-lg faq-question">
              <h2>{rank.type}</h2>
            </div>
            <div
              className={`circle-plus  flex justify-center items-center ${
                open ? "opened" : "closed"
              }`}
            >
              <div className="circle">
                <div className="horizontal"></div>
                <div className="vertical"></div>
              </div>
            </div>
          </div>
        </div>
        {/* Answer */}
        <div
          className={`bg-[#BB3BC3] ${open ? "content contentOpen" : "content"}`}
          style={{
            maxHeight: open ? "1200px" : "0px",
            color: "white",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <div className="pt-5">
            {rank.statistics.map((statistic, index) => (
              <div
                key={index}
                className="w-[80%] m-auto py-5 flex items-center gap-8 font-secondary"
              >
                <h2 className="md:text-2xl sm:text-xl text-base lg:w-[20%] md:w-[45%] w-[55%]">
                  {index + 1}. {statistic.title && `${statistic.title} |`}{" "}
                  {statistic.name}
                </h2>
                <p className="md:text-lg sm:text-base text-xs">
                  Score: {statistic.score}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RankItems;
