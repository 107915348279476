import { useRef } from "react";

import none from "../../../assets/images/Attributes/zoomers/none.png";
import redBaseballCap from "../../../assets/images/Attributes/zoomers/headGear/redBaseballCap.png";
import redBaseballCapFemale from "../../../assets/images/Attributes/zoomers/headGear/redBaseballCapFemale.png";
import blackBianie from "../../../assets/images/Attributes/zoomers/headGear/blackBianie.png";
import blackBianieGirl from "../../../assets/images/Attributes/zoomers/headGear/blackBianieGirl.png";
import yellowHearBand from "../../../assets/images/Attributes/zoomers/headGear/yellowHearBand.png";
import yellowHearBandGirl from "../../../assets/images/Attributes/zoomers/headGear/yellowHearBandGirl.png";
import blueHeadBand from "../../../assets/images/Attributes/zoomers/headGear/blueHeadBand.png";
import blueHeadBandGirl from "../../../assets/images/Attributes/zoomers/headGear/blueHeadBandGirl.png";
import whiteBeanie from "../../../assets/images/Attributes/zoomers/headGear/whiteBeanie.png";
import whiteBeanieGirl from "../../../assets/images/Attributes/zoomers/headGear/whiteBeanieGirl.png";
import greenBeret from "../../../assets/images/Attributes/zoomers/headGear/greenBeret.png";
import greenBeretGirl from "../../../assets/images/Attributes/zoomers/headGear/greenBeretGirl.png";
import greenHeadBand from "../../../assets/images/Attributes/zoomers/headGear/greenHeadBand.png";
import greenHeadBandGirl from "../../../assets/images/Attributes/zoomers/headGear/greenHeadBandGirl.png";
import riceHat from "../../../assets/images/Attributes/zoomers/headGear/riceHat.png";
import riceHatGirl from "../../../assets/images/Attributes/zoomers/headGear/riceHatGirl.png";
import orangeBeanie from "../../../assets/images/Attributes/zoomers/headGear/orangeBeanie.png";
import orangeBeanieGirl from "../../../assets/images/Attributes/zoomers/headGear/orangeBeanieGirl.png";
import redBeanie from "../../../assets/images/Attributes/zoomers/headGear/redBeanie.png";
import redBeanieGirl from "../../../assets/images/Attributes/zoomers/headGear/redBeanieGirl.png";
import whiteBaseballCap from "../../../assets/images/Attributes/zoomers/headGear/whiteBaseballCap.png";
import whiteBaseballCapGirl from "../../../assets/images/Attributes/zoomers/headGear/whiteBaseballCapGirl.png";
import sombrero from "../../../assets/images/Attributes/zoomers/headGear/sombrero.png";
import sombreroGirl from "../../../assets/images/Attributes/zoomers/headGear/sombreroGirl.png";
import roadCap from "../../../assets/images/Attributes/zoomers/headGear/roadCap.png";
import roadCapGirl from "../../../assets/images/Attributes/zoomers/headGear/roadCapGirl.png";
import blueBaseballCap from "../../../assets/images/Attributes/zoomers/headGear/blueBaseballCap.png";
import blueBaseballCapGirl from "../../../assets/images/Attributes/zoomers/headGear/blueBaseballCapGirl.png";
import blackBeret from "../../../assets/images/Attributes/zoomers/headGear/blackBeret.png";
import blackBeretGirl from "../../../assets/images/Attributes/zoomers/headGear/blackBeretGirl.png";
import strawHat from "../../../assets/images/Attributes/zoomers/headGear/strawHat.png";
import catCap from "../../../assets/images/Attributes/zoomers/headGear/catCap.png";
import catCapGirl from "../../../assets/images/Attributes/zoomers/headGear/catCapGirl.png";
import redHeadband from "../../../assets/images/Attributes/zoomers/headGear/redHeadband.png";
import redHeadbandGirl from "../../../assets/images/Attributes/zoomers/headGear/redHeadbandGirl.png";
import policeHat from "../../../assets/images/Attributes/zoomers/headGear/policeHat.png";
import policeHatGirl from "../../../assets/images/Attributes/zoomers/headGear/policeHatGirl.png";
import cowBoyHat from "../../../assets/images/Attributes/zoomers/headGear/cowBoyHat.png";
import cowBoyHatGirl from "../../../assets/images/Attributes/zoomers/headGear/cowBoyHatGirl.png";
import redRibbon from "../../../assets/images/Attributes/zoomers/headGear/redRibbon.png";
import carEars from "../../../assets/images/Attributes/zoomers/headGear/carEars.png";
import whiteRibbon from "../../../assets/images/Attributes/zoomers/headGear/whiteRibbon.png";
import goldCrown from "../../../assets/images/Attributes/zoomers/headGear/goldCrown.png";
import tiara from "../../../assets/images/Attributes/zoomers/headGear/tiara.png";
import bunnyEars from "../../../assets/images/Attributes/zoomers/headGear/bunnyEars.png";
import MultiItemSlider from "../../common/MultiItemSlider";

const HeadGear = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const headGears = [
    {
      name: "None",
      count: 77,
      percent: "34.69",
      img: none,
    },
    {
      name: "Red Baseball Cap",
      count: 21,
      percent: "9.46",
      male: redBaseballCap,
      female: redBaseballCapFemale,
      twoItems: true,
    },
    {
      name: "Black BEANIE",
      count: 13,
      percent: "5.86",
      male: blackBianie,
      female: blackBianieGirl,
      twoItems: true,
    },
    {
      name: "Yellow HEADBAND",
      count: 12,
      percent: "5.41",
      male: yellowHearBand,
      female: yellowHearBandGirl,
      twoItems: true,
    },
    {
      name: "Blue Headband",
      count: 10,
      percent: "4.50",
      male: blueHeadBand,
      female: blueHeadBandGirl,
      twoItems: true,
    },
    {
      name: "White Beanie",
      count: 10,
      percent: "4.50",
      male: whiteBeanie,
      female: whiteBeanieGirl,
      twoItems: true,
    },
    {
      name: "Green Beret",
      count: 10,
      percent: "4.50",
      male: greenBeret,
      female: greenBeretGirl,
      twoItems: true,
    },
    {
      name: "Green Headband",
      count: 10,
      percent: "4.50",
      male: greenHeadBand,
      female: greenHeadBandGirl,
      twoItems: true,
    },
    {
      name: "Rice Hat",
      count: 7,
      percent: "3.15",
      male: riceHat,
      female: riceHatGirl,
      twoItems: true,
    },
    {
      name: "Orange Beanie",
      count: 6,
      percent: "2.70",
      male: orangeBeanie,
      female: orangeBeanieGirl,
      twoItems: true,
    },
    {
      name: "Red Beanie",
      count: 6,
      percent: "2.70",
      male: redBeanie,
      female: redBeanieGirl,
      twoItems: true,
    },
    {
      name: "White Baseball Cap",
      count: 6,
      percent: "2.70",
      male: whiteBaseballCap,
      female: whiteBaseballCapGirl,
      twoItems: true,
    },
    {
      name: "Sombrero",
      count: 6,
      percent: "2.70",
      male: sombrero,
      female: sombreroGirl,
      twoItems: true,
    },
    {
      name: "Road Cap",
      count: 6,
      percent: "2.70",
      male: roadCap,
      female: roadCapGirl,
      twoItems: true,
    },
    {
      name: "Blue Baseball Cap",
      count: 4,
      percent: "1.80",
      male: blueBaseballCap,
      female: blueBaseballCapGirl,
      twoItems: true,
    },
    {
      name: "Black Beret",
      count: 3,
      percent: "1.35",
      male: blackBeret,
      female: blackBeretGirl,
      twoItems: true,
    },
    {
      name: "Straw Hat",
      count: 3,
      percent: "1.35",
      male: strawHat,
    },
    {
      name: "Cat Cap",
      count: 2,
      percent: "0.90",
      male: catCap,
      female: catCapGirl,
      twoItems: true,
    },
    {
      name: "Red Headband",
      count: 2,
      percent: "0.90",
      male: redHeadband,
      female: redHeadbandGirl,
      twoItems: true,
    },
    {
      name: "Police Hat",
      count: 1,
      percent: "0.45",
      male: policeHat,
      female: policeHatGirl,
      twoItems: true,
    },
    {
      name: "Cowboy Hat",
      count: 1,
      percent: "0.45",
      male: cowBoyHat,
      female: cowBoyHatGirl,
      twoItems: true,
    },
    {
      name: "Red Ribbon",
      count: 1,
      percent: "0.45",
      female: redRibbon,
    },
    {
      name: "CAT Ears",
      count: 1,
      percent: "0.45",
      female: carEars,
    },
    {
      name: "White Ribbon",
      count: 1,
      percent: "0.45",
      female: whiteRibbon,
    },
    {
      name: "Gold Crown",
      count: 1,
      percent: "0.45",
      female: goldCrown,
    },
    {
      name: "Tiara",
      count: 1,
      percent: "0.45",
      female: tiara,
    },
    {
      name: "Bunny Ears",
      count: 1,
      percent: "0.45",
      female: bunnyEars,
    },
  ];

  if (selected === "From common to rare") {
    headGears.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    headGears.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <MultiItemSlider
        data={headGears}
        prevRef={prevRef}
        nextRef={nextRef}
        from={"Headgear"}
      />
    </>
  );
};

export default HeadGear;
