import { Link } from "react-router-dom";
import box from "../../assets/images/Roadmap/box.png";
import mapImg from "../../assets/images/Roadmap/map2.png";
import BoxText from "../../components/Roadmap/BoxText";
import "./Roadmap.css";

const Roadmap = () => {
  return (
    <section className=" h-[100%] relative w-full -mb-[81px]">
      {/* Title Container */}
      <div className="w-[80%] m-auto py-20 font-primary text-white sm:text-[2.7rem] text-4xl font-bold">
        <h2>
          <span className="stoke-text">Road</span>map
        </h2>
      </div>
      <div className="font-secondary text-white border-t border-t-white relative">
        {/* top box img  */}
        <img src={box} alt="" className="absolute top-0 left-0 w-[30%]" />

        {/* bottom box img  */}
        <img
          src={box}
          alt=""
          className="absolute bottom-0 right-0 rotate-180 w-[30%]"
        />

        {/* main img */}
        <div className="mainImgContainer py-64 sm:py-0 relative">
          {/* zoomsville speedway */}
          <Link to="/speedWay">
            <div className="absolute zoomsvilleContainer top-[20%] left-[22%]">
              <BoxText
                title="zoomsville speedway"
                width="w-[6rem] md:w-[8rem]"
                afterHeight="after:h-[18rem]"
                name={"zoomsville"}
              />
            </div>
          </Link>

          <img src={mapImg} alt="" className="w-full pointer-events-none" />

          {/* pixel telecom */}
          <Link to={"/pixelTelecom"}>
            <div className="absolute pixelTelecomContainer top-[3%] right-[37%] ">
              <BoxText
                title="pixel telecom"
                width="w-[5rem] md:w-[7rem]"
                afterHeight="after:h-[17rem] after:z-[-1]"
                name={"pixelTelecom"}
              />
            </div>
          </Link>

          {/* game development center */}
          <Link to="/gameDevelopment">
            <div className="absolute gameDevelopmentContainer top-[8%] left-[85%]">
              <BoxText
                title="game development center"
                width="w-[6.5rem] md:w-[9rem]"
                afterHeight="after:h-[17rem]"
                name={"gameDevelopment"}
              />
            </div>
          </Link>

          {/* candy shop */}
          <Link to="/candyShop">
            <div className="absolute candyShopConainer top-[5%] right-[20%]">
              <BoxText
                title="candy shop"
                width="w-[5rem] md:w-[6rem]"
                afterHeight="after:h-[17rem] after:z-[-1]"
                name={"candyShop"}
              />
            </div>
          </Link>

          {/* the zooming ape */}
          <Link to="/zoomingApe">
            <div className="zoomingApeContainer absolute top-[60%] right-[15%]">
              <div
                className={`relative zoomingApe  border after:bottom-[100%] border-white p-2 w-[6.5rem]  md:w-[8rem] text-center after:absolute after:w-[1px]  after:bg-white after:h-[15rem]`}
              >
                <p className="font-secondary text-white text-sm md:text-lg capitalize ">
                  the zooming ape
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
