import { useState } from "react";
import { useNavigate } from "react-router-dom";
import profileDemoImg from "../../assets/images/wallet/profileDemoImg.png";
import Message from "../../components/modals/Message";
import Withdraw from "../../components/modals/Withdraw";
import LinkedAddrss from "../../components/Wallet/LinkedAddrss";
import MyAssets from "../../components/Wallet/MyAssets";

const Wallet = () => {
  const [isWalletAddressCopied, setIsWalletAddressCopied] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const walletAddress = "687425f87v587dfzcgfgrhddfxgfdgyjjjg";
  const accountBalance = 155.02;

  const navigate = useNavigate();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`${walletAddress}`);
    setIsWalletAddressCopied(true);
  };

  return (
    <section className="min-h-screen">
      <div className="md:w-[85%] w-[95%] m-auto">
        <h2 className="titleContainer pt-[3.5rem] pb-[2rem]">
          <span className="stoke-text pr-4">My</span>Wallet
        </h2>
        <div className="w-full h-full bg-engineBg bg-full bg-center bg-no-repeat object-cover c1300:p-20 p-[4rem] grid custom1400:grid-cols-withdraw navLg:grid-cols-withdrawLaptop grid-cols-1">
          <div className="flex flex-col gap-8 justify-between">
            <div className="flex items-center gap-6">
              <img
                src={profileDemoImg}
                alt="profile"
                className="w-[8rem] h-[8rem] rounded-full"
              />
              <div>
                <h2 className="text-white sm:text-xl font-primary text-sm bg-primary px-0.5 pb-1 mb-3">
                  Username
                </h2>
                <p
                  className="font-secondary text-white underline cursor-pointer"
                  onClick={() => {
                    localStorage.removeItem("racers-user");
                    navigate("/");
                    window.location.reload();
                  }}
                >
                  Log Out
                </p>
              </div>
            </div>
            <div className="flex items-center border-[2px] border-white text-lg mt-1 text-white">
              <h2 className="sm:w-[85%] w-[75%] xs:w-[65%] p-3 border-r-[2px] border-white overflow-hidden truncate">
                {walletAddress}
              </h2>
              <p
                className={`flex items-center justify-center gap-1 p-3 cursor-pointer sm:w-[15%] w-[25%] xs:w-[35%] duration-500 ${
                  isWalletAddressCopied
                    ? "text-primary sm:w-[20%]"
                    : "sm:w-[15%]"
                }`}
                onClick={handleCopyToClipboard}
              >
                {isWalletAddressCopied ? "Copied!" : "Copy"}
              </p>
            </div>
            <div>
              <p className="font-secondary text-white">Total Balance</p>
              <h2 className="font-primary text-[#FEAC0E] text-5xl">
                {accountBalance} SOL
              </h2>
            </div>
            <div className="w-full" onClick={() => setWithdrawModalOpen(true)}>
              <button className="bg-[#FEAC0E] py-3 font-primary text-white w-full">
                Withdraw
              </button>
            </div>
          </div>
          <div className="h-full navLg:p-0 py-[5rem] flex justify-center">
            <div className="navLg:w-0.5 w-full rounded-full navLg:h-full h-0.5 bg-white"></div>
          </div>
          <div className="flex flex-col justify-between gap-6">
            <LinkedAddrss />
          </div>
        </div>
        <MyAssets />
      </div>
      <Withdraw
        modalOpen={withdrawModalOpen}
        setModalOpen={setWithdrawModalOpen}
        accountBalance={accountBalance}
        nextOpen={setMessageModalOpen}
      />
      <Message
        message={"Withdrawal will be processed soon."}
        message2={"Thank you."}
        btn="OK"
        color={"gray"}
        modalOpen={messageModalOpen}
        setModalOpen={setMessageModalOpen}
      />
    </section>
  );
};

export default Wallet;
