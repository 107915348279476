import cars from "../../assets/images/Home/NFT/cars.png";
import engines from "../../assets/images/Home/NFT/engines.png";
import special from "../../assets/images/Home/NFT/special.png";
import zoomers from "../../assets/images/Home/NFT/zoomers.png";

const backgrounds = [
  {
    name: "ZOOMERS",
    image: zoomers,
    desc: "Zoomers come with utilities for gamers and holders",
  },
  {
    name: "Cars",
    image: cars,
    desc: "Choose the perfect car to represent you in the game",
  },
  {
    name: "Engines",
    image: engines,
    desc: "Engines determine the performance of your car in our game",
  },
  {
    name: "Special Edition Cars",
    image: special,
    desc: "Show off your special limited edition cars to fellow racers",
  },
];
const Nft = () => {
  return (
    <section className="mt-16 mb-24">
      <div className="customContainer py-20 ">
        <h2 className="font-primary text-white text-5xl font-bold text-center md:text-left">
          <span className="stoke-text">THE </span>NFTS
        </h2>

        <div className="flex justify-center gap-y-10 md:gap-x-16 items-center mt-12 flex-wrap ">
          {backgrounds.map((data, index) => (
            <div
              key={index}
              className="w-[20rem] h-[100%] bg-itemBg bg-no-repeat bg-center bg-full relative p-6 text-white"
            >
              <img src={data.image} alt="" className="w-full" />
              <div className="flex flex-col items-start gap-5 pt-5">
                <h2 className="text-left font-primary text-xl">{data.name}</h2>
                <p className="font-secondary text-xl">{data.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Nft;
