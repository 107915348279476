import React, { useState } from "react";
import Devider from "../../common/Devider";
import Select from "../../common/Select";
import Background from "./Background";
import BodyColor from "./BodyColor";
import BodySripe from "./BodySripe";
import BodyType from "./BodyType";
import Trail from "./Trail";
import WheelColor from "./WheelColor";
import WheelType from "./WheelType";
import WindowColor from "./WindowColor";

const Cars = () => {
  const [selected, setSelected] = useState("");

  const options = ["From common to rare", "From rare to common"];

  const handleSelect = (e) => {
    if (e.target.outerText === "From common to rare") {
      setSelected("From common to rare");
    }
    if (e.target.outerText === "From rare to common") {
      setSelected("From rare to common");
    }
  };

  return (
    <>
      <div className="mt-3 sm:mb-0 mb-12 lg:w-[73%] md:w-[80%] w-[95%] m-auto pt-[5rem]">
        <div className="max-w-[20rem]">
          <Select
            event={handleSelect}
            data={options}
            defaultValue={options[0]}
          />
        </div>
      </div>
      <Background selected={selected} />
      <Devider />
      <BodyType selected={selected} />
      <Devider />
      <BodyColor selected={selected} />
      <Devider />
      <BodySripe selected={selected} />
      <Devider />
      <WindowColor selected={selected} />
      <Devider />
      <WheelType selected={selected} />
      <Devider />
      <WheelColor selected={selected} />
      <Devider />
      <Trail selected={selected} />
    </>
  );
};

export default Cars;
