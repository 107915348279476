import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import SliderArrow from "./SliderArrow";
import GetWidth from "../../hooks/GetWidth";

const MultiItemSlider = ({ from, prevRef, nextRef, data }) => {
  const { width } = GetWidth();

  return (
    <div className="overflow-hidden">
      <div className="lg:w-[73%] md:w-[80%] w-[95%] m-auto md:pt-20 pt-0 pb-20 font-primary text-white md:text-4xl sm:text-2xl text-base items-center font-bold flex justify-between gap-2">
        <h2>{from}</h2>
        <SliderArrow prevRef={prevRef} nextRef={nextRef} />
      </div>
      <div className="md:max-w-[80%] m-auto max-w-[90%]">
        <Swiper
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={width > 1000 ? "auto" : 1}
          spaceBetween={30}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          className="backgroundSwiper"
          modules={[Navigation, Pagination]}
        >
          {data.map((item, index) => (
            <SwiperSlide
              key={index}
              style={{
                width: width > 1000 && item.twoItems ? "50rem" : "35rem",
              }}
            >
              <div
                className={`lg100:h-[22rem] h-full  bg-teamGrayBg bg-no-repeat bg-center bg-full relative p-6 text-white flex items-center justify-center flex-col gap-y-10 lg100:flex-row lg100:gap-x-10 cursor-pointer `}
              >
                <div className=" flex flex-col justify-center items-center gap-5 navLg:text-base text-sm lg100:w-[30%] w-full">
                  <h2 className="text-center font-primary m-auto lg100:text-base sm:text-sm text-xs capitalize">
                    {item.name}
                  </h2>
                  <p className="font-secondary bg-[#BB3BC3] inline py-1 ">
                    Count
                  </p>
                  <p className="font-secondary">{item.count}</p>
                  <p className="font-secondary bg-[#BB3BC3] inline py-1">%</p>
                  <p className="font-secondary">{item.percent}%</p>
                </div>
                <div className="flex items-center gap-4">
                  {item.twoItems ? (
                    <>
                      <div className="flex flex-col items-center">
                        <img src={item.male} alt="" />
                        <h2 className="font-primary py-6 navLg:text-base text-sm">
                          Male
                        </h2>
                      </div>
                      <div className="flex flex-col items-center">
                        <img src={item.female} alt="" />
                        <h2 className="font-primary py-6 navLg:text-base text-sm">
                          Female
                        </h2>
                      </div>
                    </>
                  ) : item.male ? (
                    <div className="flex flex-col items-center">
                      <img
                        src={item.male}
                        alt=""
                        className=""
                        style={{
                          width: width < 630 && width / 2.59,
                        }}
                      />
                      <h2 className="font-primary py-6 navLg:text-base text-sm">
                        Male
                      </h2>
                    </div>
                  ) : item.female ? (
                    <div className="flex flex-col items-center">
                      <img
                        src={item.female}
                        alt=""
                        style={{
                          width: width < 630 && width / 2.59,
                        }}
                      />
                      <h2 className="font-primary py-6 navLg:text-base text-sm">
                        Female
                      </h2>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center">
                      <img
                        src={item.img}
                        alt=""
                        style={{
                          width: width < 630 && width / 2.59,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default MultiItemSlider;
