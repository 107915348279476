import { useEffect, useState } from "react";
import car1 from "../../assets/images/Home/car-scroll/car-1.png";
import car2 from "../../assets/images/Home/car-scroll/car-2.png";
import car3 from "../../assets/images/Home/car-scroll/car-3.png";
import car4 from "../../assets/images/Home/car-scroll/car-4.png";

import carScrollBg from "../../assets/images/Home/car-scroll/carScrollBg.png";

const data = [car1, car2, car3, car4];

const CarScroll = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const get = (offsetY * 0.3 - 720) / 10;
  return (
    <div id="scrolling " className="my-6">
      {window.innerWidth > 768 ? (
        <div
          style={{
            transform: `translate3d(${
              get - 45
            }%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            transition: "all 0.7s ease-out",
          }}
          className="flex  justify-start "
        >
          <div className="w-[100%] flex gap-20 items-center">
            {/* <h2 className="font-primary text-white text-5xl font-bold leading-[58px] mx-4">
              play
              <span className="stoke-text">
                -to-earn, <br />
                participate
                <br />
              </span>
              in
              <span className="stoke-text">
                {" "}
                tournaments
                <br /> and win{" "}
              </span>
              prizes
            </h2> */}
            <img src={carScrollBg} alt="" className="w-full object-cover" />
            <img src={carScrollBg} alt="" className="w-full object-cover" />
          </div>
          {/* <div className=" w-full bg-full bg-carscrollBg  bg-center   flex justify-between items-center gap-x-28 pl-40">
            {data.map((data, index) => (
              <img key={index} src={data} alt="" className="w-[288px] mr-40" />
            ))}
          </div> */}
        </div>
      ) : (
        <div
          style={{
            transform: `translate3d(${
              get - 120
            }%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            transition: "all 0.7s ease-out",
          }}
          className="flex  justify-start "
        >
          <div className="w-[80rem]">
            <h2 className="font-primary text-white text-3xl font-bold leading-[38px] mx-4">
              play
              <span className="stoke-text">
                -to-earn, <br />
                participate
                <br />
              </span>
              in
              <span className="stoke-text">
                {" "}
                tournaments
                <br /> and win{" "}
              </span>
              prizes
            </h2>
          </div>
          <div className=" w-full  bg-carscrollBg  bg-center bg-full   flex justify-between items-center gap-x-28 pl-40 transform">
            {data.map((data, index) => (
              <img key={index} src={data} alt="" className="w-[288px] mr-40" />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CarScroll;
