import { useRef } from "react";

import sedan from "../../../assets/images/Attributes/body/sedan.png";
import suv from "../../../assets/images/Attributes/body/suv.png";
import minivan from "../../../assets/images/Attributes/body/minivan.png";
import coupe from "../../../assets/images/Attributes/body/coupe.png";
import muscle from "../../../assets/images/Attributes/body/muscle.png";
import superCar from "../../../assets/images/Attributes/body/superCar.png";
import antique from "../../../assets/images/Attributes/body/antique.png";
import icecreamTruck from "../../../assets/images/Attributes/body/icecreamTruck.png";
import AttributeSlider from "../../common/AttributeSlider";

const bodyTypes = [
  {
    name: "Sedan",
    image: sedan,
    percent: "26.84",
    count: "2,330",
  },
  {
    name: "SUV",
    image: suv,
    percent: "22.46",
    count: "1,950",
  },
  {
    name: "Coupe",
    image: coupe,
    percent: "16.13",
    count: "1,400",
  },
  {
    name: "Minivan",
    image: minivan,
    percent: "14.78",
    count: "1,283",
  },

  {
    name: "Muscle",
    image: muscle,
    percent: "9.70",
    count: "842",
  },
  {
    name: "Supercar",
    image: superCar,
    percent: "7.08",
    count: "615",
  },
  {
    name: "Antique",
    image: antique,
    percent: "2.44",
    count: "212",
  },
  {
    name: "Ice Cream Truck",
    image: icecreamTruck,
    percent: "0.56",
    count: "49",
  },
];

const BodyType = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (selected === "From common to rare") {
    bodyTypes.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    bodyTypes.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Body Type"}
        data={bodyTypes}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default BodyType;
