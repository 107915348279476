import smilingMale from "../../../assets/images/Attributes/zoomers/mouth/smilingMale.png";
import smilingFemale from "../../../assets/images/Attributes/zoomers/mouth/smilingFemale.png";
import relaxedMale from "../../../assets/images/Attributes/zoomers/mouth/relaxedMale.png";
import pinkRelaxedFemale from "../../../assets/images/Attributes/zoomers/mouth/pinkRelaxedFemale.png";
import smirkFemale from "../../../assets/images/Attributes/zoomers/mouth/smirkFemale.png";
import smirkMale from "../../../assets/images/Attributes/zoomers/mouth/smirkMale.png";
import goldRelaxedFemale from "../../../assets/images/Attributes/zoomers/mouth/goldRelaxedFemale.png";
import poutyFemale from "../../../assets/images/Attributes/zoomers/mouth/poutyFemale.png";
import poutyMale from "../../../assets/images/Attributes/zoomers/mouth/poutyMale.png";
import tongueOutFemale from "../../../assets/images/Attributes/zoomers/mouth/tongueOutFemale.png";
import tongueOutMale from "../../../assets/images/Attributes/zoomers/mouth/tongueOutMale.png";
import blueRelaxed from "../../../assets/images/Attributes/zoomers/mouth/blueRelaxed.png";
import frownFemale from "../../../assets/images/Attributes/zoomers/mouth/frownFemale.png";
import frownMale from "../../../assets/images/Attributes/zoomers/mouth/frownMale.png";
import openFemale from "../../../assets/images/Attributes/zoomers/mouth/openFemale.png";
import openMale from "../../../assets/images/Attributes/zoomers/mouth/openMale.png";
import blackRelaxedFemale from "../../../assets/images/Attributes/zoomers/mouth/blackRelaxed.png";
import laughingFemale from "../../../assets/images/Attributes/zoomers/mouth/laughingFemale.png";
import laughingMale from "../../../assets/images/Attributes/zoomers/mouth/laughingMale.png";
import goldTeethMale from "../../../assets/images/Attributes/zoomers/mouth/goldTeethMale.png";
import { useRef } from "react";
import MultiItemSlider from "../../common/MultiItemSlider";

const Mouth = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const mouths = [
    {
      name: "Smiling",
      count: 41,
      percent: "18.47",
      male: smilingMale,
      female: smilingFemale,
      twoItems: true,
    },
    {
      name: "Relaxed",
      count: 41,
      percent: "18.47",
      male: relaxedMale,
    },
    {
      name: "Pink Relaxed",
      count: 20,
      percent: "9.01",
      female: pinkRelaxedFemale,
    },
    {
      name: "Smirk",
      count: 18,
      percent: "8.11",
      male: smirkMale,
      female: smirkFemale,
      twoItems: true,
    },
    {
      name: "Gold Relaxed",
      count: 17,
      percent: "7.66",
      female: goldRelaxedFemale,
    },
    {
      name: "Pouty",
      count: 16,
      percent: "7.21",
      male: poutyMale,
      female: poutyFemale,
      twoItems: true,
    },
    {
      name: "Tongue Out",
      count: 16,
      percent: "7.21",
      male: tongueOutMale,
      female: tongueOutFemale,
      twoItems: true,
    },
    {
      name: "Blue Relaxed",
      count: 14,
      percent: "6.31",
      female: blueRelaxed,
    },
    {
      name: "Frown",
      count: 12,
      percent: "5.41",
      male: frownMale,
      female: frownFemale,
      twoItems: true,
    },
    {
      name: "Open",
      count: 9,
      percent: "4.05",
      male: openMale,
      female: openFemale,
      twoItems: true,
    },
    {
      name: "Black Relaxed",
      count: 8,
      percent: "3.60",
      female: blackRelaxedFemale,
    },
    {
      name: "Laughing",
      count: 7,
      percent: "3.15",
      male: laughingMale,
      female: laughingFemale,
      twoItems: true,
    },
    {
      name: "Gold Teeth",
      count: 3,
      percent: "1.35",
      male: goldTeethMale,
    },
  ];

  if (selected === "From common to rare") {
    mouths.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    mouths.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <MultiItemSlider
        data={mouths}
        from={"Mouth"}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Mouth;
