import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { NavLink, useNavigate } from "react-router-dom";
import HamburgerIcon from "../../svg/HamburgerIcon";

import logo from "../../assets/images/logo.png";
import arrowDown from "../../assets/images/arrowDown.png";
import twitter from "../../assets/images/Attributes/twitter.png";
import discord from "../../assets/images/Attributes/discord.png";

const Header = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const userLoc = localStorage.getItem("racers-user");
        if (userLoc) {
            setUser(userLoc);
        }
    }, [user]);

    const closeSidebar = () => {
        setSidebarOpen(false);
        setSidebarOpen(false);
    };

    /*
    const navItems = [
        {
            name: "Play",
            link: "/",
        },
        {
            name: "Buy",
            subLinks: [
                {
                    name: "Zoomers",
                    link: "https://magiceden.io/marketplace/pixel_racers_gen_1_zoomers",
                },
                {
                    name: "Cars",
                    link: "https://magiceden.io/marketplace/pixel_racers",
                },
                {
                    name: "Engines",
                    link: "https://magiceden.io/marketplace/pixelracersgen1engines",
                },
                {
                    name: "Pit Crew",
                    link: "https://magiceden.io/marketplace/pixelracersgen1pitcrew",
                },

                {
                    name: "Special Cars",
                    link: "https://magiceden.io/marketplace/pixelracersgen1honoraries",
                },
            ],
        },
        {
            name: "Attributes",
            link: "/attributes",
        },
        {
            name: "Merch",
            link: "/purchase",
        },
        {
            name: "Roadmap",
            link: "/roadmap",
        },
        {
            name: "Rankings",
            link: "/rankings",
        },
        {
            name: "Stats",
            link: "/stats",
        },
        {
            name: "FAQ",
            link: "https://pixelracers.gitbook.io/learn/about/what-is-pixel-racers",
        },
    ];
    */

    //const navItems = [
    //    {
    //        name: "Play",
    //        link: "/",
    //    },
    //    {
    //        name: "Buy",
    //        subLinks: [
    //            {
    //                name: "Zoomers",
    //                link: "https://magiceden.io/marketplace/pixel_racers_gen_1_zoomers",
    //            },
    //            {
    //                name: "Cars",
    //                link: "https://magiceden.io/marketplace/pixel_racers",
    //            },
    //            {
    //                name: "Engines",
    //                link: "https://magiceden.io/marketplace/pixelracersgen1engines",
    //            },
    //            {
    //                name: "Pit Crew",
    //                link: "https://magiceden.io/marketplace/pixelracersgen1pitcrew",
    //            },

    //            {
    //                name: "Special Cars",
    //                link: "https://magiceden.io/marketplace/pixelracersgen1honoraries",
    //            },
    //        ],
    //    },
    //    {
    //        name: "Attributes",
    //        link: "/attributes",
    //    },
    //    {
    //        name: "Roadmap",
    //        link: "/roadmap",
    //    },
    //    {
    //        name: "Merchandise",
    //        link: "/merch",
    //    },
    //    {
    //        name: "FAQ",
    //        link: "https://pixelracers.gitbook.io/learn/about/what-is-pixel-racers",
    //    },
    //];

    const navItems = [
        {
            name: "Home",
            link: "/",
        },
        {
            name: "Buy",
            subLinks: [
                {
                    name: "Zoomers",
                    link: "https://magiceden.io/marketplace/pixel_racers_gen_1_zoomers",
                },
                {
                    name: "Cars",
                    link: "https://magiceden.io/marketplace/pixel_racers",
                },
                {
                    name: "Engines",
                    link: "https://magiceden.io/marketplace/pixelracersgen1engines",
                },
                {
                    name: "Pit Crew",
                    link: "https://magiceden.io/marketplace/pixelracersgen1pitcrew",
                },

                {
                    name: "Special Cars",
                    link: "https://magiceden.io/marketplace/pixelracersgen1honoraries",
                },
            ],
        },
        {
            name: "Attributes",
            link: "/attributes",
        },
        {
            name: "FAQ",
            link: "https://pixelracers.gitbook.io/learn/about/what-is-pixel-racers",
        },
    ];

    return (
        <>
            <header
                className={`z-[100] w-full bg-transparent relative duration-500`}
            >
                <div className="py-6 flex justify-between text-white font-secondary md:w-[85%] w-[95%] m-auto md:pl-[2.6%]">
                    <div className="flex items-center navLg:gap-[8rem] gap-[3rem]">
                        <NavLink to="/">
                            {" "}
                            <img src={logo} alt="logo" className="xs:w-[5.5rem]" />
                        </NavLink>
                        <div className="navBreak:flex hidden gap-8 items-center ">
                            <button
                                className="relative flex items-center gap-2 buyBtn text-lg"
                                onClick={() => {
                                    setSubMenuOpen(!subMenuOpen);
                                }}
                            >
                                {navItems[1].name}
                                <img src={arrowDown} alt="arrowDown" />
                                <div
                                    className={`absolute flex flex-col items-start bg-white w-[10rem] gap-2 p-4 top-7 duration-500 subMenuContainer transform translate-y-[-2rem] opacity-0 pointer-events-none  border border-white rounded-lg`}
                                >
                                    {navItems[1].subLinks.map((subLink, index) => (
                                        <a
                                            key={index}
                                            href={subLink.link}
                                            className={`hover:text-[#FEAC0E] text-black font-medium duration-500  ${subLink.disable
                                                ? "cursor-not-allowed pointer-events-none"
                                                : "cursor-pointer"
                                                }`}
                                            onClick={closeSidebar}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {subLink.name}
                                        </a>
                                    ))}
                                </div>
                            </button>
                            {navItems.slice(2, 3).map((navItem, index) => (
                                <NavLink
                                    key={index}
                                    to={navItem.link}
                                    className={({ isActive }) =>
                                        !isActive
                                            ? "hover:text-[#FEAC0E] text-lg duration-500"
                                            : "text-lg text-[#FEAC0E] duration-500"
                                    }
                                >
                                    {navItem.name}
                                </NavLink>
                            ))}
                            <a
                                href={navItems.slice(-1)[0].link}
                                className="hover:text-[#FEAC0E]"
                                onClick={closeSidebar}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {navItems.slice(-1)[0].name}
                            </a>
                        </div>
                    </div>

                    <div className={`flex items-center sm:gap-6 gap-4`}>
                        <a
                            href="https://twitter.com/pixelracersnft"
                            target={"_blank"}
                            rel="noopener noreferrer"
                            className="hidden navBreak:block"
                        >
                            <img
                                src={twitter}
                                alt="twitter"
                                className="sm:w-auto w-[2.5rem] xs:w-[1.7rem]"
                            />
                        </a>
                        <a
                            href="https://discord.com/invite/sJyp4gMGJD"
                            target={"_blank"}
                            rel="noopener noreferrer"
                            className="hidden navBreak:block"
                        >
                            <img
                                src={discord}
                                alt="discord"
                                className="sm:w-auto w-[2.5rem] xs:w-[1.7rem]"
                            />
                        </a>
                        <div className="navBreak:hidden flex items-center gap-8">
                            <button
                                className="text-secondary"
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                            >
                                <HamburgerIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="nav"
                    className="fixed inset-0 z-[500] navBreak:hidden flex"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex w-full max-w-xs flex-1 flex-col bg-black pt-5 pb-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-0 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-8 w-8 text-white" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex flex-shrink-0 justify-center items-center px-4">
                                <NavLink to={"about"}>
                                    <img src={logo} alt="logo" />
                                </NavLink>
                            </div>
                            <nav
                                className="mt-5 h-full flex-shrink-0 overflow-y-auto"
                                aria-label="Sidebar"
                            >
                                <div className=" px-2">
                                    <div className="flex flex-col gap-8 w-[80%] m-auto text-white ">
                                        <button
                                            className="relative flex items-center gap-2"
                                            onClick={() => {
                                                setSubMenuOpen(!subMenuOpen);
                                            }}
                                        >
                                            {navItems[1].name}
                                            <img src={arrowDown} alt="arrowDown" />
                                            <div
                                                className={`absolute flex flex-col text-xl items-start bg-white w-[10rem] gap-2 p-4 top-7 duration-500 ${subMenuOpen
                                                    ? "opacity-1 pointer-events-auto"
                                                    : "opacity-0 pointer-events-none"
                                                    } border border-white rounded-lg`}
                                            >
                                                {navItems[1].subLinks.map((subLink, index) => (
                                                    <a
                                                        key={index}
                                                        href={subLink.link}
                                                        className="hover:text-[#FEAC0E] text-black font-medium text-sm"
                                                        onClick={closeSidebar}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {subLink.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </button>
                                        {navItems.slice(2, 3).map((navItem, index) => (
                                            <NavLink
                                                key={index}
                                                to={navItem.link}
                                                className={({ isActive }) =>
                                                    !isActive
                                                        ? "hover:text-[#FEAC0E] text-lg"
                                                        : "text-lg text-[#FEAC0E]"
                                                }
                                                onClick={closeSidebar}
                                            >
                                                {navItem.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                                <span className="flex items-center justify-center gap-6 pt-6">
                                    <a
                                        href="https://twitter.com/pixelracersnft"
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={twitter}
                                            alt="twitter"
                                            className="sm:w-auto w-[2.5rem]"
                                        />
                                    </a>
                                    <a
                                        href="https://discord.com/invite/sJyp4gMGJD"
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={discord}
                                            alt="discord"
                                            className="sm:w-auto w-[2.5rem]"
                                        />
                                    </a>
                                </span>
                            </nav>
                        </div>
                    </Transition.Child>
                    <div className="w-14 flex-shrink-0" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default Header;
