import { useRef } from "react";

import white from "../../../assets/images/Attributes/colors/white.png";
import black from "../../../assets/images/Attributes/colors/black.png";
import gray from "../../../assets/images/Attributes/colors/gray.png";
import red from "../../../assets/images/Attributes/colors/red.png";
import yellow from "../../../assets/images/Attributes/colors/yellow.png";
import blue from "../../../assets/images/Attributes/colors/blue.png";
import green from "../../../assets/images/Attributes/colors/green.png";
import orange from "../../../assets/images/Attributes/colors/orange.png";
import purple from "../../../assets/images/Attributes/colors/purple.png";
import mintFlame from "../../../assets/images/Attributes/colors/mintFlame.png";
import hotPink from "../../../assets/images/Attributes/colors/hotPink.png";
import clamouflage from "../../../assets/images/Attributes/colors/clamouflage.gif";
import rainbow from "../../../assets/images/Attributes/colors/rainbow.png";
import AttributeSlider from "../../common/AttributeSlider";

const bodyColors = [
  {
    name: "White",
    image: white,
    percent: "15.03",
    count: "1,305",
  },
  {
    name: "Black",
    image: black,
    percent: "14.80",
    count: "1,285",
  },
  {
    name: "Gray",
    image: gray,
    percent: "12.78",
    count: "1,109",
  },
  {
    name: "Red",
    image: red,
    percent: "12.12",
    count: "1,052",
  },
  {
    name: "Blue",
    image: blue,
    percent: "11.89",
    count: "1,032",
  },
  {
    name: "Yellow",
    image: yellow,
    percent: "11.73",
    count: "1,018",
  },
  {
    name: "Green",
    image: green,
    percent: "5.79",
    count: "503",
  },
  {
    name: "Orange",
    image: orange,
    percent: "4.77",
    count: "414",
  },
  {
    name: "Purple",
    image: purple,
    percent: "4.56",
    count: "396",
  },
  {
    name: "Mint Flame",
    image: mintFlame,
    percent: "2.43",
    count: "211",
  },
  {
    name: "Hot Pink",
    image: hotPink,
    percent: "2.02",
    count: "175",
  },
  {
    name: "Camouflage",
    image: clamouflage,
    percent: "1.54",
    count: "134",
  },
  {
    name: "Rainbow",
    image: rainbow,
    percent: "0.54",
    count: "47",
  },
];

const BodyColor = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (selected === "From common to rare") {
    bodyColors.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    bodyColors.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        prevRef={prevRef}
        nextRef={nextRef}
        data={bodyColors}
        from="Body Color"
      />
    </>
  );
};

export default BodyColor;
