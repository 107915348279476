import pitCrewOne from "../../../assets/images/Attributes/pitCrew/pitCrewOne.png";

const PitCrew = () => {
  const pitCres = [
    {
      name: "Background",
      type: "Pit Crew",
    },
    {
      name: "Window Color",
      type: "Special",
    },

    {
      name: "Body Type",
      type: "Pit Crew",
    },
    {
      name: "Wheel Type",
      type: "Four Spoke",
    },
    {
      name: "Body Color",
      type: "Pit Crew",
    },
    {
      name: "Wheel Color",
      type: "Solana",
    },
    {
      name: "Body Stripe",
      type: "None",
    },
    {
      name: "Trail",
      type: "Rainbow",
    },
  ];

  return (
    <section className="mdL:max-w-[63rem] w-full m-auto pt-20 pb-20">
      <div className="px-6">
        <div className="w-full h-full bg-engineBg bg-full bg-center bg-no-repeat p-6  text-white flex gap-6 mdL:flex-row flex-col">
          <div className="mdL:w-[45%] w-full flex flex-col items-center ">
            <img src={pitCrewOne} alt="" />
            <div className="pt-6">
              <h2 className="font-primary text-center">PIT CREW</h2>
            </div>
          </div>
          <div className="grid grid-cols-2  gap-y-8 gap-x-20 py-4 h-[80%]  justify-center auto-cols-auto">
            {pitCres.map((data, index) => (
              <div key={index}>
                <h2 className="bg-[#BB3BC3] capitalize font-primary inline py-1.5 lg:text-base text-sm">
                  {data.name}
                </h2>
                <p className="font-secondary pt-2 lg:text-base text-xs">
                  {data.type}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PitCrew;
