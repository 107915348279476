import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputRequired from "../common/InputRequired";

import copy from "../../assets/images/copy.png";
import copyColor from "../../assets/images/copyColor.png";

const OrderForm = ({ modalOpen, setModalOpen, event, merch, selectedData, country }) => {
    const [isWalletAddressCopied, setIsWalletAddressCopied] = useState(false);
    const [inputtedFormData, setInputtedFormData] = useState({});
    const walletAddress = "3srMt364intH3KZSdrRp5vUAshnN4k8y6w69eSSo1HJi";

    useEffect(() => {
        setInputtedFormData({
            ...inputtedFormData,
            productId: selectedData.id,
            country: country.country,
        });
    }, [selectedData, country])

    const calculationNonHolder = [
        {
            name: "Product price:",
            value: merch?.fullPrice,
            currency: "SOLANA",
        },
        {
            name: "Shipping price:",
            value: country?.shippingCost,
            currency: "SOLANA",
        },
        {
            name: "Total:",
            value:
                Number(merch.fullPrice) +
                Number(country?.shippingCost),
            currency: "SOLANA",
        },
    ];

    const calculationHolder = [
        {
            name: "Product price:",
            value: merch?.holderPrice,
            currency: "SOLANA",
        },
        {
            name: "Shipping price:",
            value: country?.shippingCost,
            currency: "SOLANA",
        },
        {
            name: "Total:",
            value:
                Number(merch.holderPrice) +
                Number(country?.shippingCost),
            currency: "SOLANA",
        },
    ];

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(`${walletAddress}`);
        setIsWalletAddressCopied(true);
    };

    const hadleFormData = (e) => {
        setInputtedFormData({
            ...inputtedFormData,
            [e.target.name]: e.target.value,
        });
    };
    const handleNumberInput = (e) => {
        const regex = /^[0-9 +\b]+/;
        const lastChar = e.target.value.slice(-1);
        if (regex.test(lastChar)) {
            setInputtedFormData({
                ...inputtedFormData,
                [e.target.name]: e.target.value,
            });
        }
    };

    console.log(inputtedFormData)

    return (
        <>
            <Transition.Root show={modalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={setModalOpen}
                >
                    <div className="flex min-h-full items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black/30 backdrop-blur bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:h-screen sm:align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 sm:align-middle">
                                <form
                                    className="sm:w-[90%] w-[97%] m-auto h-full"
                                    onSubmit={(e) => {
                                        console.log(inputtedFormData);

                                        event(e, inputtedFormData);
                                        setModalOpen(false);
                                    }}
                                >
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title
                                            as="div"
                                            className="text-2xl font-semibold leading-6 text-gray-900 flex items-center gap-8 font-primary"
                                        >
                                            ORDER FORM
                                        </Dialog.Title>
                                    </div>
                                    <div className="mt-5 sm:mt-6 font-secondary">
                                        <InputRequired
                                            className={""}
                                            label="First Name"
                                            type={"text"}
                                            name="firstName"
                                            placeholder="e.g. John"
                                            event={hadleFormData}
                                        />
                                        <InputRequired
                                            className={"mt-6"}
                                            label="Last Name"
                                            type={"text"}
                                            name="lastName"
                                            placeholder="e.g. Doe"
                                            event={hadleFormData}
                                        />
                                        <InputRequired
                                            className={"mt-6"}
                                            label="Email"
                                            type={"email"}
                                            name="email"
                                            placeholder="e.g. example@example.com"
                                            event={hadleFormData}
                                        />
                                        <div className="mt-6">
                                            <label
                                                htmlFor="phoneNumber"
                                                className="text-white sm:text-xl text-sm bg-primary px-0.5 py-1"
                                            >
                                                Phone Number (including country code)
                                            </label>
                                            <input
                                                type={"tel"}
                                                id={"phoneNumber"}
                                                name={"phoneNumber"}
                                                required={true}
                                                placeholder="e.g. +1 (555) 555-5555"
                                                className="focus:outline-primary w-full border-[2px] p-2 mt-1 border-black"
                                                onChange={hadleFormData}
                                            />
                                        </div>
                                        <InputRequired
                                            className={"mt-6"}
                                            label="Address"
                                            type={"text"}
                                            name="address"
                                            placeholder="e.g. 123 Main St."
                                            event={hadleFormData}
                                        />
                                        <div className="mt-6">
                                            <label
                                                htmlFor="country"
                                                className="text-white sm:text-xl text-sm bg-primary px-0.5 py-1"
                                            >
                                                Country
                                            </label>
                                            <input
                                                disabled
                                                type={"text"}
                                                id={"country"}
                                                name={"country"}
                                                required={true}
                                                className="focus:outline-primary w-full border-[2px] p-2 mt-1 border-black"
                                                value={country.country}
                                                onChange={hadleFormData}
                                            />
                                        </div>
                                        <InputRequired
                                            className={"mt-6"}
                                            label="Postal Code"
                                            type={"text"}
                                            name="zipcode"
                                            placeholder="e.g. 12345"
                                            event={hadleFormData}
                                        />
                                        <InputRequired
                                            className={"mt-6"}
                                            label="Discord ID"
                                            type={"text"}
                                            name="discordId"
                                            placeholder="e.g. name #1234"
                                            event={hadleFormData}
                                        />
                                        <div className="flex justify-between gap-6 xs:gap-2 xs:flex-wrap xs:justify-center items-center mt-3 font-secondary">
                                            <label
                                                className="text-white sm:text-xl text-sm bg-primary px-0.5 py-1"
                                            >
                                                Price (Non Holder)
                                            </label>
                                        </div>
                                        <div className="flex justify-between gap-6 xs:gap-2 xs:flex-wrap xs:justify-center items-center py-6 font-secondary">
                                            {calculationNonHolder.map((item, index) => (
                                                <div key={index}>
                                                    <h4
                                                        className={`text-lg font-medium ${item.name === "Total:"
                                                            ? "text-white bg-primary px-0.5 py-1 capitalize inline"
                                                            : "text-center"
                                                            }`}
                                                    >
                                                        {item.name}
                                                    </h4>
                                                    <p
                                                        className={`flex flex-col justify-center items-center p-3 ${item.name === "Total:"
                                                            ? "border-[2px] border-black mt-1 text-[#F6A70D]"
                                                            : ""
                                                            }`}
                                                    >
                                                        <span className="font-primary font-semibold sm:text-lg text-base sm:text-center text-left">
                                                            {item.value} USDC
                                                        </span>
                                                        <span className="font-medium">{`(${item.currency})`}</span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex justify-between gap-6 xs:gap-2 xs:flex-wrap xs:justify-center items-center mt-3 font-secondary">
                                            <label
                                                className="text-white sm:text-xl text-sm bg-primary px-0.5"
                                            >
                                                Price (Holder)
                                            </label>
                                        </div>
                                        <div className="flex justify-between gap-6 xs:gap-2 xs:flex-wrap xs:justify-center items-center py-6 font-secondary">
                                            {calculationHolder.map((item, index) => (
                                                <div key={index}>
                                                    <h4
                                                        className={`text-lg font-medium ${item.name === "Total:"
                                                            ? "text-white bg-primary px-0.5 py-1 capitalize inline"
                                                            : "text-center"
                                                            }`}
                                                    >
                                                        {item.name}
                                                    </h4>
                                                    <p
                                                        className={`flex flex-col justify-center items-center p-3 ${item.name === "Total:"
                                                            ? "border-[2px] border-black mt-1 text-[#F6A70D]"
                                                            : ""
                                                            }`}
                                                    >
                                                        <span className="font-primary font-semibold sm:text-lg text-base sm:text-center text-left">
                                                            {item.value} USDC
                                                        </span>
                                                        <span className="font-medium">{`(${item.currency})`}</span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        <label className="text-white sm:text-xl text-sm bg-primary px-0.5 py-1 capitalize">
                                            SEND USDC TO:
                                        </label>
                                        <div className="flex items-center border-[2px] border-black text-lg mt-1">
                                            <h2 className="sm:w-[85%] w-[75%] xs:w-[65%] text-[#8D8D8D] p-3 border-r-[2px] border-black overflow-hidden">
                                                {walletAddress}
                                            </h2>
                                            <p
                                                className={`flex items-center justify-center gap-1 p-3 cursor-pointer sm:w-[15%] w-[25%] xs:w-[35%] duration-500 ${isWalletAddressCopied ? "text-primary" : ""
                                                    }`}
                                                onClick={handleCopyToClipboard}
                                            >
                                                <img
                                                    src={isWalletAddressCopied ? copyColor : copy}
                                                    alt=""
                                                />
                                                {isWalletAddressCopied ? "Copied!" : "Copy"}
                                            </p>
                                        </div>
                                        <InputRequired
                                            className={"mt-6"}
                                            label="Paste the Solscan transaction link for your payment"
                                            type={"text"}
                                            name="transactionAddress"
                                            placeholder="e.g. https://solscan.io/transaction/12345"
                                            event={hadleFormData}
                                        />
                                        <div className="pt-12 pb-5 flex justify-end">
                                            <button
                                                type="submit"
                                                className="bg-[#F6A70D] text-white px-8 py-4 font-primary sm:text-2xl text-xl font-semibold"
                                            >
                                                SUBMIT ORDER
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default OrderForm;
