const Button = ({ name, color }) => {
  return (
    <button
      className={`font-primary text-2xl py-4 px-9 cursor-pointer relative  ${
        color === "pink"
          ? "border border-[#BB3BC3] bg-transparent text-white"
          : "bg-[#FEAC0E] teaxt-black downloadBtn"
      }  inline mr-7`}
    >
      {name}
    </button>
  );
};

export default Button;
