import { useRef } from "react";

import everGreen from "../../../assets/images/Attributes/backgrounds/everGreen.png";
import desert from "../../../assets/images/Attributes/backgrounds/desert.png";
import wareHouse from "../../../assets/images/Attributes/backgrounds/wareHouse.png";
import plainWhite from "../../../assets/images/Attributes/backgrounds/plainWhite.png";
import innerCity from "../../../assets/images/Attributes/backgrounds/innerCity.png";
import solana from "../../../assets/images/Attributes/backgrounds/solana.png";
import wonderLand from "../../../assets/images/Attributes/backgrounds/wonderLand.png";
import glitch from "../../../assets/images/Attributes/backgrounds/theGlitch.png";
import apoloclyps from "../../../assets/images/Attributes/backgrounds/apoloclyps.png";
import moonRide from "../../../assets/images/Attributes/backgrounds/moonRide.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import AttributeSlider from "../../common/AttributeSlider";

const Background = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const backgrounds = [
    {
      name: "Evergreen Stars",
      image: everGreen,
      count: "1,651",
      percent: "19.02",
    },
    {
      name: "Desert",
      image: desert,
      percent: "18.45",
      count: "1,602",
    },
    {
      name: "Warehouse",
      image: wareHouse,
      percent: "15.92",
      count: "1,382",
    },
    {
      name: "Plain White",
      image: plainWhite,
      percent: "13.73",
      count: "1,192",
    },
    {
      name: "Inner City",
      image: innerCity,
      percent: "10.30",
      count: "894",
    },
    {
      name: "Solana summer",
      image: solana,
      percent: "9.39",
      count: "815",
    },
    {
      name: "Wonderland",
      image: wonderLand,
      percent: "4.99",
      count: "433",
    },
    {
      name: "The Glitch",
      image: glitch,
      percent: "3.47",
      count: "301",
    },
    {
      name: "Apocalypse",
      image: apoloclyps,
      percent: "3.33",
      count: "289",
    },
    {
      name: "Moonride",
      image: moonRide,
      percent: "1.41",
      count: "122",
    },
  ];
  if (selected === "From common to rare") {
    backgrounds.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    backgrounds.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Background"}
        data={backgrounds}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Background;
