import { NavLink, Routes, Route } from "react-router-dom";

import Cars from "../../components/AttributesComponent/Cars/Cars";
import Engines from "../../components/AttributesComponent/Engines/Engines";
import PitCrew from "../../components/AttributesComponent/Pit Crew/PitCrew";
import SpecialCars from "../../components/AttributesComponent/SpecialCars/SpecialCars";
import Zoomers from "../../components/AttributesComponent/Zoomers/Zoomers";

const Attributes = () => {
  return (
    <section className=" h-[100%] relative w-full">
      {/* Title Container */}
      <div className="w-[80%] m-auto py-20 font-primary text-white sm:text-[2.7rem] text-4xl font-bold">
        <h2>
          <span className="stoke-text">At</span>tributes
        </h2>
      </div>
      {/* Items Option Container */}
      <div className="mt-[3rem] border-b-[1px] border-white text-white font-secondary text-xl">
        <div className="lg:pl-[8%] pl-[3%] flex gap-4 lg100:flex-nowrap flex-wrap justify-center lg100:justify-start">
          <NavLink
            to={"/attributes"}
            className={({ isActive }) =>
              `acttributeOptions ${isActive && "attributeActive"}`
            }
            end={true}
          >
            Zoomers
          </NavLink>
          <NavLink
            to={"/attributes/cars"}
            className={({ isActive }) =>
              `acttributeOptions ${isActive && "attributeActive"}`
            }
          >
            Cars
          </NavLink>
          <NavLink
            to={"/attributes/engines"}
            className={({ isActive }) =>
              `acttributeOptions ${isActive && "attributeActive"}`
            }
          >
            Engines
          </NavLink>
          <NavLink
            to={"/attributes/pit-crew"}
            className={({ isActive }) =>
              `acttributeOptions ${isActive && "attributeActive"}`
            }
          >
            Pit Crew
          </NavLink>
          <NavLink
            to={"/attributes/special-cars"}
            className={({ isActive }) =>
              `acttributeOptions ${isActive && "attributeActive"}`
            }
          >
            Special Cars
          </NavLink>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<Zoomers />} />
        <Route path="/cars" element={<Cars />} />
        <Route path="/engines" element={<Engines />} />
        <Route path="/pit-crew" element={<PitCrew />} />
        <Route path="/special-cars" element={<SpecialCars />} />
      </Routes>
    </section>
  );
};

export default Attributes;
