export { default as Attributes } from "./Attributes/Attributes";
export { default as Home } from "./Home/Home";
export { default as Purchase } from "./Purchase/Purchase";
export { default as Play } from "./Play/Play";
export { default as Rankings } from "./Rankings/Rankings";
export { default as Roadmap } from "./Roadmap/Roadmap";
export { default as Stats } from "./Stats/Stats";
export { default as GameDevelopment } from "./Roadmap/GameDevelopment";
export { default as CandyShop } from "./Roadmap/CandyShop";
export { default as ZoomsVilla } from "./Roadmap/ZoomsVilla";
export { default as ZoomingApe } from "./Roadmap/ZoomingApe";
export { default as PixelTelecom } from "./Roadmap/PixelTelecom";
export { default as Merch } from "./Merch/Merch";
export { default as CreateAccount } from "./CreateAccount/CreateAccount";
export { default as Login } from "./Login/Login";
export { default as Wallet } from "./Wallet/Wallet";
export { default as PrivacyPolicy } from "./PrivacyPolicy/PrivacyPolicy";
