import { useEffect, useState } from "react";

const data = [
  { name: "PIXEL" },
  { name: "RACERS" },
  { name: "PIXEL" },
  { name: "RACERS" },
  { name: "PIXEL" },
  { name: "RACERS" },
  { name: "PIXEL" },
  { name: "RACERS" },
  { name: "PIXEL" },
  { name: "RACERS" },
];

const ScrollingText = ({ isreverse, mobileSize }) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const get = (offsetY * 0.3 - 720) / 10;

  const slideStyle = {
    transform: `translate3d(${
      isreverse ? -get - 20 : get - 20
    }%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
    transition: "all 0.7s ease-out",
  };
  const slideMobileStyle = {
    transform: `translate3d(${
      isreverse ? -get - 20 : get - 70
    }%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
    transition: "all 0.7s ease-out",
  };

  return (
    <div id="scrolling " className="my-6">
      {window.innerWidth > 768 ? (
        <div style={slideStyle} className="flex items-center">
          {data.map((data, index) => (
            <h2
              key={index}
              className={`font-primary text-white text-5xl font-bold mx-4 ${
                index % 2 === 1 && "stoke-text"
              }`}
            >
              {data.name}
            </h2>
          ))}
        </div>
      ) : (
        <div style={slideMobileStyle} className="flex items-center">
          {data.map((data, index) => (
            <h2
              key={index}
              className={`font-primary text-white text-5xl font-bold mx-4 ${
                index % 2 === 1 && "stoke-text"
              }`}
            >
              {data.name}
            </h2>
          ))}
        </div>
      )}
    </div>
  );
};

export default ScrollingText;
