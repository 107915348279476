import none from "../../../assets/images/Attributes/stripe/none.png";
import black from "../../../assets/images/Attributes/stripe/black.gif";
import white from "../../../assets/images/Attributes/stripe/white.gif";
import AttributeSlider from "../../common/AttributeSlider";
import { useRef } from "react";

const bodyStripes = [
  {
    name: "None",
    image: none,
    percent: "95.69",
    count: "8,307",
  },
  {
    name: "White",
    image: white,
    percent: "2.18",
    count: "189",
  },
  {
    name: "Black",
    image: black,
    percent: "2.13",
    count: "185",
  },
];

const BodySripe = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (selected === "From common to rare") {
    bodyStripes.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    bodyStripes.sort((a, b) => {
      return a.percent - b.percent;
    });
  }
  return (
    <>
      <AttributeSlider
        data={bodyStripes}
        from="Body Stripe"
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default BodySripe;
