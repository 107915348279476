import { useRef, useState } from "react";

import CloseIcos from "../../svg/CloseIcos";

import { tournaments } from "../../data/TournamentsData";
import { useEffect } from "react";

const Tournaments = () => {
  const [active, setActive] = useState("");
  const scrollRef = useRef();

  const handleClose = () => {
    setActive("");
  };

  const handleOpen = (name) => {
    setActive(name);
    scollIntoView();
  };

  const scollIntoView = (e) => {
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    if (active) {
      scollIntoView();
    }
  });

  return (
    <section>
      <div className="w-[80%] m-auto py-20 font-primary text-white sm:text-[2.7rem] text-3xl font-bold">
        <h2>
          <span className="stoke-text">Tour</span>naments
        </h2>
      </div>
      <div className="max-w-[90%] m-auto flex flex-wrap justify-center gap-8">
        {tournaments.map((tournament, index) => (
          <div
            className={`tournamentContainer bg-rankingBg border border-white p-4 w-[35rem] overflow-hidden ${
              active === tournament.name ? "w-[71rem] m-auto" : "w-[35rem]"
            } ${
              active === ""
                ? "block"
                : active === tournament.name
                ? "block"
                : "hidden"
            }`}
          >
            <div>
              <div
                className={`justify-end ${
                  active === tournament.name ? "flex" : "hidden"
                }`}
              >
                <CloseIcos event={handleClose} />
              </div>
              <div className="flex mdL:flex-row flex-col">
                {/* Tournamen Info */}
                <div
                  className={`font-secondary text-white ${
                    active === tournament.name
                      ? "mdL:w-[50%] w-full"
                      : "w-[100%]"
                  } flex flex-col items-center p-4`}
                >
                  <div>
                    <h2 className="text-center text-2xl pb-6">
                      {tournament.title}
                    </h2>
                    <img
                      src={tournament.img}
                      ref={scrollRef}
                      alt=""
                      className="object-cover cursor-pointer"
                      onClick={() => handleOpen(tournament.name)}
                    />
                  </div>
                  <div
                    className="overflow-hidden"
                    style={{
                      maxHeight: active === tournament.name ? "1200px" : "0px",
                      color: "white",
                      transition: "all 0.5s ease-in-out",
                    }}
                  >
                    <div className="self-start py-5">
                      <h2>
                        <span className="text-xl pr-2">Start: </span>
                        {tournament.start}
                      </h2>
                      <h2>
                        <span className="text-xl pr-2">End: </span>
                        {tournament.end}
                      </h2>
                      <p className="pt-4">{tournament.description}</p>
                    </div>
                    <div className="self-start pb-6">
                      <h2 className="text-xl pb-1.5">Project Intro</h2>
                      <p>{tournament.projectIntro}</p>
                    </div>
                    <div className="self-start text-lg pb-6">
                      <h2 className="pb-0.5">Entries</h2>
                      <p>{tournament.entries}</p>
                    </div>
                    <div className="self-start">
                      <h2 className="text-lg">Prizes</h2>
                      {tournament.prizes.map((prize, index) => (
                        <p key={index}>
                          - {prize.position}: {prize.prize}
                        </p>
                      ))}
                      <p>* {tournament.desclaimer}</p>
                    </div>
                  </div>
                  <div
                    className={` self-start flex-row sm:flex-col pt-6 text-xl pl-0 p-4 gap-4 sm:gap-0 ${
                      active === tournament.name ? "flex" : "hidden"
                    }`}
                  >
                    <a
                      href="https://twitter.com/SecretShibs"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className=" text-[#FEAC0E] hover:text-white hover:underline duration-500"
                    >
                      Twitter
                    </a>
                    <a
                      href="https://discord.gg/ourkennel"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className=" text-[#FEAC0E] hover:text-white hover:underline duration-500"
                    >
                      Discord
                    </a>
                  </div>
                </div>
                {/* Tournamen Statistics */}
                <div
                  className={`${
                    active === tournament.name ? "block" : "hidden"
                  } mdL:w-[50%] w-full md:p-4 p-0`}
                >
                  <div className="mdL:border-l border-t mdL:border-t-0 border-white md:p-4 p-0 pt-5 md:pt-0">
                    {tournament.score.map((score, index) => (
                      <div className="flex items-center justify-start  smC:flex-row text-white font-secondary w-full gap-3 mb-3">
                        <div className="w-[40%] flex items-center gap-1.5">
                          <div
                            className={`relative md:p-2 p-0 ${
                              index === 0
                                ? "bg-flagOne"
                                : index === 1
                                ? "bg-flagTwo"
                                : index === 2
                                ? "bg-flagThree"
                                : ""
                            } bg-full bg-center bg-no-repeat`}
                          >
                            <p className="">{index + 1}.</p>
                          </div>
                          <h2 className="lg:text-base text-sm">
                            {score.title && `${score.title} |`} {score.name}
                          </h2>
                        </div>
                        <p className="lg:text-sm text-xs">
                          Score: {score.score} / EntryCount: {score.entryCount}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Tournaments;
