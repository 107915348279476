import ScrollingText from "./ScrollingText";

const Scrolls = () => {
  return (
    <div>
      <ScrollingText isreverse={false} />
      <ScrollingText isreverse={true} />
      <ScrollingText isreverse={false} />
    </div>
  );
};

export default Scrolls;
