import { Route, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import "./App.css";
import BgEffect from "./components/common/BgEffect";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import {
    Attributes,
    Home,
    Play,
    Rankings,
    Roadmap,
    Stats,
    GameDevelopment,
    CandyShop,
    ZoomsVilla,
    ZoomingApe,
    PixelTelecom,
    Merch,
    Purchase,
    CreateAccount,
    Login,
    Wallet,
    PrivacyPolicy
} from "./pages";
import upArrow from "./assets/images/Attributes/arrow.png";

import ComingSoon from "./pages/Play/ComingSoon";

function App() {
    const hideSome = true;

    return (
        <main className="h-[100%] relative w-full">
            <BgEffect />
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                {!hideSome && (
                    <>
                        <Route path="/rankings" element={<Rankings />} />
                        <Route path="/stats" element={<Stats />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/wallet" element={<Wallet />} />
                        <Route path="/merch" element={<Merch />} />
                        <Route path="/purchase/:itemId" element={<Purchase />} />
                        <Route path="/create/account" element={<CreateAccount />} />
                        <Route path="/roadmap" element={<Roadmap />} />
                        <Route path="/gameDevelopment" element={<GameDevelopment />} />
                        <Route path="/candyShop" element={<CandyShop />} />
                        <Route path="/speedWay" element={<ZoomsVilla />} />
                        <Route path="/zoomingApe" element={<ZoomingApe />} />
                        <Route path="/pixelTelecom" element={<PixelTelecom />} />
                        <Route path="/play" element={<ComingSoon />} />
                    </>
                )}
                <Route path="/attributes/*" element={<Attributes />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
            <ScrollToTop
                smooth
                style={{
                    backgroundColor: "transparent",
                }}
                component={
                    <div className="toTopContainer flex justify-center items-center hover:bg-[#BB3BC3] duration-500">
                        <img
                            src={upArrow}
                            alt=""
                            className="brightness-0 invert-[1] transform rotate-[270deg]"
                        />
                    </div>
                }
            />
        </main>
    );
}

export default App;
