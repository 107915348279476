import none from "../../../assets/images/Attributes/zoomers/none.png";
import silverChain from "../../../assets/images/Attributes/zoomers/neck/silverChain.png";
import blackChocker from "../../../assets/images/Attributes/zoomers/neck/blackChocker.png";
import whiteChoker from "../../../assets/images/Attributes/zoomers/neck/whiteChoker.png";
import redChoker from "../../../assets/images/Attributes/zoomers/neck/redChoker.png";
import goldChain from "../../../assets/images/Attributes/zoomers/neck/goldChain.png";
import { useRef } from "react";
import AttributeSlider from "../../common/AttributeSlider";

const Neck = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const necks = [
    {
      name: "None",
      count: 201,
      percent: "90.54",
      image: none,
    },
    {
      name: "Silver Chain",
      count: 7,
      percent: "3.15",
      image: silverChain,
    },
    {
      name: "Black Choker",
      count: 5,
      percent: "2.25",
      image: blackChocker,
    },
    {
      name: "White Choker",
      count: 3,
      percent: "1.35",
      image: whiteChoker,
    },
    {
      name: "Red Choker",
      count: 3,
      percent: "1.35",
      image: redChoker,
    },
    {
      name: "Gold Chain",
      count: 3,
      percent: "1.35",
      image: goldChain,
    },
  ];

  if (selected === "From common to rare") {
    necks.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    necks.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Neck"}
        data={necks}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Neck;
