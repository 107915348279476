import devider from "../../assets/images/Attributes/devider.png";

const Devider = () => {
  return (
    <>
      <div className="pt-[6rem]">
        <img src={devider} alt="" className="w-full object-contain h-full" />
      </div>
    </>
  );
};

export default Devider;
