import speedWay from "../../assets/images/Roadmap/speedWay/speedway.png";
import topLeft from "../../assets/images/Roadmap/gameDevelopment/topLeft.png";
import rightBottom from "../../assets/images/Roadmap/gameDevelopment/rightBottom.png";

import BackToRoadmap from "../../components/common/BackToRoadmap";

const ZoomsVilla = () => {
  // const priorities = [
  //   "Zoomer talent power-ups (in-game)",
  //   "Share of paid tournament prize pool distributed to Zoomer holders",
  //   "Engine power-up airdrop to holders",
  //   "Car mutation airdrop to holders",
  // ];

  return (
    <>
      <section className="md:px-12 px-3">
        <div className="max-w-[95rem] m-auto  pt-[5rem]">
          <div className="text-white flex sm:justify-between sm:flex-row flex-col sm:items-start items-center pb-8">
            <div className="font-primary pb-7 text-center sm:text-left">
              <h2 className="text-3xl">Zoomsville Speedway</h2>
              <p className="font-secondary text-2xl pt-2">Second Game Launch</p>
            </div>
            <BackToRoadmap />
          </div>
          <div className="grid lg100:grid-cols-2 grid-cols-1">
            <div className="text-white flex flex-col justify-center font-secondary border-t border-b border-white py-6 lg100:px-0 px-6 bor border-l lg100:border-l-0 border-r lg100:border-r-0 lg100:order-1 order-2">
              <h2 className="navLg:w-[85%] w-[95%] navLg:text-lg text-base">
                As we always say, Pixel Racers is a gaming metaverse, not a
                single game.
              </h2>
              <h2 className="navLg:w-[85%] w-[95%] navLg:text-lg text-base pt-[2rem]">
                Once our first game gets enough traction and our community
                grows, we’ll begin developing our second game that puts your
                Pixel Racers NFTs to good use.
              </h2>
            </div>
            <div className="relative border border-white lg100:order-2 order-1">
              <div className="bg-lighBg object-cover w-full h-full absolute z-[-1]"></div>
              <div className="flex justify-start">
                <img src={topLeft} alt="" />
              </div>
              <div className="flex justify-center">
                <img src={speedWay} alt="" />
              </div>
              <div className="flex justify-end">
                <img src={rightBottom} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZoomsVilla;
