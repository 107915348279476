import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ChevronUpIcon } from "@heroicons/react/outline";

import PurchaseSelect from "../../components/Purchase/PurchaseSelect";

import purchasePrices from "../../data/PurchasePrices";
import Loading from "../../components/modals/Loading";
import OrderForm from "../../components/modals/OrderForm";
import Message from "../../components/modals/Message";

const Purchase = () => {
    const axios = require('axios');

    const { itemId } = useParams();

    const viewRef = React.useRef(null);
    const [merch, setMerch] = React.useState(null);
    const [products, setProducts] = React.useState(null);
    const [confirmMessage, setConfirmMessage] = React.useState(false);
    const [buyFormOpen, setBuyFormOpen] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState(null);
    const [country, setCountry] = React.useState(null);

    useEffect(() => {
        if (!merch) {
            axios.get(`https://api.pixelracers.io/merch/${itemId}`)
                .then((response) => {
                    if (response.data.success) {
                        setMerch(response.data.data.merch);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [merch]);

    useEffect(() => {
        if (!products) {
            axios.get(`https://api.pixelracers.io/merch/products/${itemId}`)
                .then((response) => {
                    if (response.data.success) {
                        setProducts(response.data.data.products);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [products]);

    useEffect(() => {
        viewRef.current.scrollIntoView({ behavior: "smooth" });
    }, [viewRef]);

    const handleCountryChange = (e, country) => {
        setCountry(country);
    };

    const handleSizeChange = (prod) => {
        setSelectedData(prod);
    };

    const handlePlaceOrder = (e, formData) => {
        e.preventDefault();

        axios.post(`https://api.pixelracers.io/merch/order`, formData)
            .then((response) => {
                if (response.data.success) {
                    setConfirmMessage(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <section className="w-full h-full">
            <div className="custom1700:w-[80%] w-[90%] m-auto py-20 font-primary text-white sm:text-[2.4rem] text-4xl font-bold">
                <h2>
                    <span className="stoke-text">Pur</span>chase
                </h2>
            </div>
            <div
                className="custom1700:w-[80%] w-[90%] m-auto grid lg100:grid-cols-2 grid-cols-1 navLg:gap-[1rem] lg:gap-2 gap-12 bg-border bg-center bg-full navLg:p-[3.4rem] sm:p-[2rem] p-[1rem]"
                ref={viewRef}
            >
                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Pixel Racers</span> <span>Logo Hoodie</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        The Pixel Racers Logo Hoodie is the first item of our physical goods
                        collection. Coming in a classic black color, the hoodie flaunts our
                        text logo on the left chest, with a bigger city logo covering the
                        back.
                    </p>
                    <div className="flex items-center gap-5 font-primary">
                        {products?.map((prod, index) => (
                            <p key={index}
                                className={`sizeIcon ${selectedData?.type === prod.type ? "bg-primary" : ""
                                    }`}
                                onClick={() => handleSizeChange(prod)}
                            >
                                {prod.type}
                            </p>
                        ))}

                    </div>

                    {selectedData && (
                        <div className="flex items-center gap-6 flex-wrap">
                            <p className="text-center lgC:text-base text-[#3de3dd]">{selectedData.quantity - selectedData.sold} LEFT</p>
                        </div>
                    )}

                    <div className="flex items-center gap-6 flex-wrap">
                        <div className="sm:min-w-[15rem] min-w-fullw">
                            <PurchaseSelect
                                data={purchasePrices}
                                defaultValue={purchasePrices[0].country}
                                event={handleCountryChange}
                            />
                        </div>
                        <p className="text-center lgC:text-base text-sm">
                            Shipping: {country?.shippingCost} USDC
                        </p>
                    </div>
                    <div className="flex items-center gap-6">
                        <h2 className="font-primary text-[#FEAC0E] lgC:text-[1.7rem] text-[1.3rem] flex flex-col">
                            ${merch?.fullPrice} USDC
                        </h2>
                    </div>
                    <div className="flex items-center gap-6">
                        <h2 className="font-primary text-[#FEACAA] lgC:text-[1.7rem] text-[1.1rem] flex flex-col">
                            ${merch?.holderPrice} USDC for Zoomer holders.
                        </h2>
                    </div>
                    <p className="text-[#FF7B7B] text-xs">
                        ** Zoomer NFT should be in the same wallet as the wallet which was used to do the transfer.
                        Zoomer should be in the wallet until shipping was made, we will verify the presence of the NFT in the wallet
                        when shipping.
                    </p>
                    {(selectedData && country) ? (
                        <div className="grid grid-cols-2">
                            <button
                                className="lgC:h-[4rem] h-[3.7rem] bg-[#FEAC0E] font-primary lgC:text-xl text-base border border-[#FEAC0E]"
                                onClick={() => {
                                    setBuyFormOpen(true);
                                }}
                            >
                                Buy
                            </button>
                        </div>
                    ) : (
                        <div>
                            <div className="flex items-center">
                                <h2 className="font-primary text-[#b82a94] lgC:text-[1.7rem] text-[1.1rem]">
                                    Please select a Size and a Country.
                                </h2>
                            </div>
                        </div>
                    )}

                </div>
                <div className="flex justify-center lg100:order-2 order-1">
                    <img
                        src={merch?.showcasePicture}
                        alt=""
                        className="lg100:w-full md:w-[60%] sm:w-[75%] w-full"
                    />
                </div>
            </div>
            {country && selectedData && (
                <OrderForm
                    modalOpen={buyFormOpen}
                    setModalOpen={setBuyFormOpen}
                    event={handlePlaceOrder}
                    merch={merch}
                    selectedData={selectedData}
                    country={country}
                />
            )}
            <Message
                modalOpen={confirmMessage}
                setModalOpen={setConfirmMessage}
                message={
                    "Your order has been placed. A moderator will reach you on our discord for the next step. Than you for supporting our project. ZOOM ZOOM !"
                }
                btn={"OK"}
            />
        </section>
    );
};

export default Purchase;
