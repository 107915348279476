import React, { useRef } from "react";

import pinkHoodieMale from "../../../assets/images/Attributes/zoomers/clothings/pinkhoodieMale.png";
import pinkHoodieFemale from "../../../assets/images/Attributes/zoomers/clothings/pinkhoodieFemale.png";
import racingUniformMale from "../../../assets/images/Attributes/zoomers/clothings/racingUniformMale.png";
import racingUniformFemale from "../../../assets/images/Attributes/zoomers/clothings/racingUniformFemale.png";
import hawaiinShirtMale from "../../../assets/images/Attributes/zoomers/clothings/hawaiinShirtMale.png";
import hawaiinShirtFemale from "../../../assets/images/Attributes/zoomers/clothings/hawaiinShirtFemale.png";
import blueHoodieMale from "../../../assets/images/Attributes/zoomers/clothings/blueHoodieMale.png";
import blueHoodieFemale from "../../../assets/images/Attributes/zoomers/clothings/blueHoodieFemale.png";
import rbRacingUniformFemale from "../../../assets/images/Attributes/zoomers/clothings/rbRacingUniformFemale.png";
import rbRacingUniformMale from "../../../assets/images/Attributes/zoomers/clothings/rbRacingUnifromMale.png";
import StripedShirtFemale from "../../../assets/images/Attributes/zoomers/clothings/StripedShirtFemale.png";
import StripedShirtMale from "../../../assets/images/Attributes/zoomers/clothings/stripedShirtMale.png";
import blackHoodieFemale from "../../../assets/images/Attributes/zoomers/clothings/blackHoodieFemale.png";
import blackHoodieMale from "../../../assets/images/Attributes/zoomers/clothings/blackHoodieMale.png";
import whiteHoodieFemale from "../../../assets/images/Attributes/zoomers/clothings/whiteHoodieFemale.png";
import whiteHoodieMale from "../../../assets/images/Attributes/zoomers/clothings/whiteHoodieMale.png";
import blueMilitaryUniformMale from "../../../assets/images/Attributes/zoomers/clothings/blueMilitaryUniformMale.png";
import blueMilitaryUniformFemale from "../../../assets/images/Attributes/zoomers/clothings/blurMilitaryUniformFemale.png";
import purpleHoodieFemale from "../../../assets/images/Attributes/zoomers/clothings/purpleHoodieFemale.png";
import purpleHoodieMale from "../../../assets/images/Attributes/zoomers/clothings/purpleHoodieMale.png";
import greenMilitaryUniformFemale from "../../../assets/images/Attributes/zoomers/clothings/greenMilitaryUniformFemale.png";
import greenMilitaryUniformMale from "../../../assets/images/Attributes/zoomers/clothings/greenMilitaryUniformMale.png";
import solanaTShirtFemale from "../../../assets/images/Attributes/zoomers/clothings/solanaTShirtFemale.png";
import solanaTShirtMale from "../../../assets/images/Attributes/zoomers/clothings/solanaTShirtMale.png";
import blackTurtlineckFemale from "../../../assets/images/Attributes/zoomers/clothings/blackTurtlineckFemale.png";
import blackTurtlineckMale from "../../../assets/images/Attributes/zoomers/clothings/blackTurtlineckMale.png";
import tieDyeTShirtFemale from "../../../assets/images/Attributes/zoomers/clothings/tieDyeTShirtFemale.png";
import tieDyeTShirtMale from "../../../assets/images/Attributes/zoomers/clothings/tieDyeTShirtMale.png";
import goldTShirtMale from "../../../assets/images/Attributes/zoomers/clothings/goldTShirtMale.png";
import blackWengingFemale from "../../../assets/images/Attributes/zoomers/clothings/blackWengingFemale.png";
import blackWengingMale from "../../../assets/images/Attributes/zoomers/clothings/blackWengingMale.png";
import whiteSoccerJerseyMale from "../../../assets/images/Attributes/zoomers/clothings/whiteSoccerJerseyMale.png";
import blueSoccerJerseyMale from "../../../assets/images/Attributes/zoomers/clothings/blueSoccerJerseyMale.png";
import maidUniformFemale from "../../../assets/images/Attributes/zoomers/clothings/maidUniformFemale.png";
import yellowHandBookFemale from "../../../assets/images/Attributes/zoomers/clothings/yellowHandBookFemale.png";
import redJerseyMale from "../../../assets/images/Attributes/zoomers/clothings/redJerseyMale.png";
import blueDragonUkataFemale from "../../../assets/images/Attributes/zoomers/clothings/blueDragonUkataFemale.png";
import pinkKimonoFemale from "../../../assets/images/Attributes/zoomers/clothings/pinkKimonoFemale.png";
import aqaHankbookFemale from "../../../assets/images/Attributes/zoomers/clothings/aqaHankbookFemale.png";
import blackLeaterCoatMale from "../../../assets/images/Attributes/zoomers/clothings/blackLeaterCoatMale.png";
import navyShirtMale from "../../../assets/images/Attributes/zoomers/clothings/navyShirtMale.png";
import mintHanbokMale from "../../../assets/images/Attributes/zoomers/clothings/mintHanbokMale.png";
import yellowJerseyMale from "../../../assets/images/Attributes/zoomers/clothings/yellowJerseyMale.png";
import MultiItemSlider from "../../common/MultiItemSlider";

const Clothing = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const clothings = [
    {
      name: "Pink Hoodie",
      count: 19,
      percent: "8.56",
      male: pinkHoodieMale,
      female: pinkHoodieFemale,
      twoItems: true,
    },
    {
      name: "Navy and Yellow Racing Uniform",
      count: 18,
      percent: "8.11",
      male: racingUniformMale,
      female: racingUniformFemale,
      twoItems: true,
    },
    {
      name: "Hawaiian Shirt",
      count: 18,
      percent: "8.11",
      male: hawaiinShirtMale,
      female: hawaiinShirtFemale,
      twoItems: true,
    },
    {
      name: "Blue Hoodie",
      count: 17,
      percent: "7.66",
      male: blueHoodieMale,
      female: blueHoodieFemale,
      twoItems: true,
    },
    {
      name: "Red and Black Racing Uniform",
      count: 16,
      percent: "7.21",
      male: rbRacingUniformMale,
      female: rbRacingUniformFemale,
      twoItems: true,
    },
    {
      name: "Red and Black Striped Shirt",
      count: 16,
      percent: "7.21",
      male: StripedShirtMale,
      female: StripedShirtFemale,
      twoItems: true,
    },
    {
      name: "Black Hoodie",
      count: 15,
      percent: "6.76",
      male: blackHoodieMale,
      female: blackHoodieFemale,
      twoItems: true,
    },
    {
      name: "White Hoodie",
      count: 14,
      percent: "6.31",
      male: whiteHoodieMale,
      female: whiteHoodieFemale,
      twoItems: true,
    },
    {
      name: "Blue Military Uniform",
      count: 12,
      percent: "5.41",
      male: blueMilitaryUniformMale,
      female: blueMilitaryUniformFemale,
      twoItems: true,
    },
    {
      name: "Purple Hoodie",
      count: 11,
      percent: "4.95",
      male: purpleHoodieMale,
      female: purpleHoodieFemale,
      twoItems: true,
    },
    {
      name: "Green Military Uniform",
      count: 11,
      percent: "4.95",
      male: greenMilitaryUniformMale,
      female: greenMilitaryUniformFemale,
      twoItems: true,
    },
    {
      name: "Solana T-shirt",
      count: 8,
      percent: "3.60",
      male: solanaTShirtMale,
      female: solanaTShirtFemale,
      twoItems: true,
    },
    {
      name: "Black Turtleneck",
      count: 7,
      percent: "3.15",
      male: blackTurtlineckMale,
      female: blackTurtlineckFemale,
      twoItems: true,
    },
    {
      name: "Tie-dye T-shirt",
      count: 6,
      percent: "2.70",
      male: tieDyeTShirtMale,
      female: tieDyeTShirtFemale,
      twoItems: true,
    },
    {
      name: "Gold T-shirt",
      count: 4,
      percent: "1.80",
      male: goldTShirtMale,
      twoItems: false,
    },
    {
      name: "Black Wengine T-shirt",
      count: 4,
      percent: "1.80",
      male: blackWengingMale,
      female: blackWengingFemale,
      twoItems: true,
    },
    {
      name: "Light Blue and White Soccer Jersey",
      count: 4,
      percent: "1.80",
      male: whiteSoccerJerseyMale,
      twoItems: false,
    },
    {
      name: "Blue Soccer Jersey",
      count: 4,
      percent: "1.80",
      male: blueSoccerJerseyMale,
      twoItems: false,
    },
    {
      name: "Maid Uniform",
      count: 3,
      percent: "1.35",
      female: maidUniformFemale,
      twoItems: false,
    },
    {
      name: "Yellow Hanbok",
      count: 2,
      percent: "0.90",
      female: yellowHandBookFemale,
      twoItems: false,
    },
    {
      name: "Red Jersey",
      count: 2,
      percent: "0.90",
      male: redJerseyMale,
      twoItems: false,
    },
    {
      name: "Green Dragon Yukata",
      count: 2,
      percent: "0.90",
      female: blueDragonUkataFemale,
      twoItems: false,
    },
    {
      name: "Pink Kimono",
      count: 2,
      percent: "0.90",
      female: pinkKimonoFemale,
      twoItems: false,
    },
    {
      name: "Aqua Hanbok",
      count: 2,
      percent: "0.90",
      female: aqaHankbookFemale,
      twoItems: false,
    },
    {
      name: "Black Leather Coat",
      count: 2,
      percent: "0.90",
      male: blackLeaterCoatMale,
      twoItems: false,
    },
    {
      name: "Navy Shirt",
      count: 1,
      percent: "0.45",
      female: navyShirtMale,
      twoItems: false,
    },
    {
      name: "Mint Hanbok",
      count: 1,
      percent: "0.45",
      male: mintHanbokMale,
      twoItems: false,
    },
    {
      name: "Yellow Jersey",
      count: 1,
      percent: "0.45",
      male: yellowJerseyMale,
      twoItems: false,
    },
  ];

  if (selected === "From common to rare") {
    clothings.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    clothings.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <MultiItemSlider
        data={clothings}
        from="Clothing"
        nextRef={nextRef}
        prevRef={prevRef}
      />
    </>
  );
};

export default Clothing;
