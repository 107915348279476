import { useState } from "react";

import car from "../../assets/images/login&signup/car.png";
import leftBlock from "../../assets/images/login&signup/signupBlockLeft.png";
import bottomBlock from "../../assets/images/login&signup/signupBlockBottom.png";
import InputRequired from "../../components/common/InputRequired";
import { useEffect } from "react";
import SeedPhase from "../../components/modals/SeedPhase";
import Message from "../../components/modals/Message";
import ErrorMessage from "../../components/common/ErrorMessage";

const CreateAccount = () => {
    const axios = require('axios');

    const [inputtedData, setInputtedData] = useState({
        userName: "",
        password: "",
        confirmPassword: "",
    });
    const [userNameTaken, setUserNameTaken] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [passwordLength, setPasswordLength] = useState(false);
    const [seedPhaseOpen, setSeedPhaseOpen] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);

    const handleChange = (e) => {
        setInputtedData({ ...inputtedData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setUserNameTaken(false);

        if (!userNameTaken && passwordsMatch && passwordLength) {
            axios.post('https://api.pixelracers.io/create', { username: inputtedData.userName, password: inputtedData.password })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.success) {
                        setSeedPhaseOpen(true);
                    } else {
                        if (response.data.errorCode == "00002") {
                            setUserNameTaken(true);
                        }
                    }
                });
        }
    };

    //   Check if the password and confirm password are the same
    useEffect(() => {
        if (
            inputtedData.confirmPassword !== "" &&
            inputtedData.password !== inputtedData.confirmPassword
        ) {
            setPasswordsMatch(false);
        } else {
            setPasswordsMatch(true);
        }
    }, [inputtedData.password, inputtedData.confirmPassword]);

    //   Check password length
    useEffect(() => {
        inputtedData.password.length < 8 && inputtedData.password !== ""
            ? setPasswordLength(false)
            : setPasswordLength(true);
    }, [inputtedData.password]);

    return (
        <section className="relative">
            <div className="md:absolute relative w-[50%] md:w-auto left-0 md:top-20">
                <img src={leftBlock} alt="" className="w-full" />
            </div>
            <div className="lgC:w-[90%] md:w-[96%] w-[85%] m-auto grid md:grid-cols-2 grid-cols-1 gap-4 md:min-h-[90vh] h-full relative">
                <div className="h-full flex justify-center items-center">
                    <div>
                        <img src={car} alt="" className="w-full createAccountCar" />
                    </div>
                </div>
                <div className="font-secondary h-full flex flex-col justify-center gap-12">
                    <h2 className="titleContainer">
                        <span className="stoke-text block">Create</span>account
                    </h2>
                    <form className="flex flex-col gap-9" onSubmit={handleSubmit}>
                        <div className="relative">
                            <InputRequired
                                className={""}
                                label="Username"
                                type={"text"}
                                name="userName"
                                placeholder="e.g. johndoe524"
                                color={"white"}
                                event={handleChange}
                            />
                            <ErrorMessage error={userNameTaken}>
                                Username taken, please select a new one.
                            </ErrorMessage>
                        </div>
                        <div className="relative">
                            <InputRequired
                                className={""}
                                label="Password"
                                type={"password"}
                                name="password"
                                color={"white"}
                                event={handleChange}
                            />
                            <ErrorMessage error={!passwordLength}>
                                Password must be at least 8 characters.
                            </ErrorMessage>
                        </div>
                        <div className="relative">
                            <InputRequired
                                className={""}
                                label="Confirm Password"
                                type={"password"}
                                name="confirmPassword"
                                color={"white"}
                                event={handleChange}
                            />
                            <ErrorMessage error={!passwordsMatch}>
                                Please make sure your passwords match.
                            </ErrorMessage>
                        </div>
                        <div className="flex font-primary justify-end">
                            <button
                                className={`
                px-9 py-3 text-white text-lg border duration-500 ${!userNameTaken &&
                                        passwordsMatch &&
                                        passwordLength &&
                                        inputtedData.userName !== "" &&
                                        inputtedData.password !== "" &&
                                        inputtedData.confirmPassword !== ""
                                        ? "bg-[#FEAC0E] border-[#FEAC0E] cursor-pointer"
                                        : "bg-[#363636] border-white cursor-not-allowed"
                                    }
                `}
                                type="submit"
                            >
                                Ok
                            </button>
                        </div>
                    </form>
                </div>
                <div className="absolute left-[10%] bottom-[-3rem] pointer-events-none">
                    <img src={bottomBlock} alt="" className="w-fit" />
                </div>
            </div>
            <SeedPhase
                modalOpen={seedPhaseOpen}
                setModalOpen={setSeedPhaseOpen}
                setMessageModal={setMessageModalOpen}
            />
            <Message
                message={"Congrats, your account has been created!"}
                message2={"Click below to log in."}
                btn="Login"
                color={"gray"}
                btnColor={"gray"}
                modalOpen={messageModalOpen}
                setModalOpen={setMessageModalOpen}
            />
        </section>
    );
};

export default CreateAccount;
