import brozeLvele1 from "../../../assets/images/Attributes/zoomers/talent/brozeLvele1.png";
import broozeLover2 from "../../../assets/images/Attributes/zoomers/talent/broozeLover2.png";
import lightNingReaction1 from "../../../assets/images/Attributes/zoomers/talent/lightNingReaction1.png";
import lightingReaction2 from "../../../assets/images/Attributes/zoomers/talent/lightingReaction2.png";
import goodlyEyesight1 from "../../../assets/images/Attributes/zoomers/talent/goodlyEyesight1.png";
import godlyEyeSight2 from "../../../assets/images/Attributes/zoomers/talent/godlyEyeSight2.png";
import veteran1 from "../../../assets/images/Attributes/zoomers/talent/veteran1.png";
import veteran2 from "../../../assets/images/Attributes/zoomers/talent/veteran2.png";
import inDaZone1 from "../../../assets/images/Attributes/zoomers/talent/inDaZone1.png";
import inDaZone2 from "../../../assets/images/Attributes/zoomers/talent/inDaZone2.png";
import coldBlooded1 from "../../../assets/images/Attributes/zoomers/talent/coldBlooded1.png";
import coldBlooded2 from "../../../assets/images/Attributes/zoomers/talent/coldBlooded2.png";
import clutchMaster1 from "../../../assets/images/Attributes/zoomers/talent/clutchMaster1.png";
import clutchMaster2 from "../../../assets/images/Attributes/zoomers/talent/clutchMaster2.png";
import { useRef } from "react";
import AttributeSlider from "../../common/AttributeSlider";

const Talent = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const talents = [
    {
      name: "Booze Lover 1",
      count: 33,
      percent: "14.86",
      image: brozeLvele1,
    },
    {
      name: "Lightning Reaction 1",
      count: 26,
      percent: "11.71",
      image: lightNingReaction1,
    },
    {
      name: "Godly Eyesight 1",
      count: 25,
      percent: "11.26",
      image: goodlyEyesight1,
    },
    {
      name: "Veteran 1",
      count: 25,
      percent: "11.26",
      image: veteran1,
    },
    {
      name: "Clutch Master 1",
      count: 23,
      percent: "10.36",
      image: clutchMaster1,
    },
    {
      name: "In Da Zone 1",
      count: 21,
      percent: "9.46",
      image: inDaZone1,
    },
    {
      name: "Cold Blooded 1",
      count: 13,
      percent: "5.86",
      image: coldBlooded1,
    },
    {
      name: "Godly Eyesight 2",
      count: 13,
      percent: "5.86",
      image: godlyEyeSight2,
    },
    {
      name: "Veteran 2",
      count: 8,
      percent: "3.60",
      image: veteran2,
    },
    {
      name: "Cold Blooded 2",
      count: 8,
      percent: "3.60",
      image: coldBlooded2,
    },
    {
      name: "In Da Zone 2",
      count: 7,
      percent: "3.15",
      image: inDaZone2,
    },
    {
      name: "Clutch Master 2",
      count: 7,
      percent: "3.15",
      image: clutchMaster2,
    },
    {
      name: "Lightning Reaction 2",
      count: 7,
      percent: "3.15",
      image: lightingReaction2,
    },
    {
      name: "Booze Lover 2",
      count: 6,
      percent: "2.70",
      image: broozeLover2,
    },
  ];

  if (selected === "From common to rare") {
    talents.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    talents.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Talent"}
        data={talents}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Talent;
