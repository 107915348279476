import SeeMore from "../common/SeeMore";

import alphaDog1 from "../../assets/images/Attributes/specialCars/alphaDog1.png";
import alphaDog2 from "../../assets/images/Attributes/specialCars/alphaDog2.png";
import anglid from "../../assets/images/Attributes/specialCars/anglid.png";
import bunjil from "../../assets/images/Attributes/specialCars/bunjil.png";
import celon from "../../assets/images/Attributes/specialCars/celon.png";
import issaHodl from "../../assets/images/Attributes/specialCars/issaHodl.png";
import jords from "../../assets/images/Attributes/specialCars/jords.png";
import magellan from "../../assets/images/Attributes/specialCars/magellan.png";
import { useState } from "react";

const MyAssets = () => {
  const [assetsShowing, setAssetsShowing] = useState(8);

  const assets = [
    {
      name: "Name",
      image: alphaDog1,
    },
    {
      name: "Name",
      image: alphaDog2,
    },
    {
      name: "Name",
      image: anglid,
    },
    {
      name: "Name",
      image: bunjil,
    },
    {
      name: "Name",
      image: celon,
    },

    {
      name: "Name",
      image: issaHodl,
    },
    {
      name: "Name",
      image: jords,
    },
    {
      name: "Name",
      image: magellan,
    },
    {
      name: "Name",
      image: alphaDog1,
    },
    {
      name: "Name",
      image: alphaDog2,
    },
    {
      name: "Name",
      image: anglid,
    },
    {
      name: "Name",
      image: bunjil,
    },
    {
      name: "Name",
      image: celon,
    },

    {
      name: "Name",
      image: issaHodl,
    },
  ];

  return (
    <>
      <h2 className="titleContainer pt-[4.5rem] pb-[3rem]">
        <span className="stoke-text pr-4">My</span>Assets
      </h2>
      <div className="grid custom1400:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-9">
        {assets.slice(0, assetsShowing).map((asset, index) => (
          <div className="bg-assetCardBg bg-full bg-center w-full h-full p-7 ">
            <div className="w-auto">
              <img src={asset.image} alt="" className="w-full" />
            </div>
            <h2 className="text-center pt-5 font-primary text-white lg:text-xl text-base">
              {asset.name}
            </h2>
          </div>
        ))}
      </div>
      {assets.length > assetsShowing && (
        <SeeMore
          onClick={() => setAssetsShowing(assetsShowing + 4)}
          mainArray={assets}
          currntlyShowing={assetsShowing}
        />
      )}
    </>
  );
};

export default MyAssets;
