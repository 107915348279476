const Twiter = ({ props }) => {
  return (
    <>
      <svg
        width={51}
        height={49}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.706 49h10.652v-3.728h6.924v-3.728h4.262v-3.729h4.26v-7.989h4.261V19.174h-4.26v-7.99h-4.261V7.458h-4.262V3.729h-6.924V0H19.706v3.729h-6.924v3.728h-4.26v3.728h-4.26v7.989H0v10.652h4.261v7.989h4.261v3.729h4.26v3.728h6.924V49Z"
          fill="#fff"
        />
        <path
          d="M38.962 18.044c-.67.85-1.482 1.591-2.401 2.196v.679c.007 4.034-1.689 7.901-4.707 10.736-3.019 2.835-7.108 4.4-11.35 4.343a16.572 16.572 0 0 1-7.075-1.554.308.308 0 0 1-.194-.284v-.136a.33.33 0 0 1 .337-.32 11.877 11.877 0 0 0 6.646-2.271c-2.182-.042-4.146-1.27-5.05-3.16a.307.307 0 0 1 .038-.312.344.344 0 0 1 .3-.132 5.448 5.448 0 0 0 1.973-.172c-2.409-.476-4.219-2.376-4.478-4.702a.315.315 0 0 1 .139-.285.352.352 0 0 1 .328-.036 5.422 5.422 0 0 0 2.051.42c-2.11-1.317-3.022-3.821-2.22-6.096a.691.691 0 0 1 .525-.43.723.723 0 0 1 .657.207c2.848 2.881 6.762 4.598 10.916 4.788a4.704 4.704 0 0 1-.155-1.234c.038-2.18 1.459-4.123 3.595-4.919 2.137-.795 4.567-.285 6.153 1.291a11.678 11.678 0 0 0 3.102-1.024.23.23 0 0 1 .234 0 .201.201 0 0 1 0 .222 5.49 5.49 0 0 1-2.285 2.468 11.616 11.616 0 0 0 2.597-.592.217.217 0 0 1 .233 0c.06.026.103.075.12.134a.201.201 0 0 1-.029.175Z"
          fill="#0D0D0D"
        />
      </svg>
    </>
  );
};

export default Twiter;
