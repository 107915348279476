import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import tannedMale from "../../../assets/images/Attributes/zoomers/skin/tannedMale.png";
import tannedFemale from "../../../assets/images/Attributes/zoomers/skin/tannedFemale.png";
import lightMale from "../../../assets/images/Attributes/zoomers/skin/lightMale.png";
import lightFemale from "../../../assets/images/Attributes/zoomers/skin/lightFemale.png";
import darkMale from "../../../assets/images/Attributes/zoomers/skin/darkMale.png";
import darkFemale from "../../../assets/images/Attributes/zoomers/skin/darkFemale.png";
import redMale from "../../../assets/images/Attributes/zoomers/skin/redMale.png";
import redFemale from "../../../assets/images/Attributes/zoomers/skin/redFemale.png";
import robotMale from "../../../assets/images/Attributes/zoomers/skin/robotMale.png";
import robotFemale from "../../../assets/images/Attributes/zoomers/skin/robotFemale.png";
import goldMale from "../../../assets/images/Attributes/zoomers/skin/goldMale.png";
import goldFemale from "../../../assets/images/Attributes/zoomers/skin/goldFemale.png";
import iceMale from "../../../assets/images/Attributes/zoomers/skin/iceMale.png";
import iceFemale from "../../../assets/images/Attributes/zoomers/skin/iceFemale.png";
import reptileMale from "../../../assets/images/Attributes/zoomers/skin/reptileMale.png";
import reptileFemale from "../../../assets/images/Attributes/zoomers/skin/reptileFemale.png";
import solanaMale from "../../../assets/images/Attributes/zoomers/skin/solanaMale.png";
import solanaFemale from "../../../assets/images/Attributes/zoomers/skin/solanaFemale.png";
import invisibleMale from "../../../assets/images/Attributes/zoomers/skin/invisibleMale.png";
import invisibleFemale from "../../../assets/images/Attributes/zoomers/skin/invisibleFemale.png";
import SliderArrow from "../../common/SliderArrow";
import GetWidth from "../../../hooks/GetWidth";

const Skin = ({ selected }) => {
  console.log(selected);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const skins = [
    {
      name: "Tanned",
      count: 58,
      percent: "26.13",
      male: tannedMale,
      female: tannedFemale,
    },
    {
      name: "light",
      count: 49,
      percent: "22.07",
      male: lightMale,
      female: lightFemale,
    },
    {
      name: "Dark",
      count: 42,
      percent: "18.92",
      male: darkMale,
      female: darkFemale,
    },
    {
      name: "red",
      count: 24,
      percent: "10.81",
      male: redMale,
      female: redFemale,
    },
    {
      name: "robot",
      count: 21,
      percent: "9.46",
      male: robotMale,
      female: robotFemale,
    },
    {
      name: "Gold",
      count: 10,
      percent: "4.50",
      male: goldMale,
      female: goldFemale,
    },
    {
      name: "Ice",
      count: 10,
      percent: "4.50",
      male: iceMale,
      female: iceFemale,
    },
    {
      name: "Reptile",
      count: 4,
      percent: "1.80",
      male: reptileMale,
      female: reptileFemale,
    },
    {
      name: "Solana",
      count: 3,
      percent: "1.35",
      male: solanaMale,
      female: solanaFemale,
    },
    {
      name: "Invisible",
      count: 1,
      percent: "0.45",
      male: invisibleMale,
      female: invisibleFemale,
    },
  ];

  if (selected === "From common to rare") {
    skins.sort((a, b) => {
      return b.count - a.count;
    });
  }
  if (selected === "From rare to common") {
    skins.sort((a, b) => {
      return a.count - b.count;
    });
  }

  const { width } = GetWidth();

  return (
    <>
      <div className="overflow-hidden">
        <div className="lg:w-[73%] md:w-[80%] w-[95%] m-auto md:pt-20 pt-0 pb-20 font-primary text-white md:text-4xl sm:text-2xl text-base items-center font-bold flex justify-between gap-2">
          <h2>Skin</h2>
          <SliderArrow prevRef={prevRef} nextRef={nextRef} />
        </div>
        <div className="md:max-w-[80%] m-auto max-w-[90%]">
          <Swiper
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={width > 1100 ? 2 : 1}
            spaceBetween={30}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            className="backgroundSwiper"
            modules={[Navigation, Pagination]}
          >
            {skins.map((skin, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`h-[100%] bg-teamGrayBg bg-no-repeat bg-center bg-full relative p-6 text-white flex items-center justify-center flex-col gap-y-10 lg100:flex-row lg100:gap-x-10 cursor-pointer`}
                >
                  <div className=" flex flex-col justify-center items-center gap-5 navLg:text-base text-sm">
                    <h2 className="text-center font-primary navLg:text-lg text-base capitalize">
                      {skin.name}
                    </h2>
                    <p className="font-secondary bg-[#BB3BC3] inline py-1 ">
                      Count
                    </p>
                    <p className="font-secondary">{skin.count}</p>
                    <p className="font-secondary bg-[#BB3BC3] inline py-1">%</p>
                    <p className="font-secondary">{skin.percent}%</p>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex flex-col items-center">
                      <img src={skin.male} alt="" />
                      <h2 className="font-primary py-6 navLg:text-base text-sm">
                        Male
                      </h2>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={skin.female} alt="" />
                      <h2 className="font-primary py-6 navLg:text-base text-sm">
                        Female
                      </h2>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Skin;
