import React from "react";
import ModalLayout from "./ModalLayout";

const InputePhrase = ({ modalOpen, setModalOpen, nextOpen }) => {
  const demoPhrase = "PixelRacer";
  const [phraseMatched, setPhraseMatched] = React.useState(false);
  const [inputtedPhrase, setInputtedPhrase] = React.useState({
    phrase2: "",
    phrase5: "",
  });

  const handleChange = (e) => {
    setInputtedPhrase({ ...inputtedPhrase, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      inputtedPhrase.phrase2 === demoPhrase &&
      inputtedPhrase.phrase5 === demoPhrase
    ) {
      setPhraseMatched(false);
      nextOpen(true);
      setModalOpen(false);
    } else {
      setPhraseMatched(true);
    }
  };

  return (
    <ModalLayout modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block transform overflow-hidden bg-[#363636] border border-white p-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-10 sm:align-middle">
        <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
          <h2 className="font-primary text-white text-lg">Recover password</h2>
          <p className="font-secondary text-white">
            Please enter the 2nd and 5th words of your seed phrase
          </p>
          <div className="relative">
            <div className="flex items-center sm:gap-20 gap-10 flex-col sm:flex-row ">
              <input
                type="text"
                className="phraseInput"
                placeholder="2nd Phrase"
                name="phrase2"
                onChange={handleChange}
              />
              <input
                type="text"
                className="phraseInput"
                placeholder="5th Phrase"
                name="phrase5"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="font-primary flex items-center justify-end gap-20">
            <p
              className={`
            font-secondary text-[#FF7B7B] text-sm ${
              !phraseMatched ? "opacity-0" : "opacity-100"
            }`}
            >
              Incorrect seed phrase. Please try again.
            </p>
            <button
              className={`
                px-9 py-3 text-white text-lg border duration-500 ${
                  inputtedPhrase.phrase2 !== "" && inputtedPhrase.phrase5 !== ""
                    ? "bg-[#FEAC0E] border-[#FEAC0E] cursor-pointer"
                    : "bg-[#363636] border-white cursor-not-allowed"
                }
                `}
              type="submit"
            >
              ok
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
};

export default InputePhrase;
