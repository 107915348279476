const HamburgerIcon = ({ props }) => {
  return (
    <>
      <svg
        {...props}
        viewBox="0 0 100 80"
        width="35"
        height="35"
        fill="#ffffff"
      >
        <rect width="100" height="15" rx="8"></rect>
        <rect y="30" width="100" height="15" rx="8"></rect>
        <rect y="60" width="100" height="15" rx="8"></rect>
      </svg>
    </>
  );
};

export default HamburgerIcon;
