import left from "../../assets/images/Play/ractangleLeft.png";
import right from "../../assets/images/Play/rectangleRight.png";
import dots from "../../assets/images/Play/dots.png";

const ComingSoon = () => {
  return (
    <section className="w-full sm:h-screen h-full">
      <div className="overflow-hidden">
        <h2 className="font-primary text-white playTitle lgC:text-[6rem] lg:text-[5rem] sm:text-[4rem] text-[3rem]">
          Comming Soon
        </h2>
      </div>
      <div className="w-[100%] flex sm:justify-between justify-center pt-[5rem]">
        <div className="w-[30%] hidden sm:block">
          <img src={left} alt="" className="w-full" />
        </div>
        <div className="flex flex-col items-center font-secondary text-white lg:text-2xl md:text-xl text-2xl text-center">
          <h2>We are rebuilding the app only for mobile, join our discord to get the latest update and test the new build with us.</h2>
          <img src={dots} alt="" className="pt-[2rem]" />
        </div>
        <div className="w-[30%] hidden sm:block">
          <img src={right} alt="" className="w-full" />
        </div>
      </div>
    </section>
  );
};

export default ComingSoon;
