import { useState } from "react";
import ModalLayout from "./ModalLayout";

const Withdraw = ({ modalOpen, setModalOpen, accountBalance, nextOpen }) => {
  const [inputtedData, setInputtedData] = useState({
    walletAddress: "",
    withdrawAmount: "",
  });

  const handleInputChange = (e) => {
    setInputtedData({ ...inputtedData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      inputtedData.walletAddress !== "" &&
      inputtedData.withdrawAmount !== ""
    ) {
      nextOpen(true);
      setModalOpen(false);
      document.getElementById("withdraw-form").reset();
    }
  };

  return (
    <ModalLayout modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block transform overflow-hidden bg-[#363636] p-10 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-9 sm:align-middle border border-white text-white xs:p-4 font-secondary">
        <h2 className="text-white">Total Balance</h2>
        <p className="font-primary text-[#FEAC0E] text-3xl">
          {accountBalance} sol
        </p>
        <form onSubmit={handleSubmit} id="withdraw-form">
          <input
            type="text"
            required
            placeholder="Enter address"
            name="walletAddress"
            className="py-2 px-4 bg-transparent border border-white placeholder:text-[#858585] text-white mt-9 w-full"
            onChange={handleInputChange}
            value={inputtedData.walletAddress}
          />
          <div className="mt-9 flex items-center justify-between border-b border-white pb-2">
            <input
              required
              type="number"
              placeholder="Enter amount"
              name="withdrawAmount"
              className="py-2 px-4 bg-transparent focus:outline-none placeholder:text-[#858585] text-white  w-full"
              onChange={handleInputChange}
              value={inputtedData.withdrawAmount}
            />
            <p
              className="font-primary text-[#FEAC0E] text-xl cursor-pointer"
              onClick={() =>
                setInputtedData({
                  ...inputtedData,
                  withdrawAmount: accountBalance,
                })
              }
            >
              max
            </p>
          </div>
          <div className="w-full mt-4">
            <button
              className="bg-[#FEAC0E] py-3 font-primary text-white w-full"
              type="submit"
            >
              Withdraw
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
};

export default Withdraw;
