export const ranks = [
  {
    type: "Weekly",
    statistics: [
      {
        title: "Gr",
        name: "Tsiolisole",
        score: "1540",
      },
      {
        title: "",
        name: "JayWavy",
        score: "1167.5",
      },
      {
        title: "Gr",
        name: "Destiny",
        score: "1144.5",
      },
      {
        title: "Gr",
        name: "Totis",
        score: "1092.5",
      },
      {
        title: "",
        name: "OctopusJuice",
        score: "945.5",
      },
      {
        title: "PH",
        name: "mikmik",
        score: "856.5",
      },
      {
        title: "PXR",
        name: "Skrabibo",
        score: "751",
      },
      {
        title: "US",
        name: "Shunter24",
        score: "736.5",
      },

      {
        title: "Gr",
        name: "Joseph",
        score: "633.5",
      },
      {
        title: "",
        name: "HellboyNFT",
        score: "605",
      },
    ],
  },
  {
    type: "Monthly",
    statistics: [
      {
        title: "Gr",
        name: "Tsiolisole",
        score: "1540",
      },
      {
        title: "",
        name: "JayWavy",
        score: "1167.5",
      },
      {
        title: "Gr",
        name: "Destiny",
        score: "1144.5",
      },
      {
        title: "Gr",
        name: "Totis",
        score: "1092.5",
      },
      {
        title: "",
        name: "OctopusJuice",
        score: "945.5",
      },
      {
        title: "PH",
        name: "mikmik",
        score: "856.5",
      },
      {
        title: "PXR",
        name: "Skrabibo",
        score: "751",
      },
      {
        title: "US",
        name: "Shunter24",
        score: "736.5",
      },

      {
        title: "Gr",
        name: "Joseph",
        score: "633.5",
      },
      {
        title: "",
        name: "HellboyNFT",
        score: "605",
      },
    ],
  },
  {
    type: "All Time",
    statistics: [
      {
        title: "Gr",
        name: "Tsiolisole",
        score: "1540",
      },
      {
        title: "",
        name: "JayWavy",
        score: "1167.5",
      },
      {
        title: "Gr",
        name: "Destiny",
        score: "1144.5",
      },
      {
        title: "Gr",
        name: "Totis",
        score: "1092.5",
      },
      {
        title: "",
        name: "OctopusJuice",
        score: "945.5",
      },
      {
        title: "PH",
        name: "mikmik",
        score: "856.5",
      },
      {
        title: "PXR",
        name: "Skrabibo",
        score: "751",
      },
      {
        title: "US",
        name: "Shunter24",
        score: "736.5",
      },

      {
        title: "Gr",
        name: "Joseph",
        score: "633.5",
      },
      {
        title: "",
        name: "HellboyNFT",
        score: "605",
      },
    ],
  },
];
