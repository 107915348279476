const purchasePrices = [
  {
    id: 1,
    country: "AFGHANISTAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 2,
    country: "ALBANIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 3,
    country: "ALGERIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 4,
    country: "ANDORRA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 5,
    country: "ANGOLA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 6,
    country: "ANGUILIA(UNITED KINGDOM)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 7,
    country: "ARGENTINA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 8,
    country: "ARMENIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 9,
    country: "ARUBA(NETHERLANDS)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 10,
    country: "AUSTRALIA",
    shippingCost: "25",
    shippingCostKRW: "33,500",
  },
  {
    id: 11,
    country: "AUSTRIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 12,
    country: "AZERBAIJAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 13,
    country: "BAHAMAS",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 14,
    country: "BAHRAIN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 15,
    country: "BANGLADESH",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 16,
    country: "BARBADOS",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 17,
    country: "BELARUS",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 18,
    country: "BELGIUM",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 19,
    country: "BERMUDA(UNITED KINGDOM)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 20,
    country: "BHUTAN",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 21,
    country: "BOSNIA AND HERZEGOVINA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 22,
    country: "BOTSWANA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 23,
    country: "BRAZIL",
    shippingCost: "34",
    shippingCostKRW: "45,000",
  },
  {
    id: 24,
    country: "BRUNEI DARUSSALAM",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 25,
    country: "BULGARIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 26,
    country: "CAMBODIA",
    shippingCost: "18",
    shippingCostKRW: "24,000",
  },
  {
    id: 27,
    country: "CANADA",
    shippingCost: "30",
    shippingCostKRW: "40,000",
  },
  {
    id: 28,
    country: "CAPE VERDE",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 29,
    country: "CAYMAN(UNITED KINGDOM)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 30,
    country: "CENTRAL AFRICAN REP",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 31,
    country: "CHILE",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 32,
    country: "CHINA",
    shippingCost: "22",
    shippingCostKRW: "28,500",
  },
  {
    id: 33,
    country: "CHRISTMAS(AUSTRAILIA)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 34,
    country: "COCOS(AUSTRAILIA)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 35,
    country: "COLOMBIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 36,
    country: "COMOROS",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 37,
    country: "COOK ISLANDS(NEW ZEALAND)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 38,
    country: "COSTA RICA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 39,
    country: "CROATIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 40,
    country: "CUBA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 41,
    country: "CURACAO",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 42,
    country: "CYPRUS",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 43,
    country: "CZECH REP",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 44,
    country: "DEMOCRATIC REP OF THE CONGO",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 45,
    country: "DENMARK",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 46,
    country: "DJIBOUTI",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 47,
    country: "DOMINICAN REPUBLIC",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 48,
    country: "EAST TIMOR",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 49,
    country: "ECUADOR",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 50,
    country: "EGYPT",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 51,
    country: "EL SALVADOR",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 52,
    country: "EQUATORIAL GUINEA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 53,
    country: "ESTONIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 54,
    country: "ETHIOPIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 55,
    country: "FAROE ISLAND(DENMARK)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 56,
    country: "FIJI",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 57,
    country: "FINLAND",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 58,
    country: "FRANCE",
    shippingCost: "27",
    shippingCostKRW: "36,000",
  },
  {
    id: 59,
    country: "FRANCE(FRENCH SOUTHERN)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 60,
    country: "FRENCH POLYNESIA(FRANCE)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 61,
    country: "GABON",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 62,
    country: "GAMBIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 63,
    country: "GEORGIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 64,
    country: "GERMANY",
    shippingCost: "26",
    shippingCostKRW: "34,500",
  },
  {
    id: 65,
    country: "GHANA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 66,
    country: "GIBRALTAR(UNITED KINGDOM)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 67,
    country: "GREECE",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 68,
    country: "GREENLAND(DENMARK)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 69,
    country: "GUADELOUPE(FRANCE)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 70,
    country: "GUAM(U.S.A)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 71,
    country: "GUATEMALA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 72,
    country: "GUINEA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 73,
    country: "GUINEA-BISSAU",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 74,
    country: "HONG KONG",
    shippingCost: "21",
    shippingCostKRW: "27,000",
  },
  {
    id: 75,
    country: "HUNGARY",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 76,
    country: "ICELAND",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 77,
    country: "INDIA",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 78,
    country: "INDONESIA",
    shippingCost: "23",
    shippingCostKRW: "30,000",
  },
  {
    id: 79,
    country: "IRAN(ISLAMIC REP)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 80,
    country: "IRELAND",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 81,
    country: "ISRAEL",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 82,
    country: "ITALY",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 83,
    country: "JAPAN",
    shippingCost: "20",
    shippingCostKRW: "26,000",
  },
  {
    id: 84,
    country: "JORDAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 85,
    country: "KAZAKHSTAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 86,
    country: "KENYA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 87,
    country: "KEDO",
    shippingCost: "18",
    shippingCostKRW: "24,000",
  },
  {
    id: 88,
    country: "KINGDOM OF ESWATINI",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 89,
    country: "KIRIBATI",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 90,
    country: "KUWAIT",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 91,
    country: "KYRGYZSTAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 92,
    country: "LAO PEOPLE'S DEM REP",
    shippingCost: "18",
    shippingCostKRW: "24,000",
  },
  {
    id: 93,
    country: "LATVIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 94,
    country: "LEBANON",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 95,
    country: "LIECHTENSTEIN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 96,
    country: "LITHUANIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 97,
    country: "LUXEMBOURG",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 98,
    country: "MACAO(CHINA)",
    shippingCost: "18",
    shippingCostKRW: "24,000",
  },
  {
    id: 99,
    country: "MADAGASCAR",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 100,
    country: "MALAWI",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 101,
    country: "MALAYSIA",
    shippingCost: "22",
    shippingCostKRW: "29,500",
  },
  {
    id: 102,
    country: "MALDIVES",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 103,
    country: "MALTA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 104,
    country: "MARIANA ISLANDS(U.S.A)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 105,
    country: "MARSHALL(U.S.A)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 106,
    country: "MARTINIQUE(FRANCE)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 107,
    country: "MAURITANIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 108,
    country: "MAURITIUS",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 109,
    country: "MAYOTTE",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 110,
    country: "MEXICO",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 111,
    country: "MICRONESIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 112,
    country: "MOLDOVA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 113,
    country: "MONACO",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 114,
    country: "MONGOLIA",
    shippingCost: "18",
    shippingCostKRW: "24,000",
  },
  {
    id: 115,
    country: "MONTSERRAT(UNITED KINGDOM)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 116,
    country: "MOROCCO",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 117,
    country: "MOZAMBIQUE",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 118,
    country: "MYANMAR",
    shippingCost: "18",
    shippingCostKRW: "24,000",
  },
  {
    id: 119,
    country: "NAMIBIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 120,
    country: "NAURU",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 121,
    country: "NEPAL",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 122,
    country: "NETHERLANDS",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 123,
    country: "NEW CALEDONIA(FRANCE)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 124,
    country: "NEW ZEALAND",
    shippingCost: "27",
    shippingCostKRW: "35,000",
  },
  {
    id: 125,
    country: "NICARAGUA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 126,
    country: "NIEU(NEW ZEALAND)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 127,
    country: "NIGERIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 128,
    country: "NORFOLK(AUSTRALIA)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 129,
    country: "NORTH MACEDONIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 130,
    country: "NORWAY",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 131,
    country: "OMAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 132,
    country: "PAKISTAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 133,
    country: "PALAU ISLANDS",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 134,
    country: "PANAMA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 135,
    country: "PAPUA NEW GUINEA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 136,
    country: "PARAGUAY",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 137,
    country: "PERU",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 138,
    country: "PHILIPPINES",
    shippingCost: "22",
    shippingCostKRW: "29,500",
  },
  {
    id: 139,
    country: "PITCAIRN IS(UNITED KINGDOM)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 140,
    country: "POLAND",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 141,
    country: "PORTUGAL",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 142,
    country: "PUERTO RICO(U.S.A)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 143,
    country: "QATAR",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 144,
    country: "REPUBIC OF SOUTH AFRICA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 145,
    country: "REPUBLIC OF MONTENEGRO",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 146,
    country: "REUNION(FRANCE)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 147,
    country: "ROMANIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 148,
    country: "RUSSIA",
    shippingCost: "35",
    shippingCostKRW: "46,000",
  },
  {
    id: 149,
    country: "RWANDA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 150,
    country: "SAINT KITTS AND NEVIS",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 151,
    country: "SAINT LUCIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 152,
    country: "SAINT VINCENT AND THE GRENADIN",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 153,
    country: "SAMOA(U.S.A)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 154,
    country: "SAN MARINO",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 155,
    country: "SAO TOME AND PRINCIPE",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 156,
    country: "SAUDI ARABIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 157,
    country: "SENEGAL",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 158,
    country: "SERBIA/KOSOVO",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 159,
    country: "SEYCHELLES",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 160,
    country: "SINGAPORE",
    shippingCost: "20",
    shippingCostKRW: "26,000",
  },
  {
    id: 161,
    country: "SLOVAKIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 162,
    country: "SLOVENIA",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 163,
    country: "SOLOMON ISLANDS",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 164,
    country: "SPAIN",
    shippingCost: "27",
    shippingCostKRW: "35,000",
  },
  {
    id: 165,
    country: "SOUTH KOREA",
    shippingCost: "8",
    shippingCostKRW: "10,000",
  },
  {
    id: 166,
    country: "SRI LANKA",
    shippingCost: "25",
    shippingCostKRW: "32,500",
  },
  {
    id: 167,
    country: "ST PIER AND MIQUELON(FRANCE)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 168,
    country: "SUDAN",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 169,
    country: "SURINAME",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 170,
    country: "SWEDEN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 171,
    country: "SWITZERLAND",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 172,
    country: "TAIWAN",
    shippingCost: "19",
    shippingCostKRW: "25,000",
  },
  {
    id: 173,
    country: "TAJIKISTAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 174,
    country: "TANZANIA(UNITED REP)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 175,
    country: "THAILAND",
    shippingCost: "23",
    shippingCostKRW: "30,500",
  },
  {
    id: 176,
    country: "TOKELAU(NEW ZEALAND)",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 177,
    country: "TONGA(NIUAFO'OU)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 178,
    country: "TRINIDAD AND TOBAGO",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 179,
    country: "TUNISIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 180,
    country: "TURKEY",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 181,
    country: "TURKMENISTAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 182,
    country: "TURKS(UNITED KINGDOM)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 183,
    country: "TUVALU",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 184,
    country: "U.K.",
    shippingCost: "30",
    shippingCostKRW: "39,000",
  },
  {
    id: 185,
    country: "U.S.A.",
    shippingCost: "32",
    shippingCostKRW: "42,000",
  },
  {
    id: 186,
    country: "UGANDA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 187,
    country: "UKRAINE",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 188,
    country: "UNITED ARAB EMIRATES",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 189,
    country: "URUGUAY",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 190,
    country: "UZBEKISTAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 191,
    country: "VANUATU",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 192,
    country: "VATICAN",
    shippingCost: "28",
    shippingCostKRW: "36,500",
  },
  {
    id: 193,
    country: "VENEZUELA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 194,
    country: "VIETNAM",
    shippingCost: "17",
    shippingCostKRW: "22,000",
  },
  {
    id: 195,
    country: "VIRGIN(U.S.A)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 196,
    country: "VIRGIN(UNITED KINGDOM)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 197,
    country: "WALLIS AND FUTUNA(FRANCE)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 198,
    country: "WESTERN SAMOA(U.S.A)",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
  {
    id: 199,
    country: "ZAMBIA",
    shippingCost: "33",
    shippingCostKRW: "44,000",
  },
];

export default purchasePrices;
