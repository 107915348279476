const Stats = () => {
  return (
    <section className="w-full h-screen ">
      <div className="w-[80%] m-auto py-20 font-primary text-white sm:text-[2.7rem] text-4xl font-bold">
        <h2>
          <span className="stoke-text">St</span>ats
        </h2>
      </div>
      <div className="px-6">
        <div className="bg-statsBoxBg bg-full bg-center bg-no-repeat font-primary max-w-[80rem] m-auto px-8 py-8 flex flex-col gap-9">
          <div className=" flex justify-between items-center border-b border-black pb-2">
            <h2 className="md:text-[1.2rem] text-sm w-[80%]">
              Total Earned (24 hours)
            </h2>
            <p className="text-base text-end">12 sol</p>
          </div>
          <div className=" flex justify-between items-center border-b border-black pb-2">
            <h2 className="md:text-[1.2rem] text-sm w-[80%]">
              Avg. Earned (24 hours)
            </h2>
            <p className="text-base text-end">0.3 sol</p>
          </div>
          <div className=" flex justify-between items-center border-b border-black pb-2">
            <h2 className="md:text-[1.2rem] text-sm w-[80%]">
              Total Players (24 hours)
            </h2>
            <p className="text-base text-end">50</p>
          </div>
          <div className=" flex justify-between items-center border-b border-black pb-2">
            <h2 className="md:text-[1.2rem] text-sm w-[80%]">
              Total Players (All Time)
            </h2>
            <p className="text-base text-end">800</p>
          </div>
          <div className=" flex justify-between items-center border-b border-black pb-2">
            <h2 className="md:text-[1.2rem] text-sm w-[80%]">
              Total Prize Pool (24 hours)
            </h2>
            <p className="text-base text-end">5 sol</p>
          </div>
          <div className=" flex justify-between items-center my-4">
            <h2 className="md:text-[1.2rem] text-sm w-[80%]">
              Total Prize Pool (All Time)
            </h2>
            <p className="text-base text-end">100 sol</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
