import { useRef } from "react";

import desert from "../../../assets/images/Attributes/zoomers/background/desert.png";
import evergreenStars from "../../../assets/images/Attributes/zoomers/background/evergreenStars.png";
import warehouse from "../../../assets/images/Attributes/zoomers/background/wareHouse.png";
import plainWhite from "../../../assets/images/Attributes/zoomers/background/plainWhite.png";
import innerCity from "../../../assets/images/Attributes/zoomers/background/innerCity.png";
import solanaSummer from "../../../assets/images/Attributes/zoomers/background/solanaSummer.png";
import wonderLand from "../../../assets/images/Attributes/zoomers/background/wonderland.png";
import theGlitch from "../../../assets/images/Attributes/zoomers/background/theGlitch.png";
import apoclypcs from "../../../assets/images/Attributes/zoomers/background/apoclypcs.png";
import moonRide from "../../../assets/images/Attributes/zoomers/background/moonride.png";
import AttributeSlider from "../../common/AttributeSlider";

const Background = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const backgrounds = [
    {
      name: "Desert",
      image: desert,
      count: 43,
      percent: "19.37",
    },
    {
      name: "Evergreen Stars",
      image: evergreenStars,
      count: 39,
      percent: "17.57",
    },
    {
      name: "Warehouse",
      image: warehouse,
      count: 33,
      percent: "14.86",
    },
    {
      name: "Plain White",
      image: plainWhite,
      count: 31,
      percent: "13.96",
    },
    {
      name: "Inner City",
      image: innerCity,
      count: 24,
      percent: "10.81",
    },
    {
      name: "Solana summer",
      image: solanaSummer,
      count: 21,
      percent: "9.46",
    },
    {
      name: "Wonderland",
      image: wonderLand,
      count: 11,
      percent: "4.95",
    },
    {
      name: "The Glitch",
      image: theGlitch,
      count: 9,
      percent: "4.05",
    },
    {
      name: "Apoloclyps",
      image: apoclypcs,
      count: 9,
      percent: "4.05",
    },
    {
      name: "Moon Ride",
      image: moonRide,
      count: 2,
      percent: "0.90",
    },
  ];

  if (selected === "From common to rare") {
    backgrounds.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    backgrounds.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Background"}
        data={backgrounds}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Background;
