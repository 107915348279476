import { useInView } from "react-intersection-observer";

import Banner from "../../components/Home/Banner";
import CarScroll from "../../components/Home/CarScroll";
import Nft from "../../components/Home/Nft";
import Power from "../../components/Home/Power";
import Scrolls from "../../components/Home/Scrolls";

import Team from "../../components/Home/Team";
import Trailer from "../../components/Home/Trailer";

const Home = () => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });

    return (
        <section className="h-[100%]  relative overflow-hidden">
            <div className="customContainer py-20">
                <h4 className="font-primary text-white text-3xl font-bold text-center md:text-left">
                    <span className="stoke-text">Pixel Racers</span> is on hiatus for an undefined period of time. If you are interested in taking over the project, reach out to our socials. Zoom Zoom !
                </h4>
            </div>
            <div ref={ref}>
                <Nft />
                <Scrolls />
                <Power />
                <CarScroll />
                <Team />
            </div>
        </section>
    );
};

export default Home;
