import left from "../../assets/images/Play/ractangleLeft.png";
import right from "../../assets/images/Play/rectangleRight.png";
import dots from "../../assets/images/Play/dots.png";

import windows from "../../assets/images/Play/windows.png";
import windowsX64 from "../../assets/images/Play/windowsX64.png";
import macOs from "../../assets/images/Play/macOs.png";

const Play = () => {
  return (
    <section className="w-full sm:h-screen h-full">
      <div className="overflow-hidden">
        <h2 className="font-primary text-white playTitle lgC:text-[6rem] lg:text-[5rem] sm:text-[4rem] text-[3rem]">
          Ready To Race?
        </h2>
      </div>
      <div className="w-[100%] flex sm:justify-between justify-center pt-[5rem]">
        <div className="w-[30%] hidden sm:block">
          <img src={left} alt="" className="w-full" />
        </div>
        <div className="flex flex-col items-center font-secondary text-white lg:text-2xl md:text-xl text-2xl text-center">
          <h2>Download the game now</h2>
          <img src={dots} alt="" className="pt-[2rem]" />
        </div>
        <div className="w-[30%] hidden sm:block">
          <img src={right} alt="" className="w-full" />
        </div>
      </div>
      <div className="w-[85%] flex items-center gap-8 m-auto justify-center pt-[5rem] sm:flex-row flex-col ">
        <div>
          <a
            download
            href="https://drag-racing-builds.s3.ap-northeast-2.amazonaws.com/Windows_x86.zip"
          >
            <img src={windows} alt="" className="w-full windows" />
          </a>
        </div>
        <div>
          <a
            href="https://drag-racing-builds.s3.ap-northeast-2.amazonaws.com/Windows_x86_64.zip"
            download
          >
            <img src={windowsX64} alt="" className="windowsX64" />
          </a>
        </div>
        <div>
          <a
            href="https://drag-racing-builds.s3.ap-northeast-2.amazonaws.com/PR_DragRacing.zip"
            download
          >
            <img src={macOs} alt="" className="macOs" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Play;
