import { useRef } from "react";
import team1 from "../../assets/images/Home/team/team-1.png";
import team2 from "../../assets/images/Home/team/team-2.png";
import team3 from "../../assets/images/Home/team/team-3.png";
import team4 from "../../assets/images/Home/team/team-4.png";
import team5 from "../../assets/images/Home/team/team-5.png";
import SliderArrow from "../common/SliderArrow";
import TeamSlider from "./TeamSlider";
const teamData = [
  {
    name: "Pampelemoose",
    desc: "Pampelemoose is the full-stack, game, and blockchain developer for Pixel Racers. As a seasoned developer, he loves coming up with new ideas and creating game features that the community can enjoy. In his free time, Pampe plays darts and goes skateboarding.",
    image: team1,
    twitterLink: "https://twitter.com/pampelemoose",
  },
  //{
  //  name: "Margiela",
  //  desc: "Margiela is the operations, marketing, and business development guy at Pixel Racers. His goal is to make Pixel Racers a project that provides value to gamers and holders alike. When he is not working, Margiela is eating cake or watching baseball.",
  //  image: team2,
  //  twitterLink: "https://twitter.com/crypto_margiela",
  //},
  //{
  //  name: "DreamKilla",
  //  desc: "DreamKilla is an NFT collector and trader who handles public relations and partnerships for Pixel Racers. His ability to befriend anyone and network is his core strength. When Dream isn’t minting NFTs, he plays hockey and enjoys a cigar beneath the setting sun.",
  //  image: team3,
  //  twitterLink: "https://twitter.com/DreamKillaxbt",
  //},
  //{
  //  name: "MANS",
  //  desc: "Mans is the head of community at Pixel Racers. He does all the essential work like Discord management, whitelisting, and community support in addition to helping out with partnerships. When he’s not testing new Discord bots, Mans plays tennis.",
  //  image: team5,
  //  twitterLink: "https://twitter.com/solbigboi",
  //},
];

const Team = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <section className="my-16 mb-24">
      <div className="customContainer py-20 ">
        <div className="flex justify-between items-center ">
          <h2 className="font-primary text-white font-bold md:text-5xl sm:text-3xl text-2xl">
            <span className="stoke-text">THE </span>Team
          </h2>
          <SliderArrow prevRef={prevRef} nextRef={nextRef} />
        </div>

        <div className="mt-12 relative ">
          <TeamSlider teamData={teamData} prevRef={prevRef} nextRef={nextRef} />
        </div>
      </div>
    </section>
  );
};

export default Team;
