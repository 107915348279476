

const PrivacyPolicy = () => {
    return (
        <section className="w-full h-full">
            <div className="custom1700:w-[80%] w-[90%] m-auto py-20 font-primary text-white sm:text-[2.4rem] text-4xl font-bold">
                <h2>
                    <span className="stoke-text">Pri</span>vacy Policy
                </h2>
            </div>

            <div
                className="custom1700:w-[80%] w-[90%] m-auto grid grid-cols-1 navLg:gap-[1.5rem] lg:gap-3 gap-12 bg-border bg-center bg-full navLg:p-[3.4rem] sm:p-[2rem] p-[1rem]"
            >
                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Privacy Policy for Pixel Racers <br />(Android & iOS)</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        This service is provided by Pixel Racers at no cost and is intended for use as is.<br /><br />
                        If you choose to use our Service, then you agree to the collection and use of information
                        in relation to this policy. The Personal Information that we collect is used for providing and
                        improving the Service. We will not use or share your information with anyone except as described
                        in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our
                        Terms and Conditions for the respective app unless otherwise defined in this Privacy Policy.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Information Collection and Use</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        For a better experience, while using our Service, we may require you to provide us with certain
                        personally identifiable information. The information that we request will be retained by us and
                        used as described in this privacy policy.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>What information do we collect?</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We may collect and process the following personal information from you:
                    </p>
                    <div className="flex flex-col gap-3">
                        <h4 className="font-primary lgC:text-[1rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                            <span>- What information do we collect?</span>
                        </h4>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            When you register to use our Services, we will collect your entered data like name,
                            email address, password, organization information, and billing details. If you make
                            an inquiry through our website, or contact us in any other way, we will keep a copy
                            of your communications with us.
                        </p>
                        <h4 className="font-primary lgC:text-[1rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                            <span>- Information we collect when you do business with us</span>
                        </h4>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            As a customer or prospective customer, or as a vendor, supplier, consultant, or
                            other third party we may hold your business contact information and financial account
                            information (if any) and other communications you have with us.
                        </p>
                        <h4 className="font-primary lgC:text-[1rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                            <span>- Information we automatically collect</span>
                        </h4>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            We may collect certain technical information by automatic means when you visit our
                            website and games, such as IP address, played levels and highscores. We collect this
                            information automatically through the use of various technologies, such as cookies.
                        </p>
                    </div>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>What do we use your information for?</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        The personal information we collect from you may be used in one of the following ways:
                    </p>
                    <div className="flex flex-col gap-3">
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To deal with your inquiries and requests
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To create and administer records about any online account that you register with us
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To provide you with information and access to resources that you have requested from us
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To provide you with technical support (your information helps us to better respond to
                            your individual needs)
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To improve our website (we continually strive to improve our website offerings based
                            on the information and feedback we receive from you), including to improve the navigation
                            and content of our sites
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - For website and system administration and security
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - For general business purposes, including to improve customer service (your information
                            helps us to more effectively respond to your customer service requests and support needs),
                            to help us improve the content and functionality of our Services, to better understand our
                            users, to protect against wrongdoing, to enforce our Terms of Service, and to generally
                            manage our business
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To process transactions and to provide Services to our customers and end-users
                        </p>
                    </div>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Do we disclose any information to outside parties?</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We do not sell or trade your personal information to third parties. We will only share your
                        information with third parties in certain circumstances:
                    </p>
                    <div className="flex flex-col gap-3">
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - We engage certain trusted third parties to perform functions and provide services to us,
                            including cloud hosting services, off-site backups, email service providers, and customer
                            support providers. We will only share your personal information with third parties to the
                            extent necessary to perform these functions, in accordance with the purposes set out in this
                            Privacy Policy and applicable laws.
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - In the event of a corporate sale, merger, reorganization, dissolution, or similar event,
                            your personal information may be sold, disposed of, transferred, or otherwise disclosed as
                            part of that transaction.
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - We may also disclose information about you to third parties where we believe it necessary
                            or appropriate under law, for example: (1) to protect or defend our rights, interests, or
                            property or that of third parties; (2) to comply with legal process, judicial orders, or
                            subpoenas; (3) to respond to requests from public or government authorities, including for
                            national security and law enforcement purposes; (4) to prevent or investigate possible wrongdoing
                            in connection with the Services or to enforce our Terms of Service; (5) to protect the vital
                            interests of our users, customers, and other third parties.
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - We may use and share aggregated non-personal information with third parties for marketing,
                            advertising, and analytics purposes.
                        </p>
                    </div>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Log Data</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We want to inform you that whenever you use our Service, in a case of an error in the app we
                        collect data and information (through third party products) on your phone called Log Data. This
                        Log Data may include information such as your device Internet Protocol (��IP��) address, device name,
                        operating system version, the configuration of the app when utilizing our Service, the time and
                        date of your use of the Service, and other statistics.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Cookies</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
                        These are sent to your browser from the websites that you visit and are stored on your device��s internal memory.<br /><br />
                        This Service does not use these ��cookies�� explicitly. However, the app may use third party code and libraries
                        that use ��cookies�� to collect information and improve their services. You have the option to either accept or
                        refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies,
                        you may not be able to use some portions of this Service.<br /><br />
                        We will not store any personal information within cookies. We only save game progress data (played levels, highscores)
                        to let you proceed your game where you paused it and to show your provided nickname in leaderboards.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Service Providers</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We may employ third-party companies and individuals due to the following reasons:
                    </p>
                    <div className="flex flex-col gap-3">
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To facilitate our Service;
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To provide the Service on our behalf;
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To perform Service-related services; or
                        </p>
                        <p className="lgC:text-[1rem] text-[0.9rem]">
                            - To assist us in analyzing how our Service is used.
                        </p>
                    </div>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We want to inform users of this Service that these third parties have access to your Personal Information. The reason
                        is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information
                        for any other purpose.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Security</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it.
                        But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable,
                        and we cannot guarantee its absolute security.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Links to Other Sites</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that
                        these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites.
                        We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Children��s Privacy</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from
                        children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete
                        this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information,
                        please contact us so that we will be able to do the necessary actions.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Data Retention</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We may retain your personal information as long as you continue to use the Services, have an account with us, or for as long as is
                        necessary to fulfil the purposes outlined in the policy. You can ask to close your account by contacting us at the details above,
                        and we will delete your personal information on request.<br /><br />
                        We may, however, retain personal information for an additional period as is permitted or required under applicable laws, for legal,
                        tax, or regulatory reasons, or for legitimate and lawful business purposes.
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Your Rights</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        If you are from the EU, you may have the right to access a copy of the personal information we hold about you, or to request the
                        correction, amendment, or deletion of such information where it is inaccurate or processed in violation of the Privacy Shield Principles.
                        To make such a request, please contact us at: <a href="mailto:pixelracersnft@gmail.com">pixelracersnft@gmail.com</a><br /><br />
                        We will consider and respond to your request in accordance with the Privacy Shield Principles and applicable laws.<br /><br />
                        Furthermore, we commit to giving you an opportunity to opt-out if your personal information is to be disclosed to any other independent
                        third parties, or to be used for a purpose materially different from those that are set out in this Privacy Policy. Where sensitive
                        personal information is involved, we will always obtain your express opt-in consent to do such things. If you otherwise wish to limit the use
                        or disclosure of your personal information, please write to us at the contact details above.<br /><br />
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Changes to This Privacy Policy</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        We may update our Privacy Policy from time to time. Thus, you are advised to review our policy periodically for any changes. We will
                        notify you of any changes by posting the new Privacy Policy on via the app.
                    </p>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        This policy is effective as of 2022-10-04
                    </p>
                </div>

                <div className="text-white flex flex-col gap-6 font-secondary md:w-[85%] w-full lg100:mr-auto mx-auto justify-between lg100:order-1 order-2">
                    <h2 className="font-primary lgC:text-[2rem] sm:text-[1.8rem] text-[1.5rem] flex flex-col">
                        <span>Contact Us</span>
                    </h2>
                    <p className="lgC:text-[1rem] text-[0.9rem]">
                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at: <a href="mailto:pixelracersnft@gmail.com">pixelracersnft@gmail.com</a>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
