import React, { useEffect, useState } from "react";
import ErrorMessage from "../common/ErrorMessage";
import InputRequired from "../common/InputRequired";
import ModalLayout from "./ModalLayout";

const NewPasswordForm = ({ modalOpen, setModalOpen, nextOpen }) => {
  const [inputtedData, setInputtedData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);

  const handleChange = (e) => {
    setInputtedData({ ...inputtedData, [e.target.name]: e.target.value });
  };

  //   Check if the password and confirm password are the same
  useEffect(() => {
    if (
      inputtedData.confirmPassword !== "" &&
      inputtedData.password !== inputtedData.confirmPassword
    ) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  }, [inputtedData.password, inputtedData.confirmPassword]);

  //   Check password length
  useEffect(() => {
    inputtedData.password.length < 8 && inputtedData.password !== ""
      ? setPasswordLength(false)
      : setPasswordLength(true);
  }, [inputtedData.password]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordsMatch && passwordLength) {
      console.log("passwords match and are long enough");
      nextOpen(true);
      setModalOpen(false);
    }
  };

  return (
    <ModalLayout modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block transform overflow-hidden bg-[#363636] border border-white p-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-10 sm:align-middle">
        <form className="flex flex-col gap-7" onSubmit={handleSubmit}>
          <h2 className="font-primary text-white text-lg">Set new password</h2>
          <div className="relative">
            <InputRequired
              className={""}
              label="Password"
              type={"password"}
              name="password"
              color={"white"}
              event={handleChange}
            />
            <ErrorMessage error={!passwordLength}>
              Password must be at least 8 characters.
            </ErrorMessage>
          </div>
          <div className="relative">
            <InputRequired
              className={""}
              label="Confirm Password"
              type={"password"}
              name="confirmPassword"
              color={"white"}
              event={handleChange}
            />
            <ErrorMessage error={!passwordsMatch}>
              Please make sure your passwords match.
            </ErrorMessage>
          </div>
          <div className="font-primary flex items-center justify-end gap-20">
            <button
              className={`
                px-9 py-3 text-white text-lg border duration-500 ${
                  passwordLength &&
                  passwordsMatch &&
                  inputtedData.password !== "" &&
                  inputtedData.confirmPassword !== ""
                    ? "bg-[#FEAC0E] border-[#FEAC0E] cursor-pointer"
                    : "bg-[#363636] border-white cursor-not-allowed"
                }
                `}
              type="submit"
            >
              ok
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
};

export default NewPasswordForm;
