const InputRequired = ({
  className,
  label,
  required,
  name,
  type,
  event,
  placeholder,
  defaultValue,
  color,
}) => {
  return (
    <div className={className}>
      <label
        htmlFor="firstName"
        className="text-white sm:text-xl text-sm bg-primary px-0.5 py-1"
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        required={required ? required : true}
        placeholder={placeholder}
        className={`focus:outline-primary w-full border-[2px] p-2 ${
          color === "white"
            ? "border-white mt-3 bg-transparent text-white"
            : "border-black mt-1 bg-white"
        }`}
        onChange={event}
      />
    </div>
  );
};

export default InputRequired;
