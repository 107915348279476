import { useRef } from "react";

import twoSpoke from "../../../assets/images/Attributes/wheelType/twoSpoke.png";
import x from "../../../assets/images/Attributes/wheelType/x.png";
import fourSpoke from "../../../assets/images/Attributes/wheelType/fourSpoke.png";
import dot from "../../../assets/images/Attributes/wheelType/dot.png";
import fourSpokeBold from "../../../assets/images/Attributes/wheelType/fourSpokeBold.png";
import eightSpoke from "../../../assets/images/Attributes/wheelType/eightSpoke.png";
import amplification from "../../../assets/images/Attributes/wheelType/amplification.png";
import cross from "../../../assets/images/Attributes/wheelType/cross.png";
import squareRipple from "../../../assets/images/Attributes/wheelType/squareRipple.png";
import twelveSpoke from "../../../assets/images/Attributes/wheelType/twelveSpoke.png";
import doubleSquare from "../../../assets/images/Attributes/wheelType/doubleSquare.png";
import maze from "../../../assets/images/Attributes/wheelType/maze.png";
import clownFace from "../../../assets/images/Attributes/wheelType/clownFace.png";
import AttributeSlider from "../../common/AttributeSlider";

const wheelTypes = [
  {
    name: "Two Spoke",
    image: twoSpoke,
    percent: "14.99",
    count: "1,301",
  },
  {
    name: "X",
    image: x,
    percent: "14.77",
    count: "1,282",
  },
  {
    name: "Four Spoke",
    image: fourSpoke,
    percent: "14.66",
    count: "1,273",
  },
  {
    name: "Dot",
    image: dot,
    percent: "11.21",
    count: "973",
  },
  {
    name: "Eight Spoke",
    image: eightSpoke,
    percent: "10.98",
    count: "953",
  },

  {
    name: "Four Spoke Bold",
    image: fourSpokeBold,
    percent: "10.92",
    count: "948",
  },

  {
    name: "Amplification",
    image: amplification,
    percent: "6.13",
    count: "532",
  },
  {
    name: "Cross",
    image: cross,
    percent: "6.00",
    count: "521",
  },
  {
    name: "Square Ripple",
    image: squareRipple,
    percent: "5.93",
    count: "515",
  },
  {
    name: "Twelve Spoke",
    image: twelveSpoke,
    percent: "1.61",
    count: "140",
  },
  {
    name: "Double Square",
    image: doubleSquare,
    percent: "1.41",
    count: "122",
  },

  {
    name: "Maze",
    image: maze,
    percent: "0.73",
    count: "63",
  },
  {
    name: "Clown Face",
    image: clownFace,
    percent: "0.67",
    count: "58",
  },
];

const WheelType = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (selected === "From common to rare") {
    wheelTypes.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    wheelTypes.sort((a, b) => {
      return a.percent - b.percent;
    });
  }
  return (
    <>
      <AttributeSlider
        data={wheelTypes}
        from="Wheel Type"
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default WheelType;
