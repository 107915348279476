import { useRef } from "react";

import AttributeSlider from "../../common/AttributeSlider";

import white from "../../../assets/images/Attributes/colors/white.png";
import black from "../../../assets/images/Attributes/colors/black.png";

const windowColors = [
  {
    name: "White",
    image: white,
    percent: "50.29",
    count: "4,366",
  },
  {
    name: "Black",
    image: black,
    percent: "49.71",
    count: "4,315",
  },
];

const WindowColor = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (selected === "From common to rare") {
    windowColors.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    windowColors.sort((a, b) => {
      return a.percent - b.percent;
    });
  }
  return (
    <>
      <AttributeSlider
        data={windowColors}
        from="Window Color"
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default WindowColor;
