import React from "react";

const BoxText = ({ title, width, afterHeight, name }) => {
  return (
    <div
      className={`${name} relative after:absolute after:top-[100%] border border-white p-2 ${width} text-center after:bg-white after:w-[1px] 
      ${afterHeight}
      `}
    >
      <p className="font-secondary text-white text-xs md:text-lg capitalize">
        {title}
      </p>
    </div>
  );
};

export default BoxText;
