import { useState } from "react";
import Devider from "../../common/Devider";
import Select from "../../common/Select";
import Background from "./Background";
import Clothing from "./Clothing";
import EarAccessories from "./EarAccessories";
import EyeColor from "./EyeColor";
import EyeType from "./EyeType";
import Eyewear from "./Eyewear";
import FacialHair from "./FacialHair";
import Hair from "./Hair";
import HeadGear from "./HeadGear";
import Mouth from "./Mouth";
import Neck from "./Neck";
import Skin from "./Skin";
import Talent from "./Talent";

const Zoomers = () => {
  const [selected, setSelected] = useState("From common to rare");

  const options = ["From common to rare", "From rare to common"];

  const handleSelect = (e) => {
    if (e.target.outerText === "From common to rare") {
      setSelected("From common to rare");
    }
    if (e.target.outerText === "From rare to common") {
      setSelected("From rare to common");
    }
  };

  return (
    <>
      <div className="mt-3 sm:mb-0 mb-12 lg:w-[73%] md:w-[80%] w-[95%] m-auto pt-[5rem]">
        <div className="max-w-[20rem]">
          <Select
            event={handleSelect}
            data={options}
            defaultValue={options[0]}
          />
        </div>
      </div>
      <Background selected={selected} />
      <Devider />
      <Skin selected={selected} />
      <Devider />
      <Clothing selected={selected} />
      <Devider />
      <Hair selected={selected} />
      <Devider />
      <HeadGear selected={selected} />
      <Devider />
      <EyeType selected={selected} />
      <Devider />
      <EyeColor selected={selected} />
      <Devider />
      <Eyewear selected={selected} />
      <Devider />
      <Mouth selected={selected} />
      <Devider />
      <FacialHair selected={selected} />
      <Devider />
      <Neck selected={selected} />
      <Devider />
      <EarAccessories selected={selected} />
      <Devider />
      <Talent selected={selected} />
    </>
  );
};

export default Zoomers;
