import soss from "../assets/images/Rankings/SSOS_Tourney.png";
import mint from "../assets/images/Rankings/zoomersMint.png";
import whitelist from "../assets/images/Rankings/whiteList.png";
import may17 from "../assets/images/Rankings/may17-23.png";

export const tournaments = [
  {
    img: soss,
    name: "soss",
    title: "SSOS Tournament",
    start: "Wed Jun 08 2022 16:00:00 GMT+0300",
    end: "Mon Jun 13 2022 16:00:00 GMT+0300",
    description:
      "We’ve partnered with Secret Society of Shibs (SSOS) to give out whitelist and SOL prizes to tournament participants! Play in this tournament to win a WL spot for a FREE mint for SSOS.",

    projectIntro:
      "Secret Society of Shibs (SSOS) is a secret society of 11,111 Shibs that have come together to create a brotherhood (sisters allowed!). The SSOS team wants to show what's possible even without asking for an initial raise from the community, as they think it's time that the community are put first and let them decide the value of the collection. SSOS has a unique royalty tier system which progress as time goes by.",

    entries: 500,
    prizes: [
      {
        position: "1st",
        prize: "1 SSOS WL + 1 SOL",
      },
      {
        position: "2nd",
        prize: "1 SSOS WL + 0.8 SOL",
      },
      {
        position: "3rd",
        prize: "1 SSOS WL + 0.6 SOL",
      },
      {
        position: "4th",
        prize: "1 SSOS WL + 0.5 SOL",
      },
      {
        position: "5th",
        prize: "1 SSOS WL + 0.4 SOL",
      },
      {
        position: "6th - 10th",
        prize: "0.3 SOL",
      },
      {
        position: "11th - 13th",
        prize: "0.25 SOL",
      },
      {
        position: "14th - 16th",
        prize: "0.2 SOL",
      },
      {
        position: "17th - 20th",
        prize: "0.15 SOL",
      },
    ],
    desclaimer:
      "Play 20 races or more for a chance to win a WL spot for SSOS (5 random winners who didn’t win a WL allocation will be chosen)",
    score: [
      {
        title: "PH",
        name: "Brian",
        score: "457",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "DolChi",
        score: "435.5",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Chess",
        score: "435",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "656.5",
        entryCount: "183",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "422.5",
        entryCount: "76",
      },
      {
        title: "Gr",
        name: "Christos",
        score: "393",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Donut",
        score: "379",
        entryCount: "77",
      },
      {
        title: "PH",
        name: "Sored",
        score: "371",
        entryCount: "77",
      },
      {
        title: "Gr",
        name: "TonyTheFox",
        score: "339.5",
        entryCount: "109",
      },
      {
        title: "PH",
        name: "Ssamu",
        score: "326",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Saka",
        score: "323",
        entryCount: "75",
      },
      {
        title: "PH",
        name: "Arsenic",
        score: "257",
        entryCount: "115",
      },
      {
        title: "",
        name: "Shawn",
        score: "197",
        entryCount: "109",
      },
      {
        title: "",
        name: "Makyu",
        score: "158.5",
        entryCount: "74",
      },
      {
        title: "",
        name: "DKamatis",
        score: "146.5",
        entryCount: "84",
      },
      {
        title: "",
        name: "3huds",
        score: "111.5",
        entryCount: "86",
      },
      {
        title: "PH",
        name: "VINCI",
        score: "72.5",
        entryCount: "61",
      },
      {
        title: "Gr",
        name: "ping",
        score: "51",
        entryCount: "49",
      },
      {
        title: "PH",
        name: "Desho",
        score: "48.5",
        entryCount: "14",
      },
      {
        title: "PH",
        name: "Trido",
        score: "26",
        entryCount: "13",
      },
      {
        title: "",
        name: "peanut",
        score: "1.5",
        entryCount: "3",
      },
    ],
    twitter: "https://twitter.com/SecretShibs",
    discord: "https://t.co/6RbxThYZfp",
  },
  {
    img: mint,
    name: "mint",
    title: "Zoomers Mint Tournament",
    start: "Wed Jun 08 2022 16:00:00 GMT+030",
    end: "Mon Jun 13 2022 16:00:00 GMT+0300",
    description:
      "We’ve partnered with Secret Society of Shibs (SSOS) to give out whitelist and SOL prizes to tournament participants! Play in this tournament to win a WL spot for a FREE mint for SSOS.",

    projectIntro:
      "Secret Society of Shibs (SSOS) is a secret society of 11,111 Shibs that have come together to create a brotherhood (sisters allowed!). The SSOS team wants to show what's possible even without asking for an initial raise from the community, as they think it's time that the community are put first and let them decide the value of the collection. SSOS has a unique royalty tier system which progress as time goes by.",

    entries: 500,
    prizes: [
      {
        position: "1st",
        prize: "1 SSOS WL + 1 SOL",
      },
      {
        position: "2nd",
        prize: "1 SSOS WL + 0.8 SOL",
      },
      {
        position: "3rd",
        prize: "1 SSOS WL + 0.6 SOL",
      },
      {
        position: "4th",
        prize: "1 SSOS WL + 0.5 SOL",
      },
      {
        position: "5th",
        prize: "1 SSOS WL + 0.4 SOL",
      },
      {
        position: "6th - 10th",
        prize: "0.3 SOL",
      },
      {
        position: "11th - 13th",
        prize: "0.25 SOL",
      },
      {
        position: "14th - 16th",
        prize: "0.2 SOL",
      },
      {
        position: "17th - 20th",
        prize: "0.15 SOL",
      },
    ],
    desclaimer:
      "Play 20 races or more for a chance to win a WL spot for SSOS (5 random winners who didn’t win a WL allocation will be chosen)",
    score: [
      {
        title: "PH",
        name: "Brian",
        score: "457",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "DolChi",
        score: "435.5",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Chess",
        score: "435",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "656.5",
        entryCount: "183",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "422.5",
        entryCount: "76",
      },
      {
        title: "Gr",
        name: "Christos",
        score: "393",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Donut",
        score: "379",
        entryCount: "77",
      },
      {
        title: "PH",
        name: "Sored",
        score: "371",
        entryCount: "77",
      },
      {
        title: "Gr",
        name: "TonyTheFox",
        score: "339.5",
        entryCount: "109",
      },
      {
        title: "PH",
        name: "Ssamu",
        score: "326",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Saka",
        score: "323",
        entryCount: "75",
      },
      {
        title: "PH",
        name: "Arsenic",
        score: "257",
        entryCount: "115",
      },
      {
        title: "",
        name: "Shawn",
        score: "197",
        entryCount: "109",
      },
      {
        title: "",
        name: "Makyu",
        score: "158.5",
        entryCount: "74",
      },
      {
        title: "",
        name: "DKamatis",
        score: "146.5",
        entryCount: "84",
      },
      {
        title: "",
        name: "3huds",
        score: "111.5",
        entryCount: "86",
      },
      {
        title: "PH",
        name: "VINCI",
        score: "72.5",
        entryCount: "61",
      },
      {
        title: "Gr",
        name: "ping",
        score: "51",
        entryCount: "49",
      },
      {
        title: "PH",
        name: "Desho",
        score: "48.5",
        entryCount: "14",
      },
      {
        title: "PH",
        name: "Trido",
        score: "26",
        entryCount: "13",
      },
      {
        title: "",
        name: "peanut",
        score: "1.5",
        entryCount: "3",
      },
    ],
  },
  {
    img: whitelist,
    name: "whitelist",
    title: "Zoomers Whitelist Tournament",
    start: "Wed Jun 08 2022 16:00:00 GMT+030",
    end: "Mon Jun 13 2022 16:00:00 GMT+0300",
    description:
      "We’ve partnered with Secret Society of Shibs (SSOS) to give out whitelist and SOL prizes to tournament participants! Play in this tournament to win a WL spot for a FREE mint for SSOS.",

    projectIntro:
      "Secret Society of Shibs (SSOS) is a secret society of 11,111 Shibs that have come together to create a brotherhood (sisters allowed!). The SSOS team wants to show what's possible even without asking for an initial raise from the community, as they think it's time that the community are put first and let them decide the value of the collection. SSOS has a unique royalty tier system which progress as time goes by.",

    entries: 500,
    prizes: [
      {
        position: "1st",
        prize: "1 SSOS WL + 1 SOL",
      },
      {
        position: "2nd",
        prize: "1 SSOS WL + 0.8 SOL",
      },
      {
        position: "3rd",
        prize: "1 SSOS WL + 0.6 SOL",
      },
      {
        position: "4th",
        prize: "1 SSOS WL + 0.5 SOL",
      },
      {
        position: "5th",
        prize: "1 SSOS WL + 0.4 SOL",
      },
      {
        position: "6th - 10th",
        prize: "0.3 SOL",
      },
      {
        position: "11th - 13th",
        prize: "0.25 SOL",
      },
      {
        position: "14th - 16th",
        prize: "0.2 SOL",
      },
      {
        position: "17th - 20th",
        prize: "0.15 SOL",
      },
    ],
    desclaimer:
      "Play 20 races or more for a chance to win a WL spot for SSOS (5 random winners who didn’t win a WL allocation will be chosen)",
    score: [
      {
        title: "PH",
        name: "Brian",
        score: "457",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "DolChi",
        score: "435.5",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Chess",
        score: "435",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "656.5",
        entryCount: "183",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "422.5",
        entryCount: "76",
      },
      {
        title: "Gr",
        name: "Christos",
        score: "393",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Donut",
        score: "379",
        entryCount: "77",
      },
      {
        title: "PH",
        name: "Sored",
        score: "371",
        entryCount: "77",
      },
      {
        title: "Gr",
        name: "TonyTheFox",
        score: "339.5",
        entryCount: "109",
      },
      {
        title: "PH",
        name: "Ssamu",
        score: "326",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Saka",
        score: "323",
        entryCount: "75",
      },
      {
        title: "PH",
        name: "Arsenic",
        score: "257",
        entryCount: "115",
      },
      {
        title: "",
        name: "Shawn",
        score: "197",
        entryCount: "109",
      },
      {
        title: "",
        name: "Makyu",
        score: "158.5",
        entryCount: "74",
      },
      {
        title: "",
        name: "DKamatis",
        score: "146.5",
        entryCount: "84",
      },
      {
        title: "",
        name: "3huds",
        score: "111.5",
        entryCount: "86",
      },
      {
        title: "PH",
        name: "VINCI",
        score: "72.5",
        entryCount: "61",
      },
      {
        title: "Gr",
        name: "ping",
        score: "51",
        entryCount: "49",
      },
      {
        title: "PH",
        name: "Desho",
        score: "48.5",
        entryCount: "14",
      },
      {
        title: "PH",
        name: "Trido",
        score: "26",
        entryCount: "13",
      },
      {
        title: "",
        name: "peanut",
        score: "1.5",
        entryCount: "3",
      },
    ],
  },
  {
    img: may17,
    name: "may17",
    title: "May 17 - 23 Tournament",
    start: "Wed Jun 08 2022 16:00:00 GMT+030",
    end: "Mon Jun 13 2022 16:00:00 GMT+0300",
    description:
      "We’ve partnered with Secret Society of Shibs (SSOS) to give out whitelist and SOL prizes to tournament participants! Play in this tournament to win a WL spot for a FREE mint for SSOS.",

    projectIntro:
      "Secret Society of Shibs (SSOS) is a secret society of 11,111 Shibs that have come together to create a brotherhood (sisters allowed!). The SSOS team wants to show what's possible even without asking for an initial raise from the community, as they think it's time that the community are put first and let them decide the value of the collection. SSOS has a unique royalty tier system which progress as time goes by.",

    entries: 500,
    prizes: [
      {
        position: "1st",
        prize: "1 SSOS WL + 1 SOL",
      },
      {
        position: "2nd",
        prize: "1 SSOS WL + 0.8 SOL",
      },
      {
        position: "3rd",
        prize: "1 SSOS WL + 0.6 SOL",
      },
      {
        position: "4th",
        prize: "1 SSOS WL + 0.5 SOL",
      },
      {
        position: "5th",
        prize: "1 SSOS WL + 0.4 SOL",
      },
      {
        position: "6th - 10th",
        prize: "0.3 SOL",
      },
      {
        position: "11th - 13th",
        prize: "0.25 SOL",
      },
      {
        position: "14th - 16th",
        prize: "0.2 SOL",
      },
      {
        position: "17th - 20th",
        prize: "0.15 SOL",
      },
    ],
    desclaimer:
      "Play 20 races or more for a chance to win a WL spot for SSOS (5 random winners who didn’t win a WL allocation will be chosen)",
    score: [
      {
        title: "PH",
        name: "Brian",
        score: "457",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "DolChi",
        score: "435.5",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Chess",
        score: "435",
        entryCount: "78",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "656.5",
        entryCount: "183",
      },
      {
        title: "PH",
        name: "Hyo",
        score: "422.5",
        entryCount: "76",
      },
      {
        title: "Gr",
        name: "Christos",
        score: "393",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Donut",
        score: "379",
        entryCount: "77",
      },
      {
        title: "PH",
        name: "Sored",
        score: "371",
        entryCount: "77",
      },
      {
        title: "Gr",
        name: "TonyTheFox",
        score: "339.5",
        entryCount: "109",
      },
      {
        title: "PH",
        name: "Ssamu",
        score: "326",
        entryCount: "72",
      },
      {
        title: "PH",
        name: "Saka",
        score: "323",
        entryCount: "75",
      },
      {
        title: "PH",
        name: "Arsenic",
        score: "257",
        entryCount: "115",
      },
      {
        title: "",
        name: "Shawn",
        score: "197",
        entryCount: "109",
      },
      {
        title: "",
        name: "Makyu",
        score: "158.5",
        entryCount: "74",
      },
      {
        title: "",
        name: "DKamatis",
        score: "146.5",
        entryCount: "84",
      },
      {
        title: "",
        name: "3huds",
        score: "111.5",
        entryCount: "86",
      },
      {
        title: "PH",
        name: "VINCI",
        score: "72.5",
        entryCount: "61",
      },
      {
        title: "Gr",
        name: "ping",
        score: "51",
        entryCount: "49",
      },
      {
        title: "PH",
        name: "Desho",
        score: "48.5",
        entryCount: "14",
      },
      {
        title: "PH",
        name: "Trido",
        score: "26",
        entryCount: "13",
      },
      {
        title: "",
        name: "peanut",
        score: "1.5",
        entryCount: "3",
      },
    ],
  },
];
