import ReactPlayer from "react-player";

// Css inside app.css file

const Trailer = () => {
  return (
    <div className=" w-[100%] sm:h-[100%] relative mt-24  trailerContainer">
      <div className="customContainer pt-[5rem] pb-[7rem] ">
        <h2 className="font-primary text-white text-5xl font-bold">
          <span className="stoke-text">TR</span>AILER
        </h2>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=tZPPA4wOgqQ"
          controls={true}
          width="100%"
          height="80vh"
          className="my-10"
        />
        <p className="font font-secondary text-white text-xl">
          Pixel Racers is an NFT racing game with play-to-earn mechanics. Zoom
          zoom against fellow racers to be champion br of the streets.
        </p>
      </div>
    </div>
  );
};

export default Trailer;
