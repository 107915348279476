import React, { useRef } from "react";

import whitePonytailMale from "../../../assets/images/Attributes/zoomers/hair/whitePonytailMale.png";
import whitePonytailFemale from "../../../assets/images/Attributes/zoomers/hair/whitePonytailFemale.png";
import whiteLongFemale from "../../../assets/images/Attributes/zoomers/hair/whiteLongFemale.png";
import brownPonytailMale from "../../../assets/images/Attributes/zoomers/hair/brownPonytailMale.png";
import brownPonytailFemale from "../../../assets/images/Attributes/zoomers/hair/brownPonytailFemale.png";
import blackSwiftBackMale from "../../../assets/images/Attributes/zoomers/hair/blackSwiftBackMale.png";
import blackShortFemale from "../../../assets/images/Attributes/zoomers/hair/blackShortFemale.png";
import blackBobFemale from "../../../assets/images/Attributes/zoomers/hair/blockBobFemale.png";
import blueBridsFemale from "../../../assets/images/Attributes/zoomers/hair/blueBridsFemale.png";
import pinkLongFemale from "../../../assets/images/Attributes/zoomers/hair/pinkLongFemale.png";
import blondeBunMale from "../../../assets/images/Attributes/zoomers/hair/blondeBunMale.png";
import greenBobFemale from "../../../assets/images/Attributes/zoomers/hair/greenBobFemale.png";
import silverSlideCutMale from "../../../assets/images/Attributes/zoomers/hair/silverSlideCutMale.png";
import whiteBobFemale from "../../../assets/images/Attributes/zoomers/hair/whiteBobFemale.png";
import redSideCutMale from "../../../assets/images/Attributes/zoomers/hair/redSideCutMale.png";
import greenManBunMale from "../../../assets/images/Attributes/zoomers/hair/greenManBunMale.png";
import blackSideCutMale from "../../../assets/images/Attributes/zoomers/hair/blackSideCutMale.png";
import blondBuzzCutMale from "../../../assets/images/Attributes/zoomers/hair/blondBuzzCutMale.png";
import blueSpikyMale from "../../../assets/images/Attributes/zoomers/hair/blueSpikyMale.png";
import pinkBowlMale from "../../../assets/images/Attributes/zoomers/hair/pinkBowlMale.png";
import darkBrownShortMale from "../../../assets/images/Attributes/zoomers/hair/darkBrownShortMale.png";
import brownManBunMale from "../../../assets/images/Attributes/zoomers/hair/brownManBunMale.png";
import silverSweptBlackMale from "../../../assets/images/Attributes/zoomers/hair/silverSweptBlackMale.png";
import blueBuzzCutMale from "../../../assets/images/Attributes/zoomers/hair/blueBuzzCutMale.png";
import blondeBrideFemale from "../../../assets/images/Attributes/zoomers/hair/blondeBrideFemale.png";
import blueBowlMale from "../../../assets/images/Attributes/zoomers/hair/blueBowlMale.png";
import blackMulletMale from "../../../assets/images/Attributes/zoomers/hair/blackMulletMale.png";
import purpleShortMale from "../../../assets/images/Attributes/zoomers/hair/purpleShortMale.png";
import orangePigtailsFemale from "../../../assets/images/Attributes/zoomers/hair/orangePigtailsFemale.png";
import lightBrownCurtailsMale from "../../../assets/images/Attributes/zoomers/hair/lightBrownCurtailsMale.png";
import silverMullet from "../../../assets/images/Attributes/zoomers/hair/silverMullet.png";
import darkBrownCurtainsMale from "../../../assets/images/Attributes/zoomers/hair/darkBrownCurtainsMale.png";
import whiteShortMale from "../../../assets/images/Attributes/zoomers/hair/whiteShortMale.png";
import greenMohwkMale from "../../../assets/images/Attributes/zoomers/hair/greenMohwkMale.png";
import blueBunMale from "../../../assets/images/Attributes/zoomers/hair/blueBunMale.png";
import greenPigTailsGirl from "../../../assets/images/Attributes/zoomers/hair/greenPigTailsGirl.png";
import MultiItemSlider from "../../common/MultiItemSlider";

const Hair = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const hairs = [
    {
      name: "White Ponytail",
      count: 18,
      percent: 8.11,
      male: whitePonytailMale,
      female: whitePonytailFemale,
      twoItems: true,
    },
    {
      name: "White Long",
      count: 16,
      percent: "7.21",
      female: whiteLongFemale,
    },
    {
      name: "Brown Ponytail",
      count: 15,
      percent: "6.76",
      male: brownPonytailMale,
      female: brownPonytailFemale,
      twoItems: true,
    },
    {
      name: "Black Swept Back",
      count: 12,
      percent: "5.41",
      male: blackSwiftBackMale,
    },
    {
      name: "Black Short",
      count: 11,
      percent: "4.95",
      female: blackShortFemale,
    },
    {
      name: "Black Bob",
      count: 11,
      percent: "4.95",
      female: blackBobFemale,
    },
    {
      name: "Blue Braids",
      count: 9,
      percent: "4.05",
      female: blueBridsFemale,
    },
    {
      name: "Pink Long",
      count: 8,
      percent: "3.60",
      female: pinkLongFemale,
    },
    {
      name: "Blonde Bun",
      count: 8,
      percent: "3.60",
      female: blondeBunMale,
    },
    {
      name: "Green Bob",
      count: 8,
      percent: "3.60",
      female: greenBobFemale,
    },
    {
      name: "Silver Side Cut",
      count: 7,
      percent: "3.15",
      male: silverSlideCutMale,
    },
    {
      name: "White Bob",
      count: 7,
      percent: "3.15",
      female: whiteBobFemale,
    },
    {
      name: "Red Side Cut",
      count: 7,
      percent: "3.15",
      male: redSideCutMale,
    },
    {
      name: "Green Man Bun",
      count: 7,
      percent: "3.15",
      male: greenManBunMale,
    },
    {
      name: "Black Side Cut",
      count: 6,
      percent: "2.70",
      male: blackSideCutMale,
    },
    {
      name: "Blond Buzz Cut",
      count: 6,
      percent: "2.70",
      male: blondBuzzCutMale,
    },
    {
      name: "Blue Spiky",
      count: 6,
      percent: "2.70",
      male: blueSpikyMale,
    },
    {
      name: "Pink Bowl",
      count: 6,
      percent: "2.70",
      male: pinkBowlMale,
    },
    {
      name: "Dark Brown Short",
      count: 5,
      percent: "2.25",
      male: darkBrownShortMale,
    },
    {
      name: "Brown Man Bun",
      count: 5,
      percent: "2.25",
      male: brownManBunMale,
    },
    {
      name: "Silver Swept Back",
      count: 5,
      percent: "2.25",
      male: silverSweptBlackMale,
    },
    {
      name: "Blue Buzz Cut",
      count: 5,
      percent: "2.25",
      male: blueBuzzCutMale,
    },
    {
      name: "Blonde Braids",
      count: 4,
      percent: "1.80",
      female: blondeBrideFemale,
    },
    {
      name: "Blue Bowl",
      count: 4,
      percent: "1.80",
      male: blueBowlMale,
    },
    {
      name: "Black Mullet",
      count: 4,
      percent: "1.80",
      male: blackMulletMale,
    },
    {
      name: "Purple Short",
      count: 4,
      percent: "1.80",
      male: purpleShortMale,
    },
    {
      name: "Orange Pigtails",
      count: 3,
      percent: "1.35",
      female: orangePigtailsFemale,
    },
    {
      name: "Light Brown Curtains",
      count: 3,
      percent: "1.35",
      male: lightBrownCurtailsMale,
    },
    {
      name: "Silver Mullet",
      count: 3,
      percent: "1.35",
      male: silverMullet,
    },
    {
      name: "Dark Brown Curtains",
      count: 2,
      percent: "0.90",
      male: darkBrownCurtainsMale,
    },
    {
      name: "White Short",
      count: 2,
      percent: "0.90",
      male: whiteShortMale,
    },
    {
      name: "Green Mohawk",
      count: 2,
      percent: "0.90",
      male: greenMohwkMale,
    },
    {
      name: "Blue Bun",
      count: 2,
      percent: "0.90",
      female: blueBunMale,
    },
    {
      name: "Green Pigtails",
      count: 1,
      percent: "0.45",
      female: greenPigTailsGirl,
    },
  ];

  if (selected === "From common to rare") {
    hairs.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    hairs.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <MultiItemSlider
        data={hairs}
        from={"Hair"}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Hair;
