import { useRef } from "react";
import gray from "../../../assets/images/Attributes/trail/gray.gif";
import yellow from "../../../assets/images/Attributes/trail/yellow.gif";
import turquoise from "../../../assets/images/Attributes/trail/turouis.gif";
import red from "../../../assets/images/Attributes/trail/red.gif";
import purple from "../../../assets/images/Attributes/trail/purple.gif";
import green from "../../../assets/images/Attributes/trail/green.gif";
import pink from "../../../assets/images/Attributes/trail/pink.gif";
import rainbow from "../../../assets/images/Attributes/trail/rainbow.gif";

import AttributeSlider from "../../common/AttributeSlider";

const trails = [
  {
    name: "Gray",
    image: gray,
    percent: "23.85",
    count: "2,070",
  },
  {
    name: "Yellow",
    image: yellow,
    percent: "23.76",
    count: "2,063",
  },
  {
    name: "Red",
    image: red,
    percent: "15.25",
    count: "1,324",
  },
  {
    name: "Turquoise",
    image: turquoise,
    percent: "14.98",
    count: "1,300",
  },
  {
    name: "Purple",
    image: purple,
    percent: "8.17",
    count: "709",
  },
  {
    name: "Green",
    image: green,
    percent: "7.90",
    count: "686",
  },
  {
    name: "Hot Pink",
    image: pink,
    percent: "3.97",
    count: "345",
  },
  {
    name: "Rainbow",
    image: rainbow,
    percent: "2.12",
    count: "184",
  },
];

const Trail = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (selected === "From common to rare") {
    trails.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    trails.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        data={trails}
        from="Trail"
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Trail;
