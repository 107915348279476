import { Link } from "react-router-dom";

import cancel from "../../assets/images/Roadmap/cancel.png";

const BackToRoadmap = () => {
  return (
    <>
      <Link to="/roadmap">
        <button className="self-start font-secondary bg-white text-black p-3 flex items-center gap-3 cursor-pointer">
          Back to Roadmap
          <img src={cancel} alt="" />
        </button>
      </Link>
    </>
  );
};

export default BackToRoadmap;
