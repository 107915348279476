import engineOne from "../../../assets/images/engines/engineOne.png";
import speed from "../../../assets/images/engines/speed.png";
import rocket from "../../../assets/images/engines/rocket.png";
import tyre from "../../../assets/images/engines/tyre.png";
import z1 from "../../../assets/images/engines/z1.png";
import z2 from "../../../assets/images/engines/z2.png";
import z3 from "../../../assets/images/engines/z3.png";

const features = [
  {
    name: "speed",
    image: speed,
    title: "Top speed (1-10)",
    status: "Upgradeable",
  },
  {
    name: "zone1",
    title: "Zone 1 (1-3)",
    status: "Upgradeable",
    // zone: "z1",
    image: z1,
  },
  {
    name: "acceleration",
    image: rocket,
    title: "Accleration (1-10)",
    status: "Upgradeable",
  },
  {
    name: "zone2",
    // zone: "z2",
    image: z2,
    title: "Zone 2 (1-5)",
    status: "Upgradeable",
  },
  {
    name: "handling",
    image: tyre,
    title: "Handling (1-10)",
    status: "Upgradeable",
  },

  {
    name: "zone3",
    // zone: "z3",
    image: z3,
    title: "Zone 3 (1-5)",
    status: "Upgradeable",
  },
];

const Engines = () => {
  return (
    <section className="max-w-[80rem] m-auto pt-20  pb-20">
      <div className="px-6">
        <div className="w-full h-full bg-engineBg bg-full bg-center bg-no-repeat p-6  text-white flex lg100:flex-row flex-col gap-12">
          <div className="lg100:w-[45%] w-full lg100:block flex flex-col items-center">
            <div>
              <img src={engineOne} alt="" />
            </div>
            <div className="pt-6">
              <div className="font-secondary flex items-center gap-20 pb-2">
                <h2 className="flex gap-3 items-center">
                  <span className="w-4 h-4 block bg-[#18E4AD]" /> Z1
                </h2>
                <h2 className="flex gap-3 items-center">
                  <span className="w-4 h-4 block bg-[#7884D5]" /> Z3
                </h2>
              </div>
              <div className="font-secondary flex items-center gap-20">
                <h2 className="flex gap-3 items-center">
                  <span className="w-4 h-4 block bg-[#38C5BA]" /> Z2
                </h2>
                <h2 className="flex gap-3 items-center">
                  <span className="w-4 h-4 flex bg-[#18E4AD] relative justify-center items-center">
                    <span className="relative bg-black block z-10 w-2.5 h-2.5" />
                  </span>{" "}
                  Handling
                </h2>
              </div>
            </div>
          </div>
          <div className=" w-full h-full py-4 grid grid-cols-1 screen700:grid-cols-2 gap-10 pt-7 tablet:place-items-center">
            {features.map((data, index) => (
              <div className="flex screen700:items-center justify-start gap-8">
                <div className="flex-shrink-0 w-[23%] ">
                  {data.image ? (
                    <img src={data.image} alt="" className="flex-shrink-0" />
                  ) : (
                    <h2 className="font-primary text-[3.50rem]">{data.zone}</h2>
                  )}
                </div>
                <div>
                  <h2 className="font-primary text-white">{data.title}</h2>
                  <p className="font-secondary text-[#21C8B2] text-sx pt-2">
                    {data.status}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Engines;
