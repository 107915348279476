import none from "../../../assets/images/Attributes/zoomers/none.png";
import brownDrivingGoggles from "../../../assets/images/Attributes/zoomers/eyeWear/brownDrivingGoggles.png";
import pinkFlash from "../../../assets/images/Attributes/zoomers/eyeWear/pinkFlash.png";
import siverRounded from "../../../assets/images/Attributes/zoomers/eyeWear/siverRounded.png";
import blackRounded from "../../../assets/images/Attributes/zoomers/eyeWear/blackRounded.png";
import heatGlasses from "../../../assets/images/Attributes/zoomers/eyeWear/heatGlasses.png";
import solarRounded from "../../../assets/images/Attributes/zoomers/eyeWear/solarRounded.png";
import blueSwiming from "../../../assets/images/Attributes/zoomers/eyeWear/blueSwiming.png";
import blackEyePatch from "../../../assets/images/Attributes/zoomers/eyeWear/blackEyePatch.png";
import dGlasses from "../../../assets/images/Attributes/zoomers/eyeWear/3dGlasses.png";
import solanaVipers from "../../../assets/images/Attributes/zoomers/eyeWear/solanaVipers.png";
import rainBowDrivingGoogles from "../../../assets/images/Attributes/zoomers/eyeWear/rainBowDrivingGoogles.png";
import { useRef } from "react";
import AttributeSlider from "../../common/AttributeSlider";

const Eyewear = ({ selected }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const eyeWears = [
    {
      name: "None",
      count: 114,
      percent: "51.35",
      image: none,
    },
    {
      name: "Brown Driving Goggles",
      count: 19,
      percent: "8.56",
      image: brownDrivingGoggles,
    },
    {
      name: "Black Sunglasses with Pink Flash",
      count: 16,
      percent: "7.21",
      image: pinkFlash,
    },
    {
      name: "Silver Round Glasses",
      count: 15,
      percent: "6.76",
      image: siverRounded,
    },
    {
      name: "Black Round Sunglasses",
      count: 19,
      percent: "5.86",
      image: blackRounded,
    },
    {
      name: "Heart Glasses",
      count: 12,
      percent: "5.41",
      image: heatGlasses,
    },
    {
      name: "Solana Round Glasses",
      count: 6,
      percent: "4.05",
      image: solarRounded,
    },
    {
      name: "Blue Swimming Goggles",
      count: 9,
      percent: "4.05",
      image: blueSwiming,
    },
    {
      name: "Black Eye Patch",
      count: 6,
      percent: "2.70",
      image: blackEyePatch,
    },
    {
      name: "3D Glasses",
      count: 5,
      percent: "2.25",
      image: dGlasses,
    },
    {
      name: "Solana Vipers",
      count: 3,
      percent: "1.35",
      image: solanaVipers,
    },
    {
      name: "Rainbow Driving Goggles",
      count: 1,
      percent: "0.45",
      image: rainBowDrivingGoogles,
    },
  ];

  if (selected === "From common to rare") {
    eyeWears.sort((a, b) => {
      return b.percent - a.percent;
    });
  }
  if (selected === "From rare to common") {
    eyeWears.sort((a, b) => {
      return a.percent - b.percent;
    });
  }

  return (
    <>
      <AttributeSlider
        from={"Eyewear"}
        data={eyeWears}
        prevRef={prevRef}
        nextRef={nextRef}
      />
    </>
  );
};

export default Eyewear;
