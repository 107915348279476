import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Twiter from "../../svg/Twiter";
const TeamSlider = ({ teamData, prevRef, nextRef }) => {
  return (
    <Swiper
      onInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
      }}
      slidesPerView={window.innerWidth > 1075 ? 1.5 : 1}
      spaceBetween={120}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      className="teamSwiper "
      modules={[Navigation, Pagination]}
    >
      {teamData.map((data, index) => (
        <SwiperSlide key={index}>
          {({ isActive }) => (
            <div
              className={`w-[100%] h-[100%] ${
                isActive ? "bg-teamPinkBg" : "bg-teamGrayBg"
              } bg-no-repeat bg-center bg-full relative p-6 text-white flex items-center justify-start flex-col gap-y-10 md:flex-row md:gap-x-10 cursor-pointer`}
            >
              <img src={data.image} alt="" className="w-full" />
              <div className="flex flex-col items-start gap-5 pt-5">
                <h2 className="text-center font-primary text-2xl">
                  {data.name}
                </h2>
                <p className="font-secondary text-xl">{data.desc}</p>
                <a href={data.twitterLink} target="_blank" rel="noreferrer">
                  <Twiter />
                </a>
              </div>
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TeamSlider;
