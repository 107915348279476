import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import bornAgain from "../../assets/images/merch/bornAgain.png";
import demoItem from "../../assets/images/merch/demoItem.png";
import buy from "../../assets/images/merch/buy.png";
import SeeMore from "../../components/common/SeeMore";

const Merch = () => {
    const axios = require('axios');
    const [products, setProducts] = useState(null);

    useEffect(() => {
        axios.get("https://api.pixelracers.io/merch/products")
            .then((response) => {
                if (response.data.success) {
                    setProducts(response.data.data.merch);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [products]);

    return (
        <section className="w-full h-full">
            <div className="custom1700:w-[80%] w-[90%] m-auto py-20 font-primary text-white sm:text-[2.7rem] text-4xl font-bold">
                <h2>
                    <span className="stoke-text">Me</span>rch
                </h2>
            </div>
            <div className="custom1700:w-[80%] w-[90%] m-auto grid lgC:grid-cols-4 grid-cols-3 mdMax:grid-cols-2 smMax:grid-cols-1 custom1400:gap-8 gap-6">
                {products?.map((item, index) => (
                    <div key={index} className="flex justify-center">
                        <div
                            className="max-w-[20rem] mdMax:w-full h-[99%] bg-itemBg bg-no-repeat bg-center bg-full relative  text-white overflow-hidden"
                        >
                            <div className="p-6">
                                <img src={item.showcasePicture} alt="" className="w-full" />
                                <div className="flex flex-col items-center gap-5 pt-5">
                                    <h2 className="text-center font-primary lgC:text-xl text-xl min-h-[4rem] flex items-center">
                                        {item.name}
                                    </h2>
                                    <p className="font-secondary bg-[#BB3BC3] py-2 px-0.5">
                                        Holders
                                    </p>
                                    <p className="font-secondary">${item.holderPrice} USDC</p>
                                    <p className="font-secondary bg-[#BB3BC3] py-2 px-0.5">
                                        Non-holders
                                    </p>
                                    <p className="font-secondary">${item.fullPrice} USDC</p>
                                </div>
                            </div>
                            <Link className="w-full cursor-pointer" to={`/purchase/${item.id}`}>
                                <img src={buy} alt="" className="w-full" />
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            {products?.length > 10 && (
                <SeeMore />
            )}
        </section>
    );
};

export default Merch;
