import arrow from "../../assets/images/Attributes/arrow.png";

const SliderArrow = ({ prevRef, nextRef }) => {
  return (
    <>
      <div className="flex md:gap-4 gap-1.5">
        <button
          className="py-3 px-6 border border-white sliderArrowConainer"
          ref={prevRef}
        >
          <img
            src={arrow}
            alt=""
            className=" sliderArrow transform rotate-180 flex-shrink-0"
          />
        </button>
        <button
          className="py-3 px-6 border border-white sliderArrowConainer"
          ref={nextRef}
        >
          <img src={arrow} alt="" className=" sliderArrow flex-shrink-0" />
        </button>
      </div>
    </>
  );
};

export default SliderArrow;
