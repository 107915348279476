import { Dialog } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import smileFace from "../../assets/images/smileFace.png";
import ModalLayout from "./ModalLayout";

const Message = ({
  modalOpen,
  setModalOpen,
  message,
  btn,
  color,
  message2,
  btnColor,
}) => {
  const navigate = useNavigate();

  return (
    <ModalLayout modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div
        className={`inline-block transform overflow-hidden px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle border border-white ${
          color === "gray" ? "bg-[#363636]" : "bg-white"
        }`}
      >
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="div"
              className={`text-lg font-medium leading-6 flex items-center gap-8 ${
                color === "gray" ? "text-white" : "text-gray-900"
              }`}
            >
              <img src={smileFace} alt="smile face" />
            </Dialog.Title>
          </div>
          <div
            className={`mt-5 sm:mt-6 text-center font-secondary flex flex-col items-center gap-6 ${
              color === "gray" ? "text-white" : ""
            }`}
          >
            <h2 className="text-lg">
              {message2 ? (
                <>
                  {message} <br /> {message2}
                </>
              ) : (
                message
              )}
            </h2>
            <button
              className={` text-white px-8 pt-2 pb-3 font-primary text-[1.4rem] font-medium ${
                btnColor === "gray"
                  ? "bg-transparent border border-white"
                  : "bg-[#F6A70D]"
              }`}
              onClick={() => {
                setModalOpen(false);
                if (btn === "Login") {
                  navigate("/login");
                }
              }}
            >
              {btn}
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default Message;
